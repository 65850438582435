import React, { useContext, useState, useEffect } from "react";
import { Box, Layer, ResponsiveContext, Button } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "pro-solid-svg-icons";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { updatePlaceMenuSettings } from "../api/mutations";
import { usePlace } from "../api/queries";
import Loader from "./Loader";
import logoExample from "../assets/images/logo-example.png";
import FileUploadInput from "./FileUploadInput";
import { trackEventSegment } from "../utils/segmentUtils";
import { handle500, handleUnknown } from "../utils/errorHandles";
import Toast from "./Toast";
import { getFileObj } from "../utils/fileUtils";
import { logoSchema } from "../utils/schemaUtils";

const UpdateLogoPopup = ({ closePopup }) => {
  const cache = useQueryClient();
  const { place } = useParams();

  const [logoPreview, setLogoPreview] = useState(null);

  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";

  const { mutate, isLoading } = useMutation(updatePlaceMenuSettings);
  const { status, data } = usePlace(place);

  const [formData, setFormData] = useState({
    venue_logo: undefined,
  });

  const schema = Yup.object({
    venue_logo: logoSchema,
  });

  const onSubmit = async (values, bag) => {
    const reqBody = {
      venue_logo: values.venue_logo === "" ? null : await getFileObj(values.venue_logo),
    };
    await mutate(
      {
        placeId: place,
        values: reqBody,
      },
      {
        onSuccess: (data) => {
          trackEventSegment("Menu logo updated");
          Toast.fire({
            title: "Menu logo updated",
            icon: "success",
          });
          bag.setSubmitting(false);
        },
        onError: (error, newSect, rollback) => {
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                bag.setFieldError(data.errors[0].field, data.errors[0].message);
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
          bag.setSubmitting(false);
        },
      }
    );
  };

  const setLogoPrev = (url) => {
    setLogoPreview(url);
  };

  useEffect(() => {
    if (data) {
      setFormData({ venue_logo: data.venue_logo });
      if (data.venue_logo) {
        setLogoPreview(data.venue_logo);
      }
    }
  }, [data]);

  if (!data) {
    return <Loader />;
  }

  return (
    <Layer position="center" onClickOutside={closePopup} onEsc={closePopup} full={isSmall}>
      <Box background="white" border={{ color: "brand", size: "small" }} round="small" width="xlarge" fill={isSmall}>
        <Box align="end" pad={{ right: "medium", top: "medium" }}>
          <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => closePopup()} />
        </Box>
        <Box pad={{ bottom: "large", left: "large", right: "large", top: "medium" }}>
          <Formik initialValues={formData} onSubmit={onSubmit} validationSchema={schema}>
            {(formik) =>
              formik.isSubmitting || isLoading ? (
                <Loader />
              ) : (
                <Form>
                  <FileUploadInput
                    preview={logoPreview}
                    exampleFile={logoExample}
                    setPreview={setLogoPrev}
                    text="Logo"
                    name="venue_logo"
                    helpText="Your logo will be used on your dashboard as well as your personalised QR code. Your logo must be square. To see an example of an ideal logo"
                    handleFileInput={(fileName, fieldName) => formik.setFieldValue(fieldName, fileName)}
                    previewNewTab
                  />
                  <Button primary label="Update logo" type="submit" />
                </Form>
              )
            }
          </Formik>
        </Box>
      </Box>
    </Layer>
  );
};

export default UpdateLogoPopup;
