import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { GoogleMap, LoadScript, Autocomplete, Marker } from "@react-google-maps/api";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import InputDiv from "./InputDiv";
import InputField from "./InputField";

const MapWrap = styled.div`
  height: 200px;
  margin-bottom: 2em;

  ${up("sm")} {
    height: 250px;
  }

  ${up("lg")} {
    height: 300px;
  }

  ${up("xl")} {
    height: 300px;
  }

  ${up("xxl")} {
    height: 350px;
  }

  ${up("giant")} {
    height: 400px;
  }
`;

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

function AddressField() {
  const { setFieldValue, values } = useFormikContext();

  const mapCoords = values.addressPoint
    ? values.addressPoint
    : {
        lat: -25.2744,
        lng: 133.7751,
      };

  const [autocomplete, setAutocomplete] = useState(null);
  const [coords, setCoords] = useState(mapCoords);
  const [mapZoom, setMapZoom] = useState(values.addressPoint ? 14 : 4);
  const [businessName, setBusinessName] = useState("");
  const [address, setAddress] = useState(values.address);

  const onPlaceChanged = function () {
    const place = autocomplete.getPlace();
    const pickedAddress = place.formatted_address;
    const latlng = place.geometry.location;
    const niceLatLng = { lat: latlng.lat(), lng: latlng.lng() };
    const busName = place.name;
    setBusinessName(busName);
    setAddress(pickedAddress);
    setCoords(niceLatLng);
    setMapZoom(14);
  };

  useEffect(() => {
    setFieldValue("address", address, false);
    setFieldValue("addressPoint", coords, false);
  }, [coords, address, setFieldValue]);

  const onLoad = function (autocompleteObj) {
    setAutocomplete(autocompleteObj);
    if (values.addressPoint) {
      setCoords(values.addressPoint);
    }
  };

  const gmapsLibraries = ["places"];

  return (
    <LoadScript googleMapsApiKey="AIzaSyCOA3CxqX6LLv0Cv5YHliWHiW-K0vsq_yg" libraries={gmapsLibraries}>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        // fields={['formatted_address', 'geometry', 'name']}
      >
        <InputDiv text="Address" name="address" placeholder="Add business address" hasLabel />
      </Autocomplete>
      <InputField type="hidden" text="Point" name="addressPoint" />
      <MapWrap>
        <GoogleMap id="searchbox-example" mapContainerStyle={mapContainerStyle} zoom={mapZoom} center={coords}>
          {mapZoom > 4 && <Marker title={businessName} position={coords} />}
        </GoogleMap>
      </MapWrap>
    </LoadScript>
  );
}

export default AddressField;
