import { Footer, Text } from "grommet";
import React from "react";

const DashboardFooter = () => {
  return (
    <Footer margin={{ top: "large" }} pad={{ vertical: "small" }}>
      <Text size="xsmall">&copy; Eatsee Menu Co 2021</Text>
    </Footer>
  );
};

export default DashboardFooter;
