import { Grommet, Heading, Image, Box, ResponsiveContext, Button } from "grommet";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import { theme } from "../constants/theme";
import PlaceList from "./PlaceList";
import logo from "../assets/images/logo.png";

const PlaceChoose = (props) => {
  const size = useContext(ResponsiveContext);
  const navigate = useNavigate();

  return (
    <Grommet theme={theme} full>
      <Helmet>
        <title>Eatsee Dashboard - Select Venue</title>
      </Helmet>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box>
            <Box direction="row" justify="end" margin={{ top: "small", right: "small" }}>
              <Button
                label="Logout"
                primary
                onClick={() => {
                  navigate("/logout");
                }}
              />
            </Box>
            <Box
              justify="center"
              direction="row"
              height={size === "small" || size === "xsmall" ? "xsmall" : "150px"}
              pad="medium"
            >
              <Image fit="contain" src={logo} alt="Eatsee Logo" />
            </Box>
            <Heading margin="large" justify="center" textAlign="center" level={1} size="small" fill alignSelf="center">
              Select a venue
            </Heading>
            <PlaceList />
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
};

export default PlaceChoose;
