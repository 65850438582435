import { Button, ResponsiveContext } from "grommet";
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

const ActionButton = ({ label, ...restProps }) => {
  const size = useContext(ResponsiveContext);
  if (size === "small" || size === "xsmall") {
    return (
      <ThemeContext.Extend
        value={{
          button: {
            primary: {
              padding: { vertical: "0.5em", horizontal: "0.7em" },
              border: { radius: "12px" },
              align: "center",
              justify: "center",
            },
          },
        }}
      >
        <Button {...restProps} />
      </ThemeContext.Extend>
    );
  }
  return <Button label={label} {...restProps} />;
};

export default ActionButton;
