import { PropTypes } from "prop-types";
import { ErrorMessage } from "formik";
import Error from "./Error";

function InputError(props) {
  return <ErrorMessage component={Error} name={props.name} />;
}

InputError.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputError;
