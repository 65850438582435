import { Box, Button, Text } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "pro-regular-svg-icons";
import { PropTypes } from "prop-types";

const Pagination = ({ currentPage, numPages, updatePage, latestData, isPreviousData }) => {
  return (
    <Box margin={{ vertical: "medium" }} direction="row" justify="between">
      <Button
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
        onClick={() => updatePage((old) => Math.max(old - 1, 1))}
        disabled={currentPage === 1}
        type="button"
        label="Previous"
      />
      <Text>
        Page {currentPage} of {numPages}
      </Text>
      <Button
        onClick={() => updatePage((old) => (!latestData || !latestData.next ? old : old + 1))}
        type="button"
        disabled={!latestData || !latestData.next || isPreviousData}
        label="Next"
        reverse
        icon={<FontAwesomeIcon icon={faChevronRight} />}
      />
    </Box>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  updatePage: PropTypes.func.isRequired,
  latestData: PropTypes.object.isRequired,
  isPreviousData: PropTypes.bool.isRequired,
};
export default Pagination;
