import * as Yup from "yup";
import { ALLOWED_FILE_TYPES } from "../constants/fileTypes";

const coverPhotoSchema = Yup.mixed()
  .notRequired()
  .test({
    name: "fileFormat",
    test: function test(value) {
      if (value) {
        if (!ALLOWED_FILE_TYPES.includes(value.type)) {
          return this.createError({
            path: "cover_image",
            message: `File format '${value.type}' is not supported.`,
          });
        }
      }
      return true;
    },
  });

const logoSchema = Yup.mixed()
  .notRequired()
  .test({
    name: "fileFormat",
    test: function test(value) {
      if (value) {
        if (!ALLOWED_FILE_TYPES.includes(value.type)) {
          return this.createError({
            path: "venue_logo",
            message: `File format '${value.type}' is not supported.`,
          });
        }
      }
      return true;
    },
  });

export { coverPhotoSchema, logoSchema };
