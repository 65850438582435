import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "pro-regular-svg-icons";
import { faPencil } from "pro-solid-svg-icons";
import { Box, Button, ResponsiveContext } from "grommet";
import { useMediaQuery } from "react-responsive";
import { ThemeContext, ThemeProvider } from "styled-components";
import { useParams } from "react-router-dom";
import { QueryClientProvider, useMutation, useQueryClient } from "react-query";
import findIndex from "lodash/findIndex";
import ReactSwal from "../utils/swalUtils";
import MenuForm from "./MenuForm";
import breakpoints from "../constants/breakpoints";
import { removeMenu } from "../api/mutations";
import { trackEventSegment } from "../utils/segmentUtils";

const MenuContentEditorControls = ({ updateMenus, activeMenu, menus, numOfMenus }) => {
  const { place } = useParams();

  const size = useContext(ResponsiveContext);

  const isSmall = size === "xsmall" || size === "small";

  const isSmallOrMed = size === "xsmall" || size === "small" || size === "medium";

  const addMenu = (menu) => {
    updateMenus((menus) => [...menus, menu]);
  };

  const { mutate } = useMutation(removeMenu);

  const cache = useQueryClient();

  const editFormContent = (
    <ThemeProvider theme={breakpoints}>
      <QueryClientProvider client={cache}>
        <MenuForm place={place} numOfMenus={numOfMenus} addNewMenu={addMenu} menu={activeMenu} />
      </QueryClientProvider>
    </ThemeProvider>
  );

  const addFormContent = (
    <ThemeProvider theme={breakpoints}>
      <QueryClientProvider client={cache}>
        <MenuForm place={place} numOfMenus={numOfMenus} addNewMenu={addMenu} />
      </QueryClientProvider>
    </ThemeProvider>
  );

  const isLargerScreen = useMediaQuery({
    query: "(max-width: 850px)",
  });

  const menuEdit = () => {
    return ReactSwal.fire({
      html: editFormContent,
      width: isLargerScreen ? "90%" : "50%",
      padding: 0,
      showConfirmButton: false,
      customClass: {
        content: "swal-additem",
      },
    });
  };

  const menuAdd = () => {
    return ReactSwal.fire({
      html: addFormContent,
      width: isLargerScreen ? "90%" : "50%",
      padding: 0,
      showConfirmButton: false,
      customClass: {
        content: "swal-additem",
      },
    });
  };

  const menuDelete = () => {
    ReactSwal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      text: "Deleting this menu will also delete any menu sections associated with it.",
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showLoaderOnConfirm: true,
      preConfirm: async (result) => {
        await mutate(
          { menuId: activeMenu.uuid },
          {
            onError: (resError) => {
              addMenu(activeMenu.uuid);
            },
            onSuccess: () => {
              const previousPlace = cache.getQueryData(["placeDetail", { place }]);
              const newPlace = { ...previousPlace };
              const menuIndex = findIndex(newPlace.menus, ["uuid", activeMenu.uuid]);
              newPlace.menus.splice(menuIndex, 1);
              cache.setQueryData(["placeDetail", { place }], newPlace);
              const newList = menus.filter((item) => item.uuid !== activeMenu.uuid);
              updateMenus(newList);
              trackEventSegment("Removed Menu", {
                uuid: activeMenu.uuid,
                name: activeMenu.name,
              });
            },
          }
        );
      },
      allowOutsideClick: () => !ReactSwal.isLoading(),
    });
  };

  if (isSmallOrMed) {
    return (
      <ThemeContext.Extend
        value={{
          button: {
            primary: {
              padding: isSmall
                ? { vertical: "0.25em", horizontal: "0.5em" }
                : { vertical: "0.4em", horizontal: "0.7em" },
              border: { radius: "12px" },
              align: "start",
              justify: "start",
              gap: "xxsmall",
            },
          },
          text: {
            medium: {
              size: "0.85em",
            },
          },
        }}
      >
        <Box direction="row" justify={isSmall ? "evenly" : "start"} gap="small">
          <Box width="1/3">
            <Button
              gap={isSmall ? "xsmall" : "small"}
              icon={<FontAwesomeIcon icon={faPlus} />}
              primary
              label="Add"
              onClick={() => menuAdd()}
            />
          </Box>
          <Box width="1/3">
            <Button
              gap={isSmall ? "xsmall" : "small"}
              icon={<FontAwesomeIcon icon={faPencil} />}
              primary
              label="Edit"
              onClick={() => menuEdit()}
            />
          </Box>
          <Box width="1/3">
            <Button
              gap={isSmall ? "xsmall" : "small"}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              primary
              label="Delete"
              onClick={() => menuDelete()}
            />
          </Box>
        </Box>
      </ThemeContext.Extend>
    );
  }

  return (
    <Box basis="1/2" direction="row" justify="evenly" gap="medium">
      <Box flex>
        <Button icon={<FontAwesomeIcon icon={faPlus} />} primary label="Add" onClick={() => menuAdd()} />
      </Box>
      <Box flex>
        <Button icon={<FontAwesomeIcon icon={faPencil} />} primary label="Edit" onClick={() => menuEdit()} />
      </Box>
      <Box flex>
        <Button icon={<FontAwesomeIcon icon={faTrashAlt} />} primary label="Delete" onClick={() => menuDelete()} />
      </Box>
    </Box>
  );
};

export default MenuContentEditorControls;
