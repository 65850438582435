import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, ResponsiveContext, TextInput, Button } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "pro-regular-svg-icons";
import { useDishes } from "../api/queries";
import Loader from "./Loader";
import DishItem from "./DishItem";

const MenuItems = () => {
  const size = useContext(ResponsiveContext);
  const { place } = useParams();

  const [searchQuery, setSearchQuery] = React.useState("");

  const { status, data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, error } = useDishes(
    place,
    searchQuery
  );
  return (
    <>
      <Box margin={{ vertical: "medium" }}>
        <TextInput
          icon={<FontAwesomeIcon icon={faSearch} />}
          placeholder="Search..."
          onChange={(event) => setSearchQuery(event.target.value)}
          value={searchQuery}
        />
      </Box>
      {!data ? (
        <Loader />
      ) : (
        <>
          <Grid columns={size !== "small" ? "medium" : "100%"} gap="large">
            {data.pages.map((cursor) =>
              cursor.results.map((dish) => {
                return <DishItem key={dish.uuid} dish={dish} draggable={false} />;
              })
            )}
          </Grid>

          <Button
            label={isFetchingNextPage ? "Loading more..." : hasNextPage ? "Load More" : "Nothing more to load"}
            primary
            type="button"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            margin={{ top: "medium" }}
          />
          <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div>
        </>
      )}
    </>
  );
};

export default MenuItems;
