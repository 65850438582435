import { PropTypes } from "prop-types";
import styled from "styled-components";
import { faTrashAlt, faGripVertical, faPencil } from "pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { between, up } from "styled-breakpoints";
import { useState } from "react";
import { Box, Layer, Text, Image } from "grommet";
import Loader from "./Loader";
import ENV from "../constants/envConstants";
import { brandRed } from "../constants/colours";
import comingSoonImage from "../assets/images/coming-soon.jpg";
import logo from "../assets/images/eatsee.svg";
import DishItemRemove from "./DishItemRemove";
import MenuItemForm from "./MenuItemForm";

const DishWrap = styled.div`
  border-radius: 12px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.16);
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: flex;
  height: 100%;
  background: white;
`;

const DishButtons = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  button:first-child {
    margin-bottom: 0.8em;
  }

  ${up("lg")} {
    button:first-child {
      margin-bottom: 0;
    }
    flex-direction: row;
  }

  ${up("xxl")} {
    button:first-child {
      margin-bottom: 0.8em;
    }
    flex-direction: column;
  }

  ${up("giant")} {
    button:first-child {
      margin-bottom: 0;
    }
    flex-direction: row;
  }
`;

const DishButton = styled.button`
  border: 0;
  background: ${brandRed};
  color: white;
  padding: 0.3em 1em;
  border-radius: 10px;
  cursor: pointer;
  font-size: 0.9em;
  white-space: nowrap;

  ${between("lg", "xl")} {
    flex-basis: 45%;
  }

  ${up("giant")} {
    flex-basis: 45%;
  }
`;

const GripButton = styled.span`
  position: absolute;
  right: 0.75em;
  top: 0.75em;
  background: rgba(255, 255, 255, 0.7);
  padding: 0.75em;
  border-radius: 50%;
  font-size: 1.1em;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
`;

const DishItem = ({ dish, draggable, displayImage, sectionId, numDishes, menuId, hasEditOptions, sectionDishId }) => {
  const [editFormVisible, setEditFormVisible] = useState(false);
  const [deleteFormVisible, setDeleteFormVisible] = useState(false);

  const hideAllPopups = () => {
    setEditFormVisible(false);
    setDeleteFormVisible(false);
  };

  if (dish) {
    const correctImageUrl = () => {
      if (dish.image.includes("http")) {
        return dish.image;
      }
      return ENV.BACKEND_BASE_URL + dish.image;
    };

    const editForm = (
      <Layer position="center" onEsc={() => hideAllPopups()} onClickOutside={() => hideAllPopups()} round>
        <Box background="white" border={{ color: "brand", size: "small" }} round="small" pad="large" width="xlarge">
          <MenuItemForm
            sectionId={sectionId}
            menuId={menuId}
            numOfDishes={numDishes}
            dish={dish}
            closePopup={hideAllPopups}
            hasImageField={displayImage}
          />
        </Box>
      </Layer>
    );

    const deleteForm = (
      <Layer position="center" onEsc={() => hideAllPopups()} onClickOutside={() => hideAllPopups()} round>
        <Box background="white" border={{ color: "brand", size: "small" }} round="small" pad="large" width="xlarge">
          <DishItemRemove
            sectionDishId={sectionDishId}
            dish={dish}
            closePopup={hideAllPopups}
            menuId={menuId}
            sectionId={sectionId}
          />
        </Box>
      </Layer>
    );

    const priceFormatted = new Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: dish.price_currency,
    }).format(dish.price);

    return (
      <>
        <DishWrap>
          {draggable && (
            <GripButton>
              <FontAwesomeIcon size="sm" icon={faGripVertical} color="black" />
            </GripButton>
          )}
          <Box height="200px">
            {displayImage ? (
              <Image fit="cover" fill src={dish.image ? correctImageUrl() : comingSoonImage} />
            ) : (
              <Image fit="cover" fill width="100%" height="200px" src={logo} />
            )}
          </Box>
          <Box pad="small" flex justify="around">
            <Text
              weight={500}
              alignSelf="center"
              color="#626262"
              margin={{ bottom: "small" }}
              wordBreak="break-word"
              size="1.1em"
            >
              {dish.name} - {priceFormatted}{" "}
            </Text>
            {hasEditOptions && (
              <DishButtons>
                <DishButton onClick={() => setEditFormVisible(true)}>
                  <FontAwesomeIcon size="lg" icon={faPencil} color="white" />
                  <Text weight={500} margin={{ left: "xsmall" }}>
                    Edit
                  </Text>
                </DishButton>
                <DishButton onClick={() => setDeleteFormVisible(true)}>
                  <FontAwesomeIcon size="lg" icon={faTrashAlt} color="white" />
                  <Text weight={500} margin={{ left: "xsmall" }}>
                    {draggable ? "Remove" : "Delete"}
                  </Text>
                </DishButton>
              </DishButtons>
            )}
          </Box>
        </DishWrap>
        {editFormVisible && editForm}
        {deleteFormVisible && deleteForm}
      </>
    );
  }
  return <Loader />;
};

DishItem.defaultProps = {
  draggable: true,
  displayImage: true,
  hasEditOptions: true,
  menuId: null,
};

DishItem.propTypes = {
  dish: PropTypes.object.isRequired,
  menuId: PropTypes.string,
  sectionId: PropTypes.string.isRequired,
  numDishes: PropTypes.number.isRequired,
  draggable: PropTypes.bool,
  displayImage: PropTypes.bool,
  hasEditOptions: PropTypes.bool,
  sectionDishId: PropTypes.string.isRequired,
};

export default DishItem;
