import { PropTypes } from "prop-types";
import { MuuriComponent, getResponsiveStyle, useDraggable, ItemDrag, useData } from "muuri-react";
import isEqual from "lodash/isEqual";
import { useMutation, useQueryClient } from "react-query";
import { createContext, useMemo, useContext, useState, useEffect } from "react";
import findIndex from "lodash/findIndex";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import { trackEventSegment } from "../utils/segmentUtils";
import DishItem from "./DishItem";
import { updateMenuCategoryOrder, editCategoryDish } from "../api/mutations";
import MenuItemAdd from "./MenuItemAdd";

export const ThemeContext = createContext(null);

const ThemeProvider = ({ children }) => {
  const isXlScreen = useMediaQuery({
    query: "(min-width: 1500px)",
  });

  const isSmScreen = useMediaQuery({
    query: "(min-width: 1150px)",
  });

  // Memoize the style.
  const style = useMemo(() => {
    let columns = 1;
    let margin = "5% 0";
    const height = "22.5em";

    if (isXlScreen) {
      columns = 0.999 / 3;
      margin = "2% 1%";
    } else if (isSmScreen) {
      columns = 0.999 / 2;
      margin = "0 2% 4% 2%";
    }

    return getResponsiveStyle({
      columns,
      margin,
      height,
    });
  }, [isSmScreen, isXlScreen]);

  return <ThemeContext.Provider value={style}>{children}</ThemeContext.Provider>;
};

const DraggableDishItem = ({ section, item, menuId }) => {
  const style = useContext(ThemeContext);
  useData(item);
  return (
    <div style={style} key={item.uuid} className="item">
      <div className="item-content" style={{ height: "100%" }}>
        <DishItem
          displayImage={section.display === "GRID_WITH_IMAGES"}
          menuId={menuId}
          sectionId={section.uuid}
          dish={item.dish}
          sectionDishId={item.uuid}
          numDishes={section.dishes.length}
        />
      </div>
    </div>
  );
};

const AddDish = ({ menuId, section }) => {
  const setDraggable = useDraggable();
  setDraggable(false);
  const style = useContext(ThemeContext);
  return (
    <div style={style} className="item">
      <div className="item-content">
        <MenuItemAdd
          hasImageField={section.display === "GRID_WITH_IMAGES"}
          sectionId={section.uuid}
          numOfDishes={section.dishes.length}
          menuId={menuId}
        />
      </div>
    </div>
  );
};

const SectionImageList = ({ section, menuId, changeLoaded }) => {
  const cache = useQueryClient();

  const { place } = useParams();

  const [dishes, setDishes] = useState(section.dishes);

  const onMutateReorder = (data) => {
    const { dishes } = data.values;
    const sectId = data.menuCategoryId;
    cache.cancelQueries(["placeDetail", { place }]);
    const previousPlace = cache.getQueryData(["placeDetail", { place }]);
    const newPlace = { ...previousPlace };
    const menuIndex = findIndex(newPlace.menus, ["uuid", menuId]);
    const sectIndex = findIndex(newPlace.menus[menuIndex].menu_categories, ["uuid", sectId]);
    newPlace.menus[menuIndex].menu_categories[sectIndex].dishes = dishes;
    cache.setQueryData(["placeDetail", { place }], newPlace);
    return () => cache.setQueryData(["placeDetail", { place }], previousPlace);
  };

  const { mutate: reorderMutate } = useMutation(updateMenuCategoryOrder, { onMutate: onMutateReorder });

  const onMutateUpdateDishSection = (data) => {
    const { transferredDish } = data;
    const newSect = data.values.menu_category;
    cache.cancelQueries(["placeDetail", { place }]);
    const previousPlace = cache.getQueryData(["placeDetail", { place }]);
    const newPlace = { ...previousPlace };
    const menuIndex = findIndex(newPlace.menus, ["uuid", menuId]);
    const sectIndex = findIndex(newPlace.menus[menuIndex].menu_categories, ["uuid", newSect]);
    newPlace.menus[menuIndex].menu_categories[sectIndex].dishes = newPlace.menus[menuIndex].menu_categories[
      sectIndex
    ].dishes.concat(transferredDish);
    cache.setQueryData(["placeDetail", { place }], newPlace);
    return () => cache.setQueryData(["placeDetail", { place }], previousPlace);
  };

  const { mutate: updateDishSection } = useMutation(editCategoryDish, { onMutate: onMutateUpdateDishSection });

  const children = section.dishes.map((item) => (
    <DraggableDishItem key={item.uuid} section={section} menuId={menuId} item={item} />
  ));
  children.push(<AddDish menuId={menuId} key="new" section={section} />);

  return (
    <>
      <ThemeProvider>
        <MuuriComponent
          dragEnabled
          groupIds={["dishes"]}
          onSend={({ key, toIndex, toId, fromGrid }) => {
            const transferredDish = dishes.find((dish) => dish.uuid === key);
            transferredDish.dish_order = toIndex + 1;
            transferredDish.menu_category = toId;
            const updatedDishes = dishes.filter((dish) => dish !== transferredDish);
            setDishes(updatedDishes);
            updateDishSection(
              {
                categoryDishId: key,
                transferredDish,
                values: {
                  menu_category: toId,
                  dish_order: toIndex + 1,
                },
              },
              {
                onSettled: () => {
                  cache.invalidateQueries(["placeDetail", { place }]);
                },
              }
            );
          }}
          onMount={(grid) => {
            grid.on("dragReleaseEnd", (item) => {
              const items = grid.getItems();
              if (item._component.props.section.uuid !== section.uuid) {
                return;
              }
              const updatedDishes = [];
              items.map((item, index) => {
                if (index + 1 !== items.length) {
                  const dish = item.getData();
                  dish.dish_order = index + 1;
                  updatedDishes.push(dish);
                }
              });
              if (!isEqual(updatedDishes, section.dishes)) {
                reorderMutate(
                  {
                    menuCategoryId: section.uuid,
                    values: {
                      dishes: updatedDishes,
                    },
                  },
                  {
                    onSettled: () => {
                      cache.invalidateQueries(["placeDetail", { place }]);
                      changeLoaded(true);
                    },
                    onSuccess: () => {
                      trackEventSegment("Reordered dishes", {
                        subCategoryName: section.name,
                        subCategoryUuid: section.uuid,
                      });
                    },
                    onError: (error, dishes, rollback) => {
                      rollback();
                    },
                  }
                );
              }
            });
          }}
          sort="dish_order"
          id={section.uuid}
          dragSortPredicate={(item, event) => {
            const result = ItemDrag.defaultSortPredicate(item, {
              action: "move",
              threshold: 40,
            });
            return result && result.index === section.dishes.length ? false : result;
          }}
          layoutOnResize={100}
        >
          {children}
        </MuuriComponent>
      </ThemeProvider>
    </>
  );
};

SectionImageList.propTypes = {
  section: PropTypes.object.isRequired,
  changeLoaded: PropTypes.func.isRequired,
  menuId: PropTypes.string.isRequired,
};
export default SectionImageList;
