import axios from "../axiosConfig";
import ENV from "../constants/envConstants";

export const login = (data) => {
  const { values } = data;
  return axios.post(`${ENV.BACKEND_URL}/restaurants/users/login/`, values, { withCredentials: true });
};

export const logout = () => {
  return axios.post(
    `${ENV.BACKEND_URL}/restaurants/users/logout/`,
    {},
    {
      withCredentials: true,
    }
  );
};

export const forgotPassword = (data) => {
  const { values } = data;
  return axios.post(`${ENV.BACKEND_URL}/restaurants/users/forgot-password/`, values);
};

export const forgotPasswordConfirm = (data) => {
  const { values } = data;
  return axios.post(`${ENV.BACKEND_URL}/restaurants/users/forgot-password-confirm/`, values);
};

export const updatePlace = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/`, values);
};

export const updatePlaceMenuOrder = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/reorder-menus/`, values);
};

export const createMenu = (data) => {
  const { values } = data;
  return axios.post(`${ENV.BACKEND_URL}/restaurants/menus/`, values);
};

export const updateMenu = (data) => {
  const { values, menuId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/menus/${menuId}/`, values);
};

export const removeMenu = (data) => {
  const { menuId } = data;
  return axios.delete(`${ENV.BACKEND_URL}/restaurants/menus/${menuId}/`);
};

export const updateMenuOrder = (data) => {
  const { values, menuId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/menus/${menuId}/reorder/`, values);
};

export const createSection = (data) => {
  const { values } = data;
  return axios.post(`${ENV.BACKEND_URL}/restaurants/menu-categories/`, values);
};

export const updateSection = (data) => {
  const { values, sectionId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/menu-categories/${sectionId}/`, values);
};

export const removeMenuSection = (data) => {
  const { menuId } = data;
  return axios.delete(`${ENV.BACKEND_URL}/restaurants/menu-categories/${menuId}/`);
};

export const editCategoryDish = (data) => {
  const { values, categoryDishId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/dishes/category-dish/${categoryDishId}/`, values);
};

export const updateMenuCategoryOrder = (data) => {
  const { values, menuCategoryId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/menu-categories/${menuCategoryId}/reorder/`, values);
};

export const addDishToSection = (data) => {
  const { values, categoryId } = data;
  return axios.post(`${ENV.BACKEND_URL}/restaurants/menu-categories/${categoryId}/add-dish/`, values);
};

export const addExistingDishToSection = (data) => {
  const { values } = data;
  return axios.post(`${ENV.BACKEND_URL}/restaurants/menu-categories/add-existing-dish/`, values);
};

export const editDish = (data) => {
  const { values, dishId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/dishes/${dishId}/`, values);
};

export const uploadPdfMenu = (data) => {
  const { placeId, values } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/upload-pdf-menu/`, values);
};

export const updatePlaceMenuSettings = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/settings/menu/`, values);
};

export const updatePlaceBusinessSettings = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/settings/business/`, values);
};

export const updatePlaceSEOSettings = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/settings/seo/`, values);
};

export const editOffer = (data) => {
  const { values, offerId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/leads/offers/edit/${offerId}/`, values);
};

export const removeOffer = (data) => {
  const { values, offerId } = data;
  return axios.delete(`${ENV.BACKEND_URL}/restaurants/leads/offers/delete/${offerId}/`, values);
};

export const createOffer = (data) => {
  const { values } = data;
  return axios.post(`${ENV.BACKEND_URL}/restaurants/leads/offers/add/`, values);
};

export const changePassword = (data) => {
  const { values } = data;
  return axios.post(`${ENV.BACKEND_URL}/restaurants/users/change-password/`, values);
};

export const updateUser = (data) => {
  const { values } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/users/`, values);
};

export const deleteDish = (data) => {
  const { dishId } = data;
  return axios.delete(`${ENV.BACKEND_URL}/restaurants/dishes/${dishId}/`);
};

export const removeDish = (data) => {
  const { dishId } = data;
  return axios.delete(`${ENV.BACKEND_URL}/restaurants/menu-categories/remove-dish/${dishId}/`);
};

export const updateOfferSettings = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/leads/settings/${placeId}/`, values);
};

export const updatePlaceEmailPhone = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/settings/email-phone/`, values);
};

export const updatePlaceCoverPhoto = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/settings/cover-photo/`, values);
};

export const updatePlaceLogo = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/settings/logo/`, values);
};

export const updatePlaceOpeningHours = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/opening-hours/`, values);
};

export const updatePlaceCuisines = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/cuisines/`, values);
};

export const updatePlaceGuestRegSettings = (data) => {
  const { values, placeId } = data;
  return axios.put(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/guest-reg-settings/`, values);
};
