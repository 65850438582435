import React, { useEffect, useState, useRef } from "react";
import { Box, Heading } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "pro-solid-svg-icons";
import { useSpring, animated } from "react-spring";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useMeasure } from "react-use";

const CompleteAccountStep = ({ complete, title, content, updatePanel, index, activeIndex }) => {
  const props = useSpring({ opacity: complete ? 0.5 : 1 });

  const [isActive, setIsActive] = useState(false);

  const [contentHeight, setContentHeight] = useState("72px");

  const [ref, { height }] = useMeasure();
  const openCloseProps = useSpring({ height: isActive && !complete ? `${contentHeight + 72}px` : "72px" });

  const stepClick = () => {
    if (isActive) {
      setIsActive(false);
    }
    index === activeIndex ? updatePanel(null) : updatePanel(index);
  };

  useEffect(() => {
    if (activeIndex === index) {
      setIsActive(true);
    }
  }, [activeIndex]);

  useEffect(() => {
    setContentHeight(height);
    window.addEventListener("resize", setContentHeight(height));
    return window.removeEventListener("resize", setContentHeight(height));
  }, [height]);

  return (
    <Box border="xsmall" pad="xsmall" round="xsmall">
      <animated.div style={openCloseProps}>
        <Box direction="row" justify="between" style={{ opacity: complete ? 0.5 : 1 }} onClick={stepClick}>
          <Box pad={{ horizontal: "xsmall" }}>
            <Heading level={4} margin={{ vertical: "medium" }}>
              {title}
            </Heading>
          </Box>
          <Box justify="center" pad={{ horizontal: "small" }}>
            <animated.div style={props}>
              {complete ? (
                <FontAwesomeIcon icon={faCheckCircle} size="lg" color="green" />
              ) : (
                <FontAwesomeIcon icon={isActive ? faChevronUp : faChevronDown} size="lg" color="red" />
              )}
            </animated.div>
          </Box>
        </Box>
        {!complete && isActive && (
          <Box pad={{ horizontal: "xsmall" }} margin={{ bottom: "small" }} ref={ref}>
            {content}
          </Box>
        )}
      </animated.div>
    </Box>
  );
};

export default CompleteAccountStep;
