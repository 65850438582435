import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { PropTypes } from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import find from "lodash/find";
import forEach from "lodash/forEach";
import findIndex from "lodash/findIndex";
import { Select, Box, Heading, Button, Paragraph } from "grommet";
import { faWindowClose } from "pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import InputDiv from "./InputDiv";
import FileUploadInput from "./FileUploadInput";
import Loader from "./Loader";
import SelectField from "./SelectField";
import { useDiscoverySettings, useDishOptions, usePlace } from "../api/queries";
import { addDishToSection, editDish } from "../api/mutations";
import { handleUnknown, handle500 } from "../utils/errorHandles";
import { trackEventSegment } from "../utils/segmentUtils";
import ENV from "../constants/envConstants";
import dishExample from "../assets/images/dish-example.jpg";
import { getFileObjs } from "../utils/fileUtils";
import { ALLOWED_FILE_TYPES } from "../constants/fileTypes";
import FieldGrid from "./FieldGrid";
import FieldGridItem from "./FieldGridItem";
import InputLabel from "./InputLabel";
import Toast from "./Toast";

const MenuItemForm = ({ dish, numOfDishes, hasImageField, sectionId, menuId, closePopup, embedded }) => {
  const cache = useQueryClient();

  const { place } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [dietaryOptions, setDietaryOptions] = useState([]);
  const [selectedDietaryOptions, setSelectedDietaryOptions] = useState([]);

  const [currencyOptions, setCurrencyOptions] = useState([]);

  const { mutate: editDishMutate, isLoading: isEditing } = useMutation(editDish);

  const { mutate: addDishMutate, isLoading: isCreating } = useMutation(addDishToSection);

  const { data } = useDiscoverySettings();
  const { loading: optionsLoading, data: dishOptions } = useDishOptions(place);
  const { data: placeData } = usePlace(place);

  useEffect(() => {
    if (loaded === false && data && dishOptions && placeData) {
      const dietaryPrefs = [];
      data.map((item) => {
        dietaryPrefs.push(item.name);
      });
      setDietaryOptions(dietaryPrefs);
      setCurrencyOptions(dishOptions.actions.POST.price_currency.choices);
      setLoaded(true);
    }
  }, [loaded, data, dishOptions, placeData]);

  const correctImageUrl = () => {
    if (dish.image.includes("http")) {
      return dish.image;
    }
    return ENV.BACKEND_BASE_URL + dish.image;
  };

  const [imagePreview, setImagePreview] = useState(dish && dish.image ? correctImageUrl() : null);

  const setImagePrev = (url) => {
    setImagePreview(url);
  };

  const handleSelectedChoices = (newChoices) => {
    setSelectedDietaryOptions(newChoices);
  };

  useEffect(() => {
    if (dish) {
      handleSelectedChoices(dish.discovery_settings);
      if (dish.image && hasImageField) {
        setImagePrev(correctImageUrl());
      }
    }
  }, [dish]);

  const dishCreate = async (valuesToSend, bag) => {
    await addDishMutate(
      {
        values: {
          dishes: [
            {
              dish: valuesToSend,
              dish_order: numOfDishes + 1,
            },
          ],
        },
        categoryId: sectionId,
      },
      {
        onSuccess: (resData) => {
          bag.resetForm();
          setImagePrev(null);
          setSelectedDietaryOptions([]);
          const resDishes = resData.data.dishes;
          // TODO this will not work when multiple people are adding dishes - race
          const dish = find(resDishes, ["dish_order", numOfDishes + 1]);
          trackEventSegment("Added Dish", {
            name: dish.dish.name,
            uuid: dish.dish.uuid,
          });
          const previousPlace = cache.getQueryData(["placeDetail", { place }]);
          const newPlace = { ...previousPlace };
          const menuIndex = findIndex(newPlace.menus, ["uuid", menuId]);
          const sectIndex = findIndex(newPlace.menus[menuIndex].menu_categories, ["uuid", sectionId]);
          newPlace.menus[menuIndex].menu_categories[sectIndex].dishes = resDishes;
          cache.setQueryData(["placeDetail", { place }], newPlace);
          Toast.fire({
            title: "Dish created",
            icon: "success",
          });
          closePopup();
        },
        onError: (error) => {
          const { status } = error.response;
          const resData = error.response.data;
          switch (status) {
            case 400:
              if (resData.error_type === "ValidationError") {
                forEach(resData.errors[0].message.dish, (message, field) => {
                  bag.setFieldError(field, message[0]);
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
        },
      },
    );
    bag.setSubmitting(false);
    setSubmitting(false);
  };

  const dishEdit = async (valuesToSend, bag) => {
    await editDishMutate(
      {
        values: valuesToSend,
        dishId: dish.uuid,
      },
      {
        onSuccess: (resData) => {
          const editedDish = resData.data;
          if (menuId != null) {
            const previousPlace = cache.getQueryData(["placeDetail", { place }]);
            const newPlace = { ...previousPlace };
            const menuIndex = findIndex(newPlace.menus, ["uuid", menuId]);
            const sectIndex = findIndex(newPlace.menus[menuIndex].menu_categories, ["uuid", sectionId]);
            const dishIndex = findIndex(newPlace.menus[menuIndex].menu_categories[sectIndex].dishes, [
              "dish_uuid",
              editedDish.uuid,
            ]);
            newPlace.menus[menuIndex].menu_categories[sectIndex].dishes[dishIndex].dish = editedDish;
            cache.setQueryData(["placeDetail", { place }], newPlace);
          }
          trackEventSegment("Edited Dish", {
            name: editedDish.name,
            uuid: editedDish.uuid,
          });
          bag.resetForm();
          setImagePrev(null);
          setSelectedDietaryOptions([]);
          Toast.fire({
            title: "Dish updated",
            icon: "success",
          });
          closePopup();
        },
        onSettled: () => {
          cache.invalidateQueries(["placeDetail", { place }]);
        },
        onError: (error) => {
          const { status } = error.response;
          const resData = error.response.data;
          switch (status) {
            case 400:
              if (resData.error_type === "ValidationError") {
                forEach(resData.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
        },
      },
    );
    bag.setSubmitting(false);
    setSubmitting(false);
  };

  const onSubmit = (values, bag) => {
    const valuesToSend = { ...values };
    valuesToSend.price = valuesToSend.price.replace("$", "");
    valuesToSend.discovery_settings = selectedDietaryOptions;
    if (values.image && hasImageField) {
      getFileObjs(values).then((fileObj) => {
        valuesToSend.image = fileObj;
        dish ? dishEdit(valuesToSend, bag) : dishCreate(valuesToSend, bag);
      });
    } else {
      dish ? dishEdit(valuesToSend, bag) : dishCreate(valuesToSend, bag);
    }
  };

  const schema = Yup.object({
    name: Yup.string().required("This field is required."),
    price: Yup.string().required("This field is required."),
    price_currency: Yup.string().required("This field is required."),
    image: Yup.mixed()
      .notRequired()
      .test({
        name: "fileFormat",
        test: function test(value) {
          if (value) {
            if (!ALLOWED_FILE_TYPES.includes(value.type)) {
              return this.createError({
                path: "image",
                message: `File format '${value.type}' is not supported.`,
              });
            }
          }
          return true;
        },
      }),
  });

  if (!loaded) {
    return <Loader />;
  }

  const initialValues = {
    name: dish ? dish.name : "",
    description: dish ? dish.description : "",
    price: dish ? dish.price : "",
    price_currency: dish ? dish.price_currency : placeData.default_currency,
    image: undefined,
  };

  if (dish) {
    forEach(dish, (value, field) => {
      if (field !== "image") {
        initialValues[field] = value;
      }
    });
  }

  const formContent = () => {
    return (
      <Box overflow={{ vertical: submitting || isEditing || isCreating ? "none" : "auto" }}>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
          {(formik) =>
            formik.isSubmitting || submitting || isEditing || isCreating ? (
              <Loader />
            ) : (
              <>
                <Form>
                  <Box>
                    <Box flex direction="row" justify="between">
                      <Box basis="3/5">
                        <Heading margin="none" size="small">
                          {dish ? "Edit" : "Add new"} menu item
                        </Heading>
                        <Paragraph fill>
                          You can drag and drop the items to change their order, but only after you’re finished editing.
                        </Paragraph>
                      </Box>
                      {!embedded && (
                        <Box basis="2/5" align="end">
                          <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => closePopup()} />
                        </Box>
                      )}
                    </Box>
                    <InputDiv text="Menu item name" placeholder="For example avocado toast" name="name" hasLabel />
                    <InputDiv
                      text="Description (optional)"
                      placeholder="For example sourdough with avocado, feta and poached eggs"
                      name="description"
                      hasLabel
                      type="textarea"
                    />
                    <FieldGrid>
                      <FieldGridItem>
                        <InputDiv text="Price" placeholder="For example $12" name="price" hasLabel />
                      </FieldGridItem>
                      <FieldGridItem>
                        <InputLabel text="Price currency" for="price_currency" />
                        <Select
                          options={currencyOptions}
                          valueKey={{ key: "value", reduce: true }}
                          labelKey="display_name"
                          value={formik.values.price_currency}
                          onSearch={(text) => {
                            const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
                            const exp = new RegExp(escapedText, "i");
                            setCurrencyOptions(
                              dishOptions.actions.POST.price_currency.choices.filter(
                                ({ value, display_name }) => exp.test(value) || exp.test(display_name),
                              ),
                            );
                          }}
                          onChange={({ value: nextValue }) => formik.setFieldValue("price_currency", nextValue)}
                          name="price_currency"
                        />
                      </FieldGridItem>
                    </FieldGrid>
                    {hasImageField && (
                      <FileUploadInput
                        name="image"
                        text="Item photo (optional)"
                        exampleFile={dishExample}
                        helpText="This image will be used as the items image in your visual menu. Recommended image dimensions 350x500px, to see an example of an ideal image"
                        hasLabel
                        handleFileInput={(fileName, fieldName) => {
                          formik.setFieldValue(fieldName, fileName);
                        }}
                        removePreview={() => null}
                        setPreview={setImagePrev}
                        preview={imagePreview}
                        previewNewTab
                      />
                    )}
                    <>
                      <SelectField
                        choices={dietaryOptions}
                        selectedChoices={selectedDietaryOptions}
                        handleSelectedChoices={handleSelectedChoices}
                        label="Dietary requirements (optional)"
                      />
                      <Button
                        label={dish ? "Edit menu item" : "Add menu item"}
                        primary
                        type="submit"
                        submitting={formik.isSubmitting}
                      />
                    </>
                  </Box>
                </Form>
              </>
            )
          }
        </Formik>
      </Box>
    );
  };

  return formContent();
};

MenuItemForm.defaultProps = {
  embedded: false,
  menuId: null,
};

MenuItemForm.propTypes = {
  sectionId: PropTypes.string.isRequired,
  menuId: PropTypes.string,
  numOfDishes: PropTypes.number.isRequired,
  dish: PropTypes.object,
  embedded: PropTypes.bool.isRequired,
};

export default MenuItemForm;
