import React, { useContext, useState, useEffect } from "react";
import { Form, Formik, FieldArray } from "formik";
import { useParams } from "react-router-dom";
import { Box, Heading, Button, ResponsiveContext } from "grommet";
import { useMutation, useQueryClient } from "react-query";
import forEach from "lodash/forEach";
import Loader from "./Loader";
import { updatePlaceGuestRegSettings } from "../api/mutations";
import { usePlace } from "../api/queries";
import GuestRegConfs from "./GuestRegConfs";
import SwitchInput from "./SwitchInput";
import Toast from "./Toast";
import { trackEventSegment } from "../utils/segmentUtils";
import { handle500, handleUnknown } from "../utils/errorHandles";
import HelpText from "./HelpText";

const GuestRegistrationSettings = () => {
  const { place } = useParams();
  const cache = useQueryClient();

  const [loaded, setLoaded] = useState(false);

  const size = useContext(ResponsiveContext);
  const { status, data } = usePlace(place);
  const isSmall = size === "small" || size === "xsmall";

  const [formData, setFormData] = useState({
    contact_tracing_enabled: false,
    guest_reg_confirmations: [],
  });

  useEffect(() => {
    if (loaded === false && data) {
      setFormData({
        guest_reg_confirmations: data.guest_reg_confirmations,
        contact_tracing_enabled: data.contact_tracing_enabled,
      });
      setLoaded(true);
    }
  }, [data]);

  const { mutate, isLoading } = useMutation(updatePlaceGuestRegSettings);

  const onSubmit = async (values, bag) => {
    await mutate(
      {
        placeId: place,
        values,
      },
      {
        onSuccess: (data) => {
          trackEventSegment("Guest registration settings updated");
          Toast.fire({
            title: "Guest Registration Settings updated",
            icon: "success",
          });
          bag.setSubmitting(false);
        },
        onSettled: () => {
          cache.invalidateQueries(["placeDetail", { place }]);
        },
        onError: (error, newSect, rollback) => {
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
          bag.setSubmitting(false);
        },
      }
    );
  };

  if (loaded === false) {
    return <Loader />;
  }

  return (
    <>
      <Formik initialValues={formData} onSubmit={onSubmit}>
        {(formik) =>
          formik.isSubmitting || isLoading ? (
            <Loader />
          ) : (
            <Form>
              <Box flex direction="row" justify="between" margin={{ bottom: "medium" }}>
                <Box basis="3/4">
                  <Heading margin={{ bottom: "medium", top: "none" }} size="small">
                    Guest Registration Settings
                  </Heading>
                </Box>
                <Box basis="1/4">
                  <Button primary type="submit" label={isSmall ? "Save" : "Save Changes"} />
                </Box>
              </Box>
              <SwitchInput
                defaultChecked={formik.values.contact_tracing_enabled}
                toggleChange={(value) =>
                  formik.setFieldValue("contact_tracing_enabled", !formik.values.contact_tracing_enabled)
                }
                label="Enable digital guest register"
                helpText={
                  "In line with COVID-19 regulations, it is mandatory (in Australia) to enable contact tracing at your venue. Customers can check in \
                  through a QR code guest register before dining in. This information is stored securely in your restaurant dashboard and not \
                  used for marketing purposes."
                }
              />
              <Heading level={4}>Guest Registration Confirmation Messages</Heading>
              <HelpText>
                These confirmation messages will appear as checkboxes on the guest registration form. If they are not
                checked, users will be shown a validation message stating that they must check the box(es).
              </HelpText>
              <FieldArray
                name="guest_reg_confirmations"
                render={(arrayHelpers) => <GuestRegConfs arrayHelpers={arrayHelpers} />}
              />
            </Form>
          )
        }
      </Formik>
    </>
  );
};

export default GuestRegistrationSettings;
