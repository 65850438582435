import React, { useState, useEffect, useContext } from "react";
import { Box, Button, DateInput, Heading, Text, Paragraph, ResponsiveContext } from "grommet";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "pro-regular-svg-icons";
import Container from "./Container";
import { leadExportUrl, useLeads } from "../api/queries";
import Loader from "./Loader";
import LeadDataItem from "./LeadDataItem";
import Pagination from "./Pagination";
import PaginationLoader from "./PaginationLoader";

const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: "short",
  day: "numeric",
  year: "numeric",
});

const LeadData = () => {
  const { place } = useParams();

  const [page, setPage] = useState(1);
  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";

  const [numPages, setNumPages] = useState(1);

  const today = dayjs();
  const sevenDaysAgo = dayjs().subtract(7, "day");

  const [value, setValue] = useState([sevenDaysAgo, today]);

  const { data, isPreviousData, isFetching } = useLeads(place, {
    page,
    modified_after: value[0].format("YYYY-MM-DD"),
    modified_before: value[1].format("YYYY-MM-DD"),
  });

  const [exportUrl, setExportUrl] = useState(
    leadExportUrl(place, value[1].format("YYYY-MM-DD"), value[0].format("YYYY-MM-DD"))
  );

  useEffect(() => {
    if (data) {
      const numOfPages = Math.ceil(data.count / 12);
      setNumPages(numOfPages === 0 ? 1 : numOfPages);
    }
  }, [data]);

  const updatePage = (num) => {
    setPage(num);
  };

  const dateChange = (event) => {
    const dateFrom = dayjs(event.value[0]);
    const dateTo = dayjs(event.value[1]);
    setValue([dateFrom, dateTo]);
    setPage(1);
  };

  useEffect(() => {
    setExportUrl(leadExportUrl(place, value[1].format("YYYY-MM-DD"), value[0].format("YYYY-MM-DD")));
  }, [value]);

  if (!data) {
    return <Loader />;
  }

  return (
    <Box height={{ min: "auto" }}>
      <Container>
        <Box direction={isSmall ? "column" : "row"} justify="between" gap="large">
          <Box basis="1/2">
            <Heading level={2} margin={{ bottom: "small", top: "none" }} size="medium">
              Lead data
            </Heading>
            <Heading level={3} size={isSmall ? "medium" : "20px"} margin={{ top: "none", bottom: "medium" }}>
              View generated customer leads.
            </Heading>
          </Box>
          <Box basis="1/2" direction="row" justify={isSmall ? "start" : "end"} align="start">
            <Box direction="row" gap="small">
              <Button primary label="Export" href={exportUrl} />
              <DateInput
                buttonProps={{
                  label: `${dateFormat.format(new Date(value[0]))} - ${dateFormat.format(new Date(value[1]))}`,
                  secondary: true,
                }}
                onChange={dateChange}
                value={value}
              />
            </Box>
          </Box>
        </Box>
        {data.results.length === 0 ? (
          <Box margin={{ top: "large" }}>
            <Paragraph>No leads generated during this period.</Paragraph>
          </Box>
        ) : (
          <Pagination
            numPages={numPages}
            currentPage={page}
            isPreviousData={isPreviousData}
            latestData={data}
            updatePage={updatePage}
          />
        )}
        <PaginationLoader loading={isFetching} />
        <Box gap="medium" fill height={{ min: "auto" }}>
          {data.results.map((lead) => {
            return <LeadDataItem key={lead.pk} lead={lead} />;
          })}
        </Box>
      </Container>
    </Box>
  );
};

export default LeadData;
