import React, { useEffect, useState, useContext } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, Heading, Select, ResponsiveContext, Button } from "grommet";
import Loader from "./Loader";
import InputLabel from "./InputLabel";
import SwitchInput from "./SwitchInput";
import { useOffersNoPagination, useOfferSettings } from "../api/queries";
import { updateOfferSettings } from "../api/mutations";
import { trackEventSegment } from "../utils/segmentUtils";
import Toast from "./Toast";

const OfferPreferences = ({ offerPreferences }) => {
  const { place } = useParams();

  const [loaded, setLoaded] = useState(false);

  const { isFetching: isFetchingSettings, data: offerSettings } = useOfferSettings(place);
  const { isFetching: isFetchingOffers, data: offers } = useOffersNoPagination(place);

  const { mutate, isLoading } = useMutation(updateOfferSettings);

  const [formData, setFormData] = useState({
    enabled: null,
    active_offer: "",
  });

  const onSubmit = async (values, bag) => {
    await mutate(
      { placeId: place, values },
      {
        onSuccess: (data) => {
          trackEventSegment("Updated lead generation preferences");
          Toast.fire({
            title: "Lead generation preferences updated",
            icon: "success",
          });
        },
      }
    );
  };

  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";

  useEffect(() => {
    if (loaded === false && offerSettings) {
      const loadedData = {
        enabled: offerSettings.enabled,
        active_offer: offerSettings.active_offer ? offerSettings.active_offer : "",
      };
      setFormData(loadedData);
      setLoaded(true);
    }
  }, [offerSettings, loaded]);

  if (!loaded) {
    return <Loader />;
  }

  return (
    <>
      <Formik initialValues={formData} onSubmit={onSubmit}>
        {(formik) =>
          formik.isSubmitting || isLoading ? (
            <Loader />
          ) : (
            <Form>
              <Box flex direction="row" justify="between" margin={{ bottom: "medium" }}>
                <Box basis="3/4">
                  <Heading margin={{ bottom: "medium", top: "none" }} size="small">
                    Lead generation preferences
                  </Heading>
                </Box>
                <Box basis="1/4">
                  <Button primary type="submit" label={isSmall ? "Save" : "Save Changes"} />
                </Box>
              </Box>
              <SwitchInput
                defaultChecked={formik.values.enabled}
                toggleChange={(value) => formik.setFieldValue("enabled", !formik.values.enabled)}
                label="Enabled"
                helpText="Designates wIf enabled, when viewing your menu, customers will be shown your selected active lead generator."
              />
              <Box margin={{ vertical: "large" }}>
                {!offers ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel text="Active Lead Generator" for="active_offer" />
                    <Select
                      options={[{ title: "------------", uuid: "" }, ...offers]}
                      value={formik.values.active_offer}
                      placeholder="Select an offer"
                      labelKey="title"
                      onChange={({ value: nextValue }) => formik.setFieldValue("active_offer", nextValue)}
                      name="active_offer"
                      valueKey={{ key: "uuid", reduce: true }}
                    />
                  </>
                )}
              </Box>
            </Form>
          )
        }
      </Formik>
    </>
  );
};

export default OfferPreferences;
