import { up } from "styled-breakpoints";
import styled, { ThemeProvider } from "styled-components";
import { PropTypes } from "prop-types";
import logo from "../assets/images/logo.png";
import breakpoints from "../constants/breakpoints";

const FormContainer = styled.div`
  border-radius: 15px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.16);
  padding: 1.5em 2em;
  font-size: 0.9em;
  margin-bottom: 1em;

  ${up("sm")} {
    padding: 2em 3em;
    font-size: 0.95em;
  }

  ${up("lg")} {
    padding: 2.25em 3.5em;
    font-size: 1em;
  }

  ${up("xl")} {
    padding: 2.5em 4em;
  }

  ${up("xxl")} {
    padding: 3em 4em;
  }

  ${up("giant")} {
    padding: 4em;
  }
`;

const Main = styled.div`
  max-width: 90%;
  margin: 0 auto;
  display: block;
  margin-top: 1em;
  box-sizing: border-box;
  font-family: "Gordita";

  ${up("sm")} {
    max-width: 85%;
    margin-top: 1.5em;
  }

  ${up("md")} {
    max-width: 80%;
  }

  ${up("lg")} {
    margin-top: 1.75em;
    max-width: 70%;
  }

  ${up("xl")} {
    max-width: 60%;
    margin-top: 2em;
  }

  ${up("xxl")} {
    max-width: 55%;
    margin-top: 3em;
  }

  ${up("giant")} {
    max-width: 50%;
    margin-top: 3.5em;
  }
`;

const HeadingContainer = styled.div`
  margin-bottom: 2em;

  ${up("md")} {
    margin-bottom: 2.25em;
  }

  ${up("lg")} {
    margin-bottom: 2.5em;
  }

  ${up("xl")} {
    margin-bottom: 2.75em;
  }

  ${up("xxl")} {
    margin-bottom: 3em;
  }

  ${up("giant")} {
    margin-bottom: 4em;
  }
`;

const Header = styled.h2`
  font-size: 1.3em;
  font-weight: 500;
  text-align: center;
  font-family: Gordita;

  ${up("md")} {
    font-size: 1.4em;
  }

  ${up("lg")} {
    font-size: 1.6em;
  }

  ${up("xl")} {
    font-size: 1.7em;
  }

  ${up("xxl")} {
    font-size: 1.9em;
  }

  ${up("giant")} {
    font-size: 2em;
  }
`;

const Subheader = styled.h3`
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  font-family: "Gordita";

  ${up("md")} {
    font-size: 1.05em;
  }

  ${up("lg")} {
    font-size: 1.1em;
  }

  ${up("xl")} {
    font-size: 1.2em;
  }
`;

const LogoElem = styled.img`
  height: 40px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 1.5em;
  display: block;

  ${up("sm")} {
    max-width: 70%;
    margin-bottom: 2em;
  }

  ${up("md")} {
    margin-bottom: 2.5em;
  }

  ${up("lg")} {
    max-width: 50%;
    height: 45px;
    margin-bottom: 3em;
  }

  ${up("xl")} {
    margin-bottom: 3em;
    height: 50px;
  }

  ${up("xxl")} {
    height: 60px;
    margin-bottom: 4em;
  }

  ${up("giant")} {
    margin-bottom: 4.5em;
  }
`;

const AuthBase = (props) => {
  return (
    <ThemeProvider theme={breakpoints}>
      <Main>
        <LogoElem src={logo} alt="Eatsee Logo" />
        <HeadingContainer>
          <Header>{props.header}</Header>
          <Subheader>{props.subheader}</Subheader>
        </HeadingContainer>
        <FormContainer>{props.children}</FormContainer>
      </Main>
    </ThemeProvider>
  );
};

AuthBase.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
};

export default AuthBase;
