import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "pro-regular-svg-icons";
import { Card, CardBody, CardHeader, Text, Image, Box, ResponsiveContext } from "grommet";
import { useContext } from "react";
import ENV from "../constants/envConstants";

const PlaceItem = ({ place }) => {
  const navigate = useNavigate();
  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";

  const choosePlace = () => {
    window.analytics.identify({
      company: {
        id: place.uuid,
        name: place.name,
      },
    });
    navigate(`/${place.uuid}`, { state: { title: "Menu Builder" } });
  };

  return (
    <Card
      key={place.uuid}
      justify="start"
      align="start"
      alignContent="start"
      pad="medium"
      direction="row"
      width="large"
      onClick={choosePlace}
    >
      <CardHeader margin={{ right: "medium" }}>
        {place.venue_logo ? (
          <Image height={isSmall ? "24px" : "36px"} src={place.venue_logo} fit="contain" />
        ) : (
          <FontAwesomeIcon size={isSmall ? "lg" : "2x"} icon={faBuilding} />
        )}
      </CardHeader>
      <CardBody alignSelf="center" direction="row" justify="between">
        <Text weight={500} size={isSmall ? "medium" : "large"} justify="end">
          {place.name}
        </Text>
        <Box round="small" background="brand" pad="xsmall" width="1/4" basis="1/4" height="min-content">
          <Text alignSelf="center" size={isSmall ? "xsmall" : "small"}>
            {place.current_plan} {!isSmall && "plan"}
          </Text>
        </Box>
      </CardBody>
    </Card>
  );
};

export default PlaceItem;
