import { Field, ErrorMessage } from "formik";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import { faTrashAlt, faPlus, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flatpickr from "react-flatpickr";
import InputLabel from "./InputLabel";
import HelpText from "./HelpText";
import "flatpickr/dist/themes/material_green.css";
import { brandRed } from "../constants/colours";
import Error from "./Error";
import AddMore from "./AddMore";

const RemoveButton = styled.span`
  margin: auto 0;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  color: black;
  flex: 3;
  font-size: 0.8em;
  position: absolute;
  right: 0;
  top: 60%;

  ${up("sm")} {
    position: static;
  }
`;

const InputInner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2em;

  ${up("sm")} {
    margin-bottom: 1em;
    flex-direction: row;
  }
`;

const FieldWrap = styled.div`
  margin-bottom: 5em;
`;

const SelectWrap = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 1em;

  ${up("sm")} {
    width: 40%;
    margin-bottom: 0;
  }
`;

const SelectDropDown = styled.div`
  position: absolute;
  bottom: 33%;
  right: 1em;
`;

const CustomSelect = styled.select`
  color: black;
  background: none;
  border: ${brandRed} 2px solid;
  border-radius: 5px;
  padding: 0.6em 1em;
  font-weight: 500;
  text-align: center;
  appearance: none;
  width: 100%;
  height: 100%;

  ${up("sm")} {
    padding: 0.25em 1.5em;
  }

  ${up("md")} {
    padding: 0.25em 2em;
  }

  ${up("lg")} {
    padding: 0.25em 2.5em;
  }

  ${up("xl")} {
    padding: 0.25em 3em;
  }

  ${up("xxl")} {
    padding: 0.25em 4em;
  }
`;

const CustomInputEl = styled.input`
  color: black;
  background: none;
  border: ${brandRed} 2px solid;
  border-radius: 5px;
  padding: 0.6em 0em;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  text-align: center;

  ${up("sm")} {
    width: 45%;
  }
`;

const TimeWrap = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${up("sm")} {
    width: 50%;
    margin-left: 0.5em;
    flex-direction: row;
  }

  ${up("md")} {
    margin-left: 1em;
  }
`;

const To = styled.span`
  text-align: center;
  margin: 0.2em 0;
  align-self: center;

  ${up("sm")} {
    margin: 0 0.2em;
  }
`;

const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
  return <CustomInputEl {...props} defaultValue={defaultValue} ref={inputRef} />;
};

const Availability = ({arrayHelpers, label, helpText}) => {
  const addNew = () => {
    arrayHelpers.push({
      day: "EVERY_DAY",
      startTime: "7:00",
      endTime: "22:00",
    });
  };
  return (
    <FieldWrap>
      {label && ( <InputLabel text={label} />)}
      {helpText && (<HelpText>{helpText}</HelpText>)}
      {arrayHelpers.form.values.[arrayHelpers.name].map((menuAvailability, index) => (
        <InputInner key={index}>
          <SelectWrap>
            <Field name={`${arrayHelpers.name}.${index}.day`} as={CustomSelect}>
              <option value="EVERY_DAY">Every day</option>
              <option value="WEEKDAYS">Mon-Fri</option>
              <option value="WEEKENDS">Sat-Sun</option>
              <option value="MONDAY">Monday</option>
              <option value="TUESDAY">Tuesday</option>
              <option value="WEDNESDAY">Wednesday</option>
              <option value="THURSDAY">Thursday</option>
              <option value="FRIDAY">Friday</option>
              <option value="SATURDAY">Saturday</option>
              <option value="SUNDAY">Sunday</option>
            </Field>
            <SelectDropDown>
              <FontAwesomeIcon icon={faChevronDown} size="sm" />
            </SelectDropDown>
          </SelectWrap>
          <TimeWrap>
            <Flatpickr
              data-enable-time
              render={({ defaultValue, value, ...props }, ref) => {
                return <CustomInput defaultValue={defaultValue} inputRef={ref} />;
              }}
              onChange={(date) => {
                const time = `${date[0].getHours()}:${date[0].getMinutes()}`;
                const newValues = arrayHelpers.form.values[arrayHelpers.name][index];
                newValues.startTime = time;
                arrayHelpers.replace(index, newValues);
              }}
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "h:i K",
              }}
              value={arrayHelpers.form.values[arrayHelpers.name][index].startTime}
            />
            <To>to</To>
            <Flatpickr
              data-enable-time
              render={({ defaultValue, value, ...props }, ref) => {
                return <CustomInput defaultValue={defaultValue} inputRef={ref} />;
              }}
              onChange={(date) => {
                const time = `${date[0].getHours()}:${date[0].getMinutes()}`;
                const newValues = arrayHelpers.form.values[arrayHelpers.name][index];
                newValues.endTime = time;
                arrayHelpers.replace(index, newValues);
              }}
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "h:i K",
              }}
              value={arrayHelpers.form.values[arrayHelpers.name][index].endTime}
            />
          </TimeWrap>
          <RemoveButton onClick={() => arrayHelpers.remove(index)}>
            <FontAwesomeIcon icon={faTrashAlt} size="lg" color="grey" />
          </RemoveButton>
        </InputInner>
      ))}
      <ErrorMessage component={Error} name={arrayHelpers.name} />
      <AddMore onClick={() => addNew()} type="button">
        <FontAwesomeIcon icon={faPlus} size="lg" color="black" />
        Add more
      </AddMore>
    </FieldWrap>
  );
};

export default Availability;
