export const theme = {
  global: {
    font: {
      family: "Gordita",
      size: "18px",
      height: "20px",
    },
    colors: {
      grey: "#F2F2F2",
      brand: "#ff0049",
      focus: "#2491eb",
    },
    // focus: {
    // outline: {
    // color: "black",
    // size: "2px",
    // },
    // shadow: {
    //   color: "black",
    // },
    // },
  },
  button: {
    primary: {
      focus: {
        border: {
          color: "brand",
        },
      },
      border: {
        radius: "12px",
      },
      padding: {
        vertical: "0.8em",
        horizontal: "1em",
      },
      background: {
        color: "brand",
      },
      font: {
        weight: "500",
      },
    },
    secondary: {
      focus: {
        border: {
          color: "brand",
        },
      },
      border: {
        color: "focus",
        radius: "12px",
      },
      padding: {
        vertical: "0.8em",
        horizontal: "1em",
      },
      font: {
        weight: "500",
      },
      background: {
        color: "#EDEDED",
      },
      // color: "black",
    },
    default: {
      focus: {
        border: {
          color: "brand",
        },
      },
      border: {
        color: "brand",
      },
      // color: "black",
    },
  },
  anchor: {
    fontWeight: "500",
    color: "white",
  },
  menu: {
    icons: {
      color: "white",
    },
    box: {
      justify: "center",
      justifyContent: "space-between",
    },
    button: {
      justifyContent: "space-between",
    },
    extend: {
      justify: "start",
      justifyContent: "space-between",
      align: "start",
    },
    justifyContent: "space-between",
  },
  heading: {
    weight: 500,
    3: {
      weight: 400,
    },
    level: {
      1: {
        font: {},
        small: {
          size: "30px",
          height: "36px",
          maxWidth: "816px",
        },
        medium: {
          size: "46px",
          height: "52px",
          maxWidth: "1200px",
        },
        large: {
          size: "78px",
          height: "84px",
          maxWidth: "1968px",
        },
        xlarge: {
          size: "110px",
          height: "116px",
          maxWidth: "2736px",
        },
      },
    },
  },
  accordion: {
    border: undefined,
    justifyItems: "between",
  },
  table: {
    body: {
      pad: "medium",
    },
  },
  layer: {
    background: "none",
  },
};
