import { FieldArray, Form, Formik } from "formik";
import { Button } from "grommet";
import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import forEach from "lodash/forEach";
import Cuisines from "./Cuisines";
import Loader from "./Loader";
import { updatePlaceCuisines } from "../api/mutations";
import { trackEventSegment } from "../utils/segmentUtils";
import { handleUnknown, handle500 } from "../utils/errorHandles";
import Toast from "./Toast";

const CuisineForm = ({ place, closePopup }) => {
  const [loaded, setLoaded] = useState(false);

  const [formData, setFormData] = useState({
    cuisines: [],
  });

  const cache = useQueryClient();

  useEffect(() => {
    setFormData({
      cuisines: place ? place.cuisines : [],
    });
    setLoaded(true);
  }, [place]);

  const { mutate, isLoading } = useMutation(updatePlaceCuisines);

  const onSubmit = (values, bag) => {
    mutate(
      { values, placeId: place.uuid },
      {
        onSuccess: (response) => {
          const previousPlace = cache.getQueryData(["placeDetail", { place: place.uuid }]);
          const newPlace = { ...previousPlace };
          newPlace.cuisines = response.data.cuisines;
          cache.setQueryData(["placeDetail", { place: place.uuid }], newPlace);
          trackEventSegment("Updated place cuisines");
          Toast.fire({
            title: "Cuisines successfully updated",
            icon: "success",
          });
          closePopup();
        },
        onError: (error) => {
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
        },
      }
    );
  };

  if (!loaded) {
    return <Loader />;
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={formData} enableReinitialize>
      {(formik) =>
        isLoading ? (
          <Loader />
        ) : (
          <>
            <Form>
              <FieldArray name="cuisines" render={(arrayHelpers) => <Cuisines arrayHelpers={arrayHelpers} />} />
              <Button submitting={formik.isSubmitting} primary type="submit" label="Update cuisines" />
            </Form>
          </>
        )
      }
    </Formik>
  );
};

export default CuisineForm;
