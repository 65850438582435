import React from "react";
import { Box, Text, Meter } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "pro-regular-svg-icons";
import { useNavigate } from "react-router";
import { brandRed } from "../constants/colours";
import { getSetupScore } from "../utils/setupScore";

const AccountSetupButton = ({ place }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("account-setup");
  };

  const score = getSetupScore(place);

  return (
    <Box
      border={{ color: "rgba(0, 0, 0, 0.33)" }}
      pad="small"
      direction="row"
      justify="between"
      round="xsmall"
      onClick={handleClick}
    >
      <Box direction="row">
        <Meter
          type="circle"
          max={4}
          size="xxsmall"
          thickness="xsmall"
          value={4 - score}
          color="brand"
          margin={{ right: "small" }}
          aria-label="Account setup progress"
        />
        <Box>
          <Text>Account setup</Text>
          <Text size="small">{score} remaining steps</Text>
        </Box>
      </Box>
      <Box justify="center" margin={{ right: "xsmall" }}>
        <FontAwesomeIcon color={brandRed} icon={faChevronRight} />
      </Box>
    </Box>
  );
};

export default AccountSetupButton;
