import React from "react";
import { Card, Text, CardHeader, CardFooter } from "grommet";
import { faPhone, faEnvelope, faClock } from "pro-regular-svg-icons";
import dayjs from "dayjs";
import CardTrait from "./CardTrait";

const LeadDataItem = ({ lead }) => {
  const date = dayjs(lead.created).format("DD/MM/YY h:mmA");

  return (
    <Card
      background="white"
      pad={{ vertical: "medium", horizontal: "medium" }}
      flex
      fill="vertical"
      height="min-content"
    >
      <CardHeader direction="column" gap="small">
        <Text alignSelf="start" weight="bold" margin={{ bottom: "small" }}>
          {lead.full_name}
        </Text>
        <Text>{lead.feedback}</Text>
      </CardHeader>
      <CardFooter justify="start">
        <CardTrait content={lead.phone_number} icon={faPhone} />
        {lead.email && <CardTrait content={lead.email} icon={faEnvelope} />}
        <CardTrait content={date} icon={faClock} />
      </CardFooter>
    </Card>
  );
};

export default LeadDataItem;
