import styled from "styled-components";

const AddButton = styled.button`
  border: 2px dashed #ff5265;
  border-radius: 8px;
  width: 100%;
  padding: 1.5em 0;
  color: #ff5265;
  font-weight: 600;
  font-size: 1.2em;
  background: none;
  margin-bottom: 1em;
`;

export default AddButton;
