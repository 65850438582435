import { Formik, Form } from "formik";
import { Box, Heading, Button, Layer, ResponsiveContext } from "grommet";
import React, { useState, useContext } from "react";
import * as Yup from "yup";
import { useMutation } from "react-query";
import forEach from "lodash/forEach";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "pro-solid-svg-icons";
import { changePassword } from "../api/mutations";
import ReactSwal from "../utils/swalUtils";
import { trackEventSegment } from "../utils/segmentUtils";
import Toast from "./Toast";
import Loader from "./Loader";
import InputDiv from "./InputDiv";

const ChangePasswordPopup = ({ closePopup }) => {
  const [formData, setFormData] = useState({
    new_password1: "",
    new_password2: "",
    old_password: "",
  });
  const size = useContext(ResponsiveContext);

  const schema = Yup.object({
    new_password1: Yup.string().required("This field is required."),
    new_password2: Yup.string()
      .required("This field is required.")
      .oneOf([Yup.ref("new_password1"), null], "Passwords must match"),
    old_password: Yup.string().required("This field is required."),
  });

  const { mutate, isLoading } = useMutation(changePassword);

  const onSubmit = async (values, bag) => {
    const reqContent = { values };
    await mutate(reqContent, {
      onSuccess: () => {
        trackEventSegment("Changed password");
        closePopup();
        Toast.fire({
          title: "Password updated",
          icon: "success",
        });
      },
      onError: (error, variables) => {
        const { data } = error.response;
        switch (error.response.status) {
          case 400:
            if (data.error_type === "ValidationError") {
              forEach(data.errors, (error) => {
                bag.setFieldError(error.field, error.message);
              });
            }
            break;
          case 500:
            ReactSwal.fire({
              title: "Oops!",
              text:
                "It's not your fault! Our server has encountered an internal error. An adminstrator has been notified. Try refreshing your page in the interim.",
              width: "35rem",
            });
            break;
          default:
            ReactSwal.fire({
              title: "Oops!",
              text: "An unknown error has occured. Try refreshing your page in the interim.",
              width: "35rem",
            });
        }
      },
    });
  };

  return (
    <Layer
      full={size === "small" || size === "xsmall"}
      position="center"
      onClickOutside={closePopup}
      onEsc={closePopup}
    >
      <Box
        fill={size === "small" || size === "xsmall"}
        background="white"
        border={{ color: "brand", size: "small" }}
        round="small"
        width="xlarge"
      >
        <Box align="end" pad={{ right: "medium", top: "medium" }}>
          <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => closePopup()} />
        </Box>
        <Box pad={{ bottom: "large", left: "large", right: "large", top: "medium" }}>
          <Formik validationSchema={schema} initialValues={formData} onSubmit={onSubmit}>
            {(formik) =>
              formik.isSubmitting || isLoading ? (
                <Loader />
              ) : (
                <Form>
                  <Box flex direction="row" justify="between">
                    <Heading margin={{ bottom: "medium", top: "none" }} size="small">
                      Change password
                    </Heading>
                  </Box>
                  <InputDiv
                    type="password"
                    text="Old Password"
                    placeholder="Enter your previous password"
                    name="old_password"
                    hasLabel
                  />
                  <InputDiv
                    text="New Password"
                    placeholder="Enter your desired new password"
                    name="new_password1"
                    type="password"
                    hasLabel
                  />
                  <InputDiv
                    text="Confirm New Password"
                    placeholder="Confirm your desired new password"
                    name="new_password2"
                    type="password"
                    hasLabel
                  />
                  <Button primary label="Change Password" type="submit" />
                </Form>
              )
            }
          </Formik>
        </Box>
      </Box>
    </Layer>
  );
};

export default ChangePasswordPopup;
