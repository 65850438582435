import ENV from "../constants/envConstants";

/**
 * Track a page view with Segment.
 *
 * See https://segment.com/docs/sources/website/analytics.js/#page.
 */
export const trackPageViewSegment = (pageName, options = {}) => {
  if (ENV.IS_DEVELOPMENT) {
    console.log("trackPageViewSegment", pageName, options);
  }

  //   if (!ENV.IS_PRODUCTION) {
  //     return;
  //   }

  window.analytics.page(pageName, options);
};

/**
 * Identify a user with Segment.
 *
 * See https://segment.com/docs/sources/website/analytics.js/#identify.
 */
export const identifySegment = (id, traits = {}) => {
  if (ENV.IS_DEVELOPMENT) {
    console.log("identifySegment", traits, id);
  }

  //   if (!ENV.IS_PRODUCTION) {
  //     return;
  //   }
  if (id === null) {
    window.analytics.identify(traits);
  } else {
    window.analytics.identify(id, traits);
  }
};

export const identifyGroupSegment = (id, traits = {}) => {
  if (ENV.IS_DEVELOPMENT) {
    console.log("identifySegment", traits, id);
  }

  //   if (!ENV.IS_PRODUCTION) {
  //     return;
  //   }
  window.analytics.group(id, traits);
};

/**
 * Track an event with Segment.
 *
 * See https://segment.com/docs/sources/website/analytics.js/#track.
 */
export const trackEventSegment = (eventName, properties = {}) => {
  if (ENV.IS_DEVELOPMENT) {
    console.log("trackEventSegment", eventName, properties);
  }

  //   if (!ENV.IS_PRODUCTION) {
  //     return;
  //   }

  window.analytics.track(eventName, properties);
};
