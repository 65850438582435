import React from "react";
import { BarLoader } from "react-spinners";
import { Box } from "grommet";
import { brandRed } from "../constants/colours";

const PaginationLoader = ({ loading }) => {
  return (
    <Box justify="center" direction="row" pad={{ bottom: "small" }}>
      <BarLoader loading={loading} color={brandRed} width="90%" />
    </Box>
  );
};

export default PaginationLoader;
