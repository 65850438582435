import { Box, Heading, Text, ResponsiveContext, Paragraph, Button, Layer } from "grommet";
import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { faWindowClose } from "pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Confetti from "react-confetti";
import PricingTable from "./PricingTable";
import SwitchInput from "./SwitchInput";
import { usePlace } from "../api/queries";
import Loader from "./Loader";

const UpgradePopup = ({ togglePopup, title }) => {
  const { place } = useParams();

  const { data, isFetching } = usePlace(place);
  const size = useContext(ResponsiveContext);

  const isSmall = size === "small" || size === "xsmall";

  const [monthlyActive, setMonthlyActive] = useState(true);
  const [successActive, setSuccessActive] = useState(false);
  const [successPlan, setSuccessPlan] = useState("");
  const [showConfetti, setShowConfetti] = useState(true);

  const processSuccess = (planName) => {
    setSuccessActive(true);
    setSuccessPlan(planName);
  };

  if (!data) {
    return <Loader />;
  }

  const description =
    data.current_plan === "Starter" ? "🔑 Upgrade to unlock premium features" : "🔄 Switch from your current plan";

  const innerContent = (
    <Box fill height={{ min: "auto" }}>
      <Box fill margin={{ bottom: isSmall ? "small" : "medium" }} height={{ min: "auto" }}>
        {isSmall ? (
          <Box>
            <Box direction="row" justify="between" margin={{ bottom: "medium" }}>
              <Box>
                <Heading margin={{ bottom: "xsmall", top: "none" }} level={3}>
                  {title}
                </Heading>
                <Text size="small">{description}</Text>
              </Box>
              <Box direction="row">
                <Box margin={{ left: "medium" }}>
                  <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => togglePopup(false)} />
                </Box>
              </Box>
            </Box>
            <Box direction="row" justify="between">
              <Box direction="row">
                <Text weight="bold">{monthlyActive ? "Monthly" : "Yearly"}</Text>
                <SwitchInput defaultChecked toggleChange={() => setMonthlyActive(!monthlyActive)} />
              </Box>
              <Box direction="column">
                <Box background="brand" round pad="small">
                  <Text weight="bold" size="xsmall">
                    Save 20%
                  </Text>
                </Box>
                <Text alignSelf="center" size="xsmall" weight="bold">
                  Yearly plans
                </Text>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box direction="row" justify="between">
              <Heading margin="none" level={3}>
                {title}
              </Heading>
              <Box direction="row">
                <Text weight="bold">{monthlyActive ? "Monthly" : "Yearly"}</Text>
                <SwitchInput defaultChecked toggleChange={() => setMonthlyActive(!monthlyActive)} />
                <Box margin={{ left: "medium" }}>
                  <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => togglePopup(false)} />
                </Box>
              </Box>
            </Box>
            <Box direction="row" justify="between" align="center">
              <Text>{description}</Text>
              <Box direction="row">
                <Text alignSelf="center" size="small" weight="bold">
                  Yearly subscriptions
                </Text>
                <Box margin={{ left: "small" }} background="brand" round pad="small">
                  <Text weight="bold" size="xsmall">
                    Save 20%
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <PricingTable
        processSuccess={processSuccess}
        monthlyActive={monthlyActive}
        currentPlanId={data.current_plan_id}
      />
    </Box>
  );

  const successContent = (
    <Box>
      {showConfetti && (
        <Layer full plain onClick={() => setShowConfetti(false)}>
          <Confetti
            numberOfPieces={1000}
            gravity={0.15}
            recycle={false}
            onConfettiComplete={() => setShowConfetti(false)}
          />
        </Layer>
      )}
      <Box direction="row" justify="between" margin={{ bottom: "medium" }}>
        <Box>
          <Heading margin={{ bottom: "xsmall", top: "none" }} level={2}>
            Upgrade complete
          </Heading>
        </Box>
        <Box direction="row">
          <Box margin={{ left: "medium" }}>
            <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => togglePopup(false)} />
          </Box>
        </Box>
      </Box>
      <Paragraph fill>You have successfully upgraded to the {successPlan} plan!</Paragraph>
      <Box basis="1/2" width="50%" margin={{ horizontal: "auto", top: "large" }}>
        <Button primary label="Continue" onClick={() => togglePopup(false)} />
      </Box>
    </Box>
  );
  return (
    <Box
      overflow={{ vertical: "auto" }}
      pad="large"
      width="xlarge"
      background="white"
      round="small"
      fill={size === "small" || size === "xsmall"}
      height={{ min: "auto" }}
    >
      {successActive ? successContent : innerContent}
    </Box>
  );
};

export default UpgradePopup;
