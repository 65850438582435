import React, { useState, useContext } from "react";
import { Box, Heading, ResponsiveContext } from "grommet";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "pro-regular-svg-icons";
import Container from "./Container";
import QRGeneratorForm from "./QRGeneratorForm";
import { usePlace } from "../api/queries";
import ActionButton from "./ActionButton";

const QRGenerator = () => {
  const [downloadUrl, setDownloadUrl] = useState(null);

  const { place } = useParams();
  const size = useContext(ResponsiveContext);

  const { data } = usePlace(place);

  const isSmall = size === "small" || size === "xsmall";
  const updateDownloadUrl = (url) => {
    setDownloadUrl(url);
  };

  const customLogoAllowed = data.permissions.custom_qr_code;

  return (
    <Container>
      <Box flex direction="row" justify="between">
        <Box basis="3/4">
          <Heading level={2} margin={{ bottom: "medium", top: "none" }} size="medium">
            QR code generator
          </Heading>
          <Heading level={3} margin={{ bottom: "medium", top: "none" }} size={isSmall ? "medium" : "20px"}>
            Download this QR code for customers to scan to access your digital menu
          </Heading>
        </Box>
        <Box basis={size === "small" || size === "xsmall" ? "auto" : "1/4"}>
          <ActionButton
            download="qrcode.png"
            primary
            type="button"
            label="Download"
            icon={<FontAwesomeIcon icon={faDownload} />}
            href={`data:image/png;base64,${downloadUrl}`}
          />
        </Box>
      </Box>
      <QRGeneratorForm customLogoAllowed={customLogoAllowed} updateDownloadUrl={updateDownloadUrl} />
    </Container>
  );
};

export default QRGenerator;
