import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import forEach from "lodash/forEach";
import { useParams } from "react-router-dom";
import Loader from "./Loader";
import { usePlace } from "../api/queries";
import { updatePlaceMenuOrder } from "../api/mutations";
import { trackEventSegment } from "../utils/segmentUtils";
import MenuListItem from "./MenuListItem";
import { useDraggableInPortal } from "../utils/hooks";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  forEach(result, (item, index) => {
    item.order = index + 1;
  });

  return result;
};

const MenuReorder = () => {
  const { place } = useParams();
  const renderDraggable = useDraggableInPortal();
  const [loaded, setLoaded] = useState(false);

  const cache = useQueryClient();

  const [menus, setMenus] = useState([]);

  const onMutate = (data) => {
    const updatedMenus = data.values.menus;
    cache.cancelQueries(["placeDetail", { place }]);
    const previousPlace = cache.getQueryData(["placeDetail", { place }]);
    const newPlace = { ...previousPlace };
    newPlace.menus = updatedMenus;
    cache.setQueryData(["placeDetail", { place }], newPlace);
    return () => cache.setQueryData(["placeDetail", { place }], previousPlace);
  };

  const { mutate } = useMutation(updatePlaceMenuOrder, { onMutate });

  const { data } = usePlace(place);

  useEffect(() => {
    if (data && loaded === false) {
      const { menus } = data;
      setMenus(menus);
    } else if (data && loaded === true) {
      setMenus(data.menus);
    }
    if (data) {
      setLoaded(true);
    }
  }, [data]);

  const onDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }
    const items = reorder(menus, result.source.index, result.destination.index);
    setMenus(items);
    // var destDish = find(categories, ['order', result.destination.index + 1])
    setLoaded(false);
    mutate(
      {
        placeId: place,
        values: {
          menus,
        },
      },
      {
        onSuccess: () => {
          trackEventSegment("Reordered menus", {
            placeId: place,
          });
        },
        onSettled: () => {
          cache.invalidateQueries(["placeDetail", { place }]);
        },
        onError: (error, menus, rollback) => {
          rollback();
        },
      }
    );
  };

  const list = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {menus.map((item, index) => (
                <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
                  {renderDraggable((provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <MenuListItem menu={item} />
                    </div>
                  ))}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };
  return <>{loaded ? <>{menus.length > 0 && list()}</> : <Loader />}</>;
};

export default MenuReorder;
