import { useState, useEffect } from "react";
import { Formik, FieldArray, Form } from "formik";
import forEach from "lodash/forEach";
import * as Yup from "yup";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import find from "lodash/find";
import { brandRed } from "../constants/colours";
import Button from "./Button";
import InputDiv from "./InputDiv";
import Loader from "./Loader";
import { createMenu, updateMenu } from "../api/mutations";
import Availability from "./Availability";
import { trackEventSegment } from "../utils/segmentUtils";
import { handleUnknown, handle500 } from "../utils/errorHandles";
import ReactSwal from "../utils/swalUtils";

const SubHeading = styled.h3`
  font-size: 1.2em;
  margin-bottom: 2em;
  font-weight: 500;
  margin-top: 0;
`;

const FormWrap = styled.div`
  border: 2px ${brandRed} solid;
  border-radius: 10px;
  padding: 1.5em;

  ${up("sm")} {
    padding: 1.75em;
  }

  ${up("md")} {
    padding: 2em;
  }

  ${up("lg")} {
    padding: 2.25em;
  }

  ${up("xxl")} {
    padding: 2.5em;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MenuForm = ({ place, addNewMenu, menu, numOfMenus }) => {
  const [loaded, setLoaded] = useState(false);

  const defaultAvailabilities = [
    {
      day: "EVERY_DAY",
      startTime: "7:00",
      endTime: "22:00",
    },
  ];

  const parseAvailabilityData = (menu) => {
    const availabilites = [];
    menu.menu_availabilities.map((item) => {
      availabilites.push({
        day: item.days,
        startTime: item.start_time,
        endTime: item.end_time,
      });
    });
    return availabilites;
  };

  const [formData, setFormData] = useState({
    name: null,
    menuAvailabilities: [],
  });

  const djTojsDict = {
    name: "name",
    menu_availabilities: "menuAvailabilities",
  };

  useEffect(() => {
    setFormData({
      name: menu?.name,
      menuAvailabilities: menu ? parseAvailabilityData(menu) : defaultAvailabilities,
    });
    setLoaded(true);
  }, [menu]);

  const cache = useQueryClient();

  const onMutate = (updatedMenuData) => {
    const updatedMenu = updatedMenuData.values;
    cache.cancelQueries(["placeDetail", { place }]);

    const previousPlace = cache.getQueryData(["placeDetail", { place }]);
    const newPlace = { ...previousPlace };

    const menu = find(newPlace.menus, ["uuid", updatedMenuData.menuId]);

    menu.name = updatedMenu.name;
    menu.menu_availabilities = updatedMenu.menu_availabilities;
    cache.setQueryData(["placeDetail", { place }], newPlace);
    return () => cache.setQueryData(["placeDetail", { place }], previousPlace);
  };

  const { mutate: mutateUpdate, isLoading: isUpdating } = useMutation(updateMenu, { onMutate });
  const { mutate: mutateCreate, isLoading: isCreating } = useMutation(createMenu);

  const schema = Yup.object({
    name: Yup.string().required("This field is required."),
  });

  const menuCreate = async (formattedValues, bag) => {
    await mutateCreate(
      {
        values: formattedValues,
      },
      {
        onError: (error) => {
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(djTojsDict[error.field], error.message);
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
        },
        onSuccess: (res) => {
          const newMenu = res.data;
          const previousPlace = cache.getQueryData(["placeDetail", { place }]);
          const newPlace = { ...previousPlace };
          newPlace.menus = [...newPlace.menus, newMenu];
          cache.setQueryData(["placeDetail", { place }], newPlace);
          ReactSwal.close();
          addNewMenu(menu);
          trackEventSegment("Created Menu", {
            menuName: formattedValues.name,
            uuid: res.data.uuid,
          });
        },
      }
    );
    bag.setSubmitting(false);
  };

  const menuUpdate = async (formattedValues, bag) => {
    await mutateUpdate(
      {
        values: formattedValues,
        menuId: menu.uuid,
      },
      {
        onError: (error, newMenu, rollback) => {
          rollback();
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(djTojsDict[error.field], error.message);
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
        },
        onSettled: () => {
          cache.invalidateQueries(["placeDetail", { place }]);
        },
        onSuccess: (data) => {
          const changedMenu = data.data;
          trackEventSegment("Updated Menu", {
            menuName: formattedValues.name,
            uuid: data.data.uuid,
          });
          ReactSwal.close();
        },
      }
    );
    bag.setSubmitting(false);
  };

  const onSubmit = (values, bag) => {
    const formattedAvailabilities = [];
    forEach(values.menuAvailabilities, (value, key) => {
      formattedAvailabilities.push({
        days: value.day,
        end_time: `${value.endTime}:00`,
        start_time: `${value.startTime}:00`,
      });
    });
    const formattedValues = {
      name: values.name,
      menu_availabilities: formattedAvailabilities,
      place,
      order: numOfMenus + 1,
    };
    menu ? menuUpdate(formattedValues, bag) : menuCreate(formattedValues, bag);
  };

  if (!loaded) {
    return <Loader />;
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={formData} enableReinitialize validationSchema={schema}>
      {(formik) => (
        <FormWrap>
          {isUpdating || isCreating ? (
            <Loader />
          ) : (
            <>
              <Top>
                <SubHeading>{menu ? "Edit" : "Add new"} menu</SubHeading>
                <FontAwesomeIcon
                  icon={faWindowClose}
                  size="lg"
                  onClick={() => {
                    ReactSwal.close();
                  }}
                />
              </Top>
              <Form>
                <InputDiv
                  hasLabel
                  text="Menu name"
                  placeholder="For example breakfast"
                  name="name"
                  hasBackground={false}
                  hasBorder
                  isRounded
                />
                <FieldArray
                  name="menuAvailabilities"
                  render={(arrayHelpers) => (
                    <Availability
                      arrayHelpers={arrayHelpers}
                      label="Menu availability hours (optional)"
                      helpText="This information is used to determine which menu is displayed based on the time of day a customer is using your visual menu."
                    />
                  )}
                />
                <Button
                  submitting={formik.isSubmitting}
                  type="submit"
                  text={menu ? "Update menu" : "Create menu"}
                  hasMargin={false}
                />
              </Form>
            </>
          )}
        </FormWrap>
      )}
    </Formik>
  );
};

MenuForm.propTypes = {
  addNewMenu: PropTypes.func.isRequired,
  numOfMenus: PropTypes.number.isRequired,
  menu: PropTypes.object,
  place: PropTypes.string.isRequired,
};

export default MenuForm;
