const getFileObjs = async (values) => {
  return new Promise((res, rej) => {
    if (values.image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        return res(reader.result);
      };
      reader.readAsDataURL(values.image);
    }
  });
};

const getFileObj = async (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      return res(reader.result);
    };
    reader.onerror = (readerError) => {
      return rej(readerError);
    };
    reader.readAsDataURL(file);
  });
};

export { getFileObj, getFileObjs };
