import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { useMutation } from "react-query";
import forEach from "lodash/forEach";
import { useParams } from "react-router-dom";
import AuthBase from "./AuthBase";
import Error from "./Error";
import InputDiv from "./InputDiv";
import Button from "./Button";
import Loader from "./Loader";
import { forgotPasswordConfirm } from "../api/mutations";
import { trackEventSegment } from "../utils/segmentUtils";
import ReactSwal from "../utils/swalUtils";

const ForgotPasswordConfirm = () => {
  const navigate = useNavigate();

  const initialData = {
    new_password1: "",
    new_password2: "",
  };

  const { uid, token } = useParams();

  const [linkSent, setLinkSent] = useState(false);

  const { mutate } = useMutation(forgotPasswordConfirm);

  const schema = Yup.object({
    new_password1: Yup.string().required("This field is required."),
    new_password2: Yup.string()
      .required("This field is required.")
      .oneOf([Yup.ref("new_password1"), null], "Passwords must match"),
  });

  const onSubmit = async (values, bag) => {
    await mutate(
      { values: { uid, token, ...values } },
      {
        onSuccess: () => {
          trackEventSegment("Password reset");
          setLinkSent(true);
        },
        onError: (error, variables) => {
          const { data } = error.response;
          switch (error.response.status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  if (error.field === "token" || error.field === "uid") {
                    bag.setStatus(error.message);
                  } else {
                    bag.setFieldError(error.field, error.message);
                  }
                });
              }
              break;
            case 500:
              ReactSwal.fire({
                title: "Oops!",
                text: "It's not your fault! Our server has encountered an internal error. An adminstrator has been notified. Try refreshing your page in the interim.",
                width: "35rem",
              });
              break;
            default:
              ReactSwal.fire({
                title: "Oops!",
                text: "An unknown error has occured. Try refreshing your page in the interim.",
                width: "35rem",
              });
          }
        },
      }
    );
  };

  return (
    <AuthBase header="Reset forgotten password" subheader="Create your new password below">
      {linkSent ? (
        <>
          <p style={{ marginBottom: "2em" }}>Your password has been successfully reset.</p>
          <Button width="50%" text="Login now" hasMargin onClick={() => navigate("/login")} />
        </>
      ) : (
        <Formik initialValues={initialData} onSubmit={onSubmit} validationSchema={schema}>
          {(formik) =>
            formik.isSubmitting ? (
              <Loader type="beat" size={20} />
            ) : (
              <Form>
                <Error>{formik.status}</Error>
                <div style={{ marginBottom: "1em" }} />
                <InputDiv
                  text="New Password"
                  placeholder="Enter your desired new password"
                  name="new_password1"
                  type="password"
                  hasLabel
                />
                <InputDiv
                  text="Confirm New Password"
                  placeholder="Confirm your desired new password"
                  name="new_password2"
                  type="password"
                  hasLabel
                />
                <Button type="submit" text="Reset password" />
              </Form>
            )
          }
        </Formik>
      )}
    </AuthBase>
  );
};

export default ForgotPasswordConfirm;
