import { useState, useEffect, useContext } from "react";
import { QueryClientProvider, useMutation, useQueryClient } from "react-query";
import find from "lodash/find";
import forEach from "lodash/forEach";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, ResponsiveContext, Select, Text } from "grommet";
import { ThemeProvider } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "pro-solid-svg-icons";
import AddButton from "./AddButton";
import { usePlace } from "../api/queries";
import { updateMenuOrder } from "../api/mutations";
import Loader from "./Loader";
import { trackEventSegment } from "../utils/segmentUtils";
import SectionItem from "./SectionItem";
import MenuContentEditorControls from "./MenuContentEditorControls";
import MenuForm from "./MenuForm";
import breakpoints from "../constants/breakpoints";
import ReactSwal from "../utils/swalUtils";
import ActionButton from "./ActionButton";
import SectionFormPopup from "./SectionFormPopup";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  forEach(result, (item, index) => {
    item.order = index + 1;
  });

  return result;
};

const MenuContentEditor = () => {
  const [loaded, setLoaded] = useState(false);

  const isLargerScreen = useMediaQuery({
    query: "(max-width: 850px)",
  });

  const size = useContext(ResponsiveContext);

  const { place } = useParams();

  const { data, isFetching } = usePlace(place);

  const { mutate } = useMutation(updateMenuOrder);

  const [activeMenu, setActiveMenu] = useState("");

  const cache = useQueryClient();

  const [menus, setMenus] = useState([]);

  const updateMenus = (newMenus) => {
    setMenus(newMenus);
  };

  const isSmallOrMed = size === "small" || size === "xsmall" || size === "medium";

  const addMenu = (menu) => {
    updateMenus((menus) => [...menus, menu]);
  };

  const addFormContent = (
    <ThemeProvider theme={breakpoints}>
      <QueryClientProvider client={cache}>
        <MenuForm place={place} numOfMenus={data.menus.length} addNewMenu={addMenu} />
      </QueryClientProvider>
    </ThemeProvider>
  );

  const menuAdd = () => {
    return ReactSwal.fire({
      html: addFormContent,
      width: isLargerScreen ? "90%" : "50%",
      padding: 0,
      showConfirmButton: false,
      customClass: {
        content: "swal-additem",
      },
    });
  };

  useEffect(() => {
    if (data && loaded === false) {
      setMenus(data.menus);
      setActiveMenu(data.menus[0]);
      setLoaded(true);
    } else if (data && loaded === true && activeMenu) {
      // setMenus(data.menus);
      // const newActiveMenu = find(data.menus, ["uuid", activeMenu.uuid]);
      // setActiveMenu(newActiveMenu);
    }
  }, [data, menus, isFetching]);

  // useEffect(() => {
  //   if (activeMenu) {
  //     setSections(activeMenu.menu_categories);
  //     setLoaded(true);
  //   }
  // }, [activeMenu]);

  const onChange = ({ value, option }) => {
    const menu = find(menus, ["uuid", value]);
    setActiveMenu(menu);
  };

  const onDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }
    const items = reorder(activeMenu.menu_categories, result.source.index, result.destination.index);
    setLoaded(false);
    mutate(
      {
        menuId: activeMenu.uuid,
        values: {
          menu_categories: items,
        },
      },
      {
        onSuccess: () => {
          trackEventSegment("Reordered sections", {
            subCategoryName: activeMenu.name,
            subCategoryUuid: activeMenu.uuid,
          });
        },
        onSettled: () => {
          cache.invalidateQueries(["placeDetail", { place }]);
        },
      },
    );
  };

  const sectionList = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list" isDropDisabled={false}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Box gap="medium">
                {activeMenu.menu_categories.map((item, index) => (
                  <Draggable isDragDisabled={false} key={item.uuid} draggableId={item.uuid} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <span {...provided.dragHandleProps} />
                        <SectionItem
                          numOfSections={activeMenu.menu_categories.length}
                          dragHandle={provided.dragHandleProps}
                          sectionId={item.uuid}
                          menuId={activeMenu.uuid}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </Box>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };
  return (
    <>
      {data ? (
        <>
          {menus.length > 0 && activeMenu ? (
            <>
              <Box
                height={{ min: "auto" }}
                margin={{ vertical: "medium" }}
                direction={isSmallOrMed ? "column" : "row"}
                justify="between"
                gap={isSmallOrMed ? "small" : "medium"}
              >
                <Box basis={isSmallOrMed ? "full" : "1/3"}>
                  <Box>
                    <Select
                      options={menus}
                      plain
                      onChange={onChange}
                      size="large"
                      value={activeMenu.uuid}
                      labelKey="name"
                      valueKey={{ key: "uuid", reduce: true }}
                    />
                  </Box>
                </Box>
                <MenuContentEditorControls
                  updateMenus={updateMenus}
                  activeMenu={activeMenu}
                  menus={menus}
                  numOfMenus={data.menus.length}
                />
              </Box>
              <Box margin={{ vertical: "medium" }}>
                {activeMenu.menu_categories.length > 0 ? sectionList() : <p> No menu sections yet! </p>}
              </Box>
              <SectionFormPopup
                menuId={activeMenu.uuid}
                numOfSections={activeMenu.menu_categories.length}
                element={<AddButton>Add menu section</AddButton>}
              />
            </>
          ) : (
            <Box justify="between" direction="row">
              <Text>There are no menus yet.</Text>
              <ActionButton
                icon={<FontAwesomeIcon style={{ verticalAlign: "sub" }} icon={faPlus} />}
                label="Create menu"
                primary
                onClick={() => menuAdd()}
              />
            </Box>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default MenuContentEditor;
