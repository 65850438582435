import { Box, Button, Layer } from "grommet";
import React, { useState, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { PropTypes } from "prop-types";
import { useParams } from "react-router-dom";
import findIndex from "lodash/findIndex";
import MenuItemSelect from "./MenuItemSelect";
import { addExistingDishToSection } from "../api/mutations";
import Loader from "./Loader";
import { trackEventSegment } from "../utils/segmentUtils";
import { handleUnknown, handle500 } from "../utils/errorHandles";
import Toast from "./Toast";

const MenuItemExisting = ({ sectionId, menuId, numOfDishes, closePopup }) => {
  const [selectedDish, setSelectedDish] = useState(null);
  const [loading, setLoading] = useState(false);

  const cache = useQueryClient();
  const { place } = useParams();

  const updateSelected = (dish) => {
    setSelectedDish(dish);
  };

  const { mutate } = useMutation(addExistingDishToSection);

  const addDish = async () => {
    setLoading(true);
    await mutate(
      {
        values: {
          dish: selectedDish.uuid,
          dish_order: numOfDishes + 1,
          menu_category: sectionId,
        },
      },
      {
        onSuccess: (resData) => {
          const addedDish = resData.data;
          const menuCatDish = {
            uuid: addedDish.menu_category,
            dish_order: addedDish.dish_order,
            dish: selectedDish,
          };
          const previousPlace = cache.getQueryData(["placeDetail", { place }]);
          const newPlace = { ...previousPlace };
          const menuIndex = findIndex(newPlace.menus, ["uuid", menuId]);
          const sectIndex = findIndex(newPlace.menus[menuIndex].menu_categories, ["uuid", sectionId]);
          newPlace.menus[menuIndex].menu_categories[sectIndex].dishes = [
            ...newPlace.menus[menuIndex].menu_categories[sectIndex].dishes,
            menuCatDish,
          ];
          cache.setQueryData(["placeDetail", { place }], newPlace);
          trackEventSegment("Added Existing Dish", {
            name: selectedDish.name,
            uuid: selectedDish.uuid,
          });
          setLoading(false);
          closePopup();
          Toast.fire({
            title: "Item added",
            icon: "success",
          });
        },
        onSettled: () => {
          cache.invalidateQueries(["placeDetail", { place }]);
        },
        onError: (error) => {
          const { status } = error.response;
          switch (status) {
            case 400:
              handleUnknown();
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
        },
      }
    );
  };

  const container = useRef();

  if (loading) {
    return <Loader />;
  }

  return (
    <Box overflow="auto" ref={container}>
      <MenuItemSelect selectedDish={selectedDish} selectItem={updateSelected} />
      <Layer margin={{ bottom: "large" }} plain modal={false} target={container.current} position="bottom">
        <Button
          disabled={selectedDish === null}
          size="large"
          fill
          primary
          label="Add selected item"
          onClick={() => addDish()}
        />
      </Layer>
    </Box>
  );
};

MenuItemExisting.propTypes = {
  sectionId: PropTypes.string.isRequired,
  menuId: PropTypes.string.isRequired,
  numOfDishes: PropTypes.number.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default MenuItemExisting;
