import { Anchor, Header } from "grommet";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "pro-regular-svg-icons";
import styled from "styled-components";

const MobileHeader = styled(Header)`
  position: fixed;
  z-index: 1;
`;

const DashboardHeader = (props) => {
  const { toggleSidebar } = props;

  return (
    <MobileHeader width="full" background="brand" pad="medium">
      <Anchor onClick={() => toggleSidebar(true)}>
        <FontAwesomeIcon size="xl" style={{ fontSize: "1.75em" }} icon={faBars} />
      </Anchor>
    </MobileHeader>
  );
};

export default DashboardHeader;
