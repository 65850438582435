import { useState, useEffect, useContext } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import forEach from "lodash/forEach";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import shurley from "shurley";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Box, Heading, Button, ResponsiveContext } from "grommet";
import InputDiv from "./InputDiv";
import AddressField from "./AddressField";
import FieldGrid from "./FieldGrid";
import FieldGridItem from "./FieldGridItem";
import Loader from "./Loader";
import { usePlace } from "../api/queries";
import { updatePlaceBusinessSettings } from "../api/mutations";
import { identifyGroupSegment, trackEventSegment } from "../utils/segmentUtils";
import PhoneInputField from "./PhoneInputField";
import Toast from "./Toast";

function BusinessProfile() {
  const { place } = useParams();

  const { mutate: placeUpdate, isLoading } = useMutation(updatePlaceBusinessSettings);
  const { status, data } = usePlace(place);
  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";

  const [loaded, setLoaded] = useState(false);

  const cache = useQueryClient();

  const [formData, setFormData] = useState({
    businessName: "",
    address: "",
    addressPoint: "",
    businessEmailAddress: "",
    correspondenceEmail: "",
    businessWebsite: "",
    businessPhoneNumber: "",
  });

  useEffect(() => {
    if (loaded === false && data) {
      const loadedData = {
        businessName: data.name,
        address: data.address,
        addressPoint: {
          lat: data.point.latitude,
          lng: data.point.longitude,
        },
        businessEmailAddress: data.email,
        correspondenceEmail: data.owner_email,
        businessWebsite: data.website,
        businessPhoneNumber: data.phone_number,
      };
      setFormData(loadedData);
      setLoaded(true);
    }
  }, [data, loaded]);

  useEffect(() => {
    if (status === "idle") {
      setLoaded(true);
    }
  }, [status]);

  const schema = Yup.object({
    businessName: Yup.string().required("This field is required."),
    address: Yup.string().required("This field is required."),
    addressPoint: Yup.string()
      .transform((_, input) =>
        input != null && input.toString ? input.toString() : "Please select an address using the google maps field."
      )
      .required("Please select an address using the google maps field."),
    businessEmailAddress: Yup.string().required("This field is required.").email("Please enter a valid email."),
    correspondenceEmail: Yup.string().required("This field is required.").email("Please enter a valid email."),
    businessPhoneNumber: Yup.string()
      .required("This field is required.")
      .test(
        "is-valid-number",
        // eslint-disable-next-line no-template-curly-in-string
        "${value} is not a valid phone number.",
        async (value) => {
          if (value) {
            return isValidPhoneNumber(value);
          }
          return false;
        }
      ),
  });

  const djTojsDict = {
    name: "business_name",
    owner_email: "correspondenceEmail",
    email: "businessEmailAddress",
    website: "businessWebsite",
    phone_number: "businessPhoneNumber",
  };

  const onSubmit = async (values, bag) => {
    const reqBody = {
      name: values.businessName,
      address: values.address,
      phone_number: values.businessPhoneNumber,
      owner_email: values.correspondenceEmail,
      email: values.businessEmailAddress,
      website: values.businessWebsite ? shurley.parse(values.businessWebsite) : null,
      point: {
        latitude: values.addressPoint.lat,
        longitude: values.addressPoint.lng,
      },
    };
    await placeUpdate(
      { values: reqBody, placeId: place },
      {
        onSuccess: (data) => {
          // cache.setQueryData(["placeDetail", place], data.data);
          identifyGroupSegment(data.data.uuid, {
            name: values.businessName,
            phone: values.businessPhoneNumber,
            email: values.correspondenceEmail,
            website: values.businessWebsite,
          });
          trackEventSegment("Updated place");
          Toast.fire({
            title: "Business profile updated",
            icon: "success",
          });
          cache.refetchQueries(["placeDetail", { place }]);
          setLoaded(true);
        },
        onError: (error, variables) => {
          const { data } = error.response;
          switch (error.response.status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(djTojsDict[error.field], error.message);
                });
              }
              break;
            case 500:
              Swal.fire({
                title: "Oops!",
                text:
                  "It's not your fault! Our server has encountered an internal error. An adminstrator has been notified. Try refreshing your page in the interim.",
                width: "35rem",
              });
              break;
            default:
              Swal.fire({
                title: "Oops!",
                text: "An unknown error has occured. Try refreshing your page in the interim.",
                width: "35rem",
              });
          }
        },
      }
    );
    bag.setSubmitting(false);
  };

  if (loaded === false) {
    return <Loader />;
  }

  return (
    <>
      <Formik validationSchema={schema} initialValues={formData} onSubmit={onSubmit}>
        {(formik) =>
          formik.isSubmitting || isLoading ? (
            <Loader />
          ) : (
            <Form>
              <Box flex direction="row" justify="between">
                <Box basis="3/4">
                  <Heading margin={{ bottom: "medium", top: "none" }} size="small">
                    Business settings
                  </Heading>
                </Box>
                <Box basis="1/4">
                  <Button primary type="submit" label={isSmall ? "Save" : "Save Changes"} />
                </Box>
              </Box>
              <InputDiv text="Business name" placeholder="Add business name" name="businessName" hasLabel />
              <AddressField />
              <FieldGrid>
                <FieldGridItem>
                  <InputDiv
                    text="Business email address"
                    helpText="Publically viewable to customers"
                    placeholder="Enter email address"
                    name="businessEmailAddress"
                    hasLabel
                  />
                </FieldGridItem>
                <FieldGridItem>
                  <InputDiv
                    text="Correspondence email address"
                    helpText="Eatsee correspondence will be sent here"
                    placeholder="Enter email address"
                    name="correspondenceEmail"
                    hasLabel
                  />
                </FieldGridItem>
                <FieldGridItem>
                  <InputDiv text="Business website" placeholder="Enter website" name="businessWebsite" hasLabel />
                </FieldGridItem>
                <FieldGridItem>
                  <PhoneInputField
                    text="Business phone number"
                    placeholder="Enter phone number"
                    name="businessPhoneNumber"
                    hasLabel
                  />
                </FieldGridItem>
              </FieldGrid>
            </Form>
          )
        }
      </Formik>
    </>
  );
}

export default BusinessProfile;
