import { PropTypes } from "prop-types";
import InputLabel from "./InputLabel";
import InputField from "./InputField";
import InputError from "./InputError";
import FieldWrap from "./FieldWrap";
import HelpText from "./HelpText";

function InputDiv(props) {
  let placeholder;
  if (props.placeholder) {
    placeholder = props.placeholder;
  } else {
    placeholder = props.text;
  }

  const content = (
    <>
      {props.hasLabel && <InputLabel for={props.name} text={props.text} />}
      {props.helpText && <HelpText>{props.helpText}</HelpText>}
      <InputField
        hasBackground={props.hasBackground}
        hasBorder={props.hasBorder}
        inputPrefix={props.inputPrefix}
        isRounded={props.isRounded}
        type={props.type}
        text={props.text}
        placeholder={placeholder}
        name={props.name}
        ariaLabel={!props.hasLabel}
        onClick={props.onClick}
      />
      <InputError name={props.name} />
    </>
  );
  if (props.hasFieldWrap) {
    return <FieldWrap>{content}</FieldWrap>;
  }
  return content;
}

InputDiv.defaultProps = {
  type: "text",
  hasLabel: false,
  hasBackground: true,
  isRounded: false,
  hasBorder: false,
  helpText: null,
  placeholder: null,
  onClick: null,
  inputPrefix: null,
  hasFieldWrap: true,
};

InputDiv.propTypes = {
  text: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  hasLabel: PropTypes.bool,
  hasBackground: PropTypes.bool,
  hasBorder: PropTypes.bool,
  hasFieldWrap: PropTypes.bool,
  isRounded: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  inputPrefix: PropTypes.string,
  onClick: PropTypes.func,
};

export default InputDiv;
