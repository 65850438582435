import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Button, Text } from "grommet";
import { faDownload } from "pro-regular-svg-icons";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useInvoices } from "../api/queries";
import Loader from "./Loader";
import ENV from "../constants/envConstants";

const BillingInvoices = () => {
  const { place } = useParams();

  const { data } = useInvoices(place);

  if (!data) {
    return <Loader />;
  }

  return (
    <Card>
      {data.invoices.length > 0 ? (
        data.invoices.map((invoice, index) => {
          dayjs.extend(advancedFormat);
          const dateIssued = dayjs.unix(invoice.date);
          return (
            <Box direction="row" pad="medium" border={index !== data.invoices.length - 1 ? "bottom" : false}>
              <Box basis="1/3">{dateIssued.format("MMMM Do YYYY")}</Box>
              <Box basis="1/3" pad={{ left: "large" }}>
                {invoice.status}
              </Box>
              <Box basis="1/3" align="end">
                <Button
                  plain
                  reverse
                  icon={<FontAwesomeIcon icon={faDownload} size="xl" />}
                  label="Download"
                  href={`${ENV.BACKEND_URL}/restaurants/billing/${place}/invoices/${invoice.id}`}
                />
              </Box>
            </Box>
          );
        })
      ) : (
        <Box pad="medium">
          <Text>No invoices</Text>
        </Box>
      )}
    </Card>
  );
};

export default BillingInvoices;
