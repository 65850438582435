import * as Yup from "yup";
import { Form, Formik, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { Box, Select, ResponsiveContext, Button } from "grommet";
import { ChromePicker } from "react-color";
import { useQueryClient } from "react-query";
import OutsideClickHandler from "react-outside-click-handler";
import Loader from "./Loader";
import InputDiv from "./InputDiv";
import { useQRCode } from "../api/queries";
import QRCode from "./QRCode";
import InputLabel from "./InputLabel";
import SwitchInput from "./SwitchInput";
import UpdateLogoPopup from "./UpdateLogoPopup";

const AutoSubmitToken = () => {
  const { values, submitForm } = useFormikContext();

  useEffect(() => {
    submitForm();
  }, [values, submitForm]);

  return null;
};

const QRGeneratorForm = (props) => {
  const { place } = useParams();

  const schema = Yup.object({
    size: Yup.string().required("This field is required."),
  });

  const [updateLogoVisible, setUpdateLogoVisible] = useState(false);

  const closePopup = () => {
    setUpdateLogoVisible(false);
  };

  const size = useContext(ResponsiveContext);

  const isSmall = size === "small" || size === "xsmall";
  const cache = useQueryClient();
  const [pickerActive, setPickerActive] = useState(false);

  const sizeOptions = [
    { label: "Extra Small", value: 4.5 },
    { label: "Small", value: 5 },
    { label: "Regular", value: 5.5 },
    { label: "Large", value: 6 },
    { label: "Extra Large", value: 6.5 },
  ];

  const qrTypeOptions = [
    { label: "Forced sign in", value: "in-venue" },
    { label: "Guest registration only", value: "guest-reg-only" },
    { label: "No sign in", value: "no-sign-in" },
    { label: "Sign in optional", value: "optional-sign-in" },
  ];

  const [formData, setFormData] = useState({
    size: 6.5,
    qr_type: "in-venue",
    color: "#000000",
    use_venue_logo: false,
  });

  const onSubmit = (values, bag) => {
    setFormData(values);
  };

  useEffect(() => {
    cache.invalidateQueries("useQRCode");
  }, [formData]);

  const { data, isFetching } = useQRCode(place, formData);

  if (!isFetching) {
    props.updateDownloadUrl(data.img);
  }

  return (
    <Box>
      <Formik onSubmit={onSubmit} initialValues={formData} validationSchema={schema}>
        {(formik) => (
          <Form>
            <Box margin={{ bottom: "medium" }} width="medium">
              <InputLabel text="Logo size" />
              <Select
                options={sizeOptions}
                value={formik.values.size}
                labelKey="label"
                onChange={({ value: nextValue }) => formik.setFieldValue("size", nextValue)}
                name="size"
                valueKey={{ key: "value", reduce: true }}
              />
            </Box>
            <Box margin={{ bottom: "medium" }} width="medium">
              <InputLabel text="QR type" />
              <Select
                options={qrTypeOptions}
                value={formik.values.qr_type}
                labelKey="label"
                onChange={({ value: nextValue }) => formik.setFieldValue("qr_type", nextValue)}
                name="qr_type"
                valueKey={{ key: "value", reduce: true }}
              />
            </Box>
            <Box direction="row" basis={isSmall ? "full" : "1/2"} wrap={isSmall}>
              <Box direction="row" width="405px" justify="center" align="center">
                {isFetching ? <Loader /> : <QRCode img={data.img} />}
              </Box>
              <Box>
                <InputDiv name="color" text="Color" hasLabel onClick={() => setPickerActive(true)} />
                {pickerActive && (
                  <OutsideClickHandler onOutsideClick={() => setPickerActive(false)}>
                    <ChromePicker
                      onChange={(color) => formik.setFieldValue("color", color.hex)}
                      color={formik.values.color}
                    />
                  </OutsideClickHandler>
                )}
                <SwitchInput
                  defaultChecked={false}
                  toggleChange={(value) => formik.setFieldValue("use_venue_logo", !formik.values.use_venue_logo)}
                  label="Use custom venue logo"
                  helpText="Your logo will be used on your personalised QR code."
                  hasPerm={props.customLogoAllowed}
                />
                {props.customLogoAllowed && (
                  <>
                    <Button secondary label="Update logo" onClick={() => setUpdateLogoVisible(true)} />
                    {updateLogoVisible && <UpdateLogoPopup closePopup={closePopup} />}
                  </>
                )}
              </Box>
            </Box>
            <AutoSubmitToken />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default QRGeneratorForm;
