import React, { useContext, useState } from "react";
import { Box, Heading, ResponsiveContext, Text, Button } from "grommet";
import { useParams } from "react-router";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { QueryClientProvider, useMutation, useQueryClient } from "react-query";
import { isValidPhoneNumber } from "react-phone-number-input";
import forEach from "lodash/forEach";
import { ThemeProvider } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Container from "./Container";
import CompleteAccountStep from "./CompleteAccountStep";
import { usePlace } from "../api/queries";
import Loader from "./Loader";
import { getSetupScore, hasCoverPhoto, hasEmailAndPhone, hasLogo, hasMenu } from "../utils/setupScore";
import { updatePlaceEmailPhone, updatePlaceCoverPhoto, updatePlaceLogo } from "../api/mutations";
import PhoneInputField from "./PhoneInputField";
import InputDiv from "./InputDiv";
import { trackEventSegment } from "../utils/segmentUtils";
import Error from "./Error";
import coverExample from "../assets/images/cover-example.jpg";
import logoExample from "../assets/images/logo-example.png";
import FileUploadInput from "./FileUploadInput";
import { coverPhotoSchema, logoSchema } from "../utils/schemaUtils";
import { getFileObj } from "../utils/fileUtils";
import MenuForm from "./MenuForm";
import breakpoints from "../constants/breakpoints";
import ReactSwal from "../utils/swalUtils";
import ActionButton from "./ActionButton";

const sizeChart = {
  xsmall: "100%",
  small: "100%",
  medium: "50%",
  large: "25%",
  xlarge: "20%",
};

const EmailPhoneNumberForm = ({ updatePanel, place, size }) => {
  const cache = useQueryClient();
  const isSmallOrMed = size === "small" || size === "xsmall" || size === "medium";

  const [formData, setFormData] = useState({
    email: place.email,
    phone_number: place.phone_number,
  });
  const [loaded, setLoaded] = useState(true);

  const { mutate, isLoading } = useMutation(updatePlaceEmailPhone);

  const schema = Yup.object({
    email: Yup.string().required("This field is required.").email("Please enter a valid email."),
    phone_number: Yup.string()
      .required("This field is required.")
      .test(
        "is-valid-number",
        // eslint-disable-next-line no-template-curly-in-string
        "${value} is not a valid phone number.",
        (value) => isValidPhoneNumber(value)
      ),
  });

  const onSubmit = async (values, bag) => {
    setLoaded(false);
    const reqBody = {
      phone_number: values.phone_number,
      email: values.email,
    };
    await mutate(
      { values: reqBody, placeId: place.uuid },
      {
        onSuccess: (data) => {
          trackEventSegment("Updated email and phone");
          cache.refetchQueries(["placeDetail", { place: place.uuid }]);
          setLoaded(true);
          updatePanel(null);
        },
        onError: (error, variables) => {
          const { data } = error.response;
          switch (error.response.status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              }
              break;
            case 500:
              bag.setStatus(
                "It's not your fault! Our server has encountered an internal error. An adminstrator has been notified. Try refreshing your page in the interim."
              );
              break;
            default:
              bag.setStatus("An unknown error has occured. Try refreshing your page in the interim.");
          }
        },
      }
    );
    bag.setSubmitting(false);
    setLoaded(true);
  };

  return (
    <Box pad="xsmall">
      <Text margin={{ bottom: "xsmall" }}>
        Help customers contact your restaurant by providing a contact email address and phone number.
      </Text>
      <Box pad="small">
        <Formik validationSchema={schema} initialValues={formData} onSubmit={onSubmit}>
          {(formik) =>
            formik.isSubmitting || isLoading ? (
              <Loader />
            ) : (
              <Form>
                <Error style={{ marginBottom: "1em" }}>{formik.status}</Error>
                <Box direction="column">
                  <Box basis="1/2" width={isSmallOrMed ? "100%" : "50%"} direction="row">
                    <InputDiv text="Business email address" placeholder="Enter email address" name="email" hasLabel />
                  </Box>
                  <Box width={isSmallOrMed ? "100%" : "50%"}>
                    <PhoneInputField
                      text="Business phone number"
                      placeholder="Enter phone number"
                      name="phone_number"
                      hasLabel
                    />
                  </Box>
                  <Box width={sizeChart[size]}>
                    <Button type="submit" primary label="Save Changes" />
                  </Box>
                </Box>
              </Form>
            )
          }
        </Formik>
      </Box>
    </Box>
  );
};

const CoverPhotoForm = ({ updatePanel, size }) => {
  const { place } = useParams();
  const cache = useQueryClient();
  const { mutate, isLoading } = useMutation(updatePlaceCoverPhoto);
  const [coverPreview, setCoverPreview] = useState(null);

  const setCoverPrev = (url) => {
    setCoverPreview(url);
  };

  const [formData, setFormData] = useState({
    cover_image: undefined,
  });

  const schema = Yup.object({
    cover_image: coverPhotoSchema,
  });

  const onSubmit = async (values, bag) => {
    const reqBody = {
      cover_image: values.cover_image === "" ? null : await getFileObj(values.cover_image),
    };
    await mutate(
      {
        placeId: place,
        values: reqBody,
      },
      {
        onSuccess: (data) => {
          trackEventSegment("Place cover photo added");
          bag.setSubmitting(false);
          updatePanel(null);
          const previousPlace = cache.getQueryData(["placeDetail", { place }]);
          const newPlace = { ...previousPlace };
          newPlace.cover_image = data.data.cover_image;
          cache.setQueryData(["placeDetail", { place }], newPlace);
        },
        onSettled: () => {
          cache.invalidateQueries(["placeDetail", { place }]);
        },
        onError: (error, newSect, rollback) => {
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              }
              break;
            case 500:
              bag.setStatus(
                "It's not your fault! Our server has encountered an internal error. An adminstrator has been notified. Try refreshing your page in the interim."
              );
              break;
            default:
              bag.setStatus("An unknown error has occured. Try refreshing your page in the interim.");
          }
          bag.setSubmitting(false);
        },
      }
    );
  };

  return (
    <Box pad="xsmall">
      <Text margin={{ bottom: "xsmall" }}>
        Customise the look and feel of your menu with a beautiful image customers will recognise.
      </Text>
      <Box pad="small">
        <Formik initialValues={formData} validationSchema={schema} onSubmit={onSubmit}>
          {(formik) =>
            formik.isSubmitting || isLoading ? (
              <Loader />
            ) : (
              <Form>
                <FileUploadInput
                  preview={coverPreview}
                  exampleFile={coverExample}
                  setPreview={setCoverPrev}
                  text="Cover photo"
                  name="cover_image"
                  helpText="This image will be used as the background image and cover photo for your digital menu. Recommended image dimensions 1280x720px, to see an example of an ideal image"
                  handleFileInput={(fileName, fieldName) => formik.setFieldValue(fieldName, fileName)}
                  previewNewTab
                />
                <Box width={sizeChart[size]}>
                  <Button type="submit" primary label="Save Changes" />
                </Box>
              </Form>
            )
          }
        </Formik>
      </Box>
    </Box>
  );
};

const LogoForm = ({ updatePanel, size }) => {
  const { place } = useParams();
  const cache = useQueryClient();
  const { mutate, isLoading } = useMutation(updatePlaceLogo);
  const [logoPreview, setLogoPreview] = useState(null);

  const setLogoPrev = (url) => {
    setLogoPreview(url);
  };

  const [formData, setFormData] = useState({
    venue_logo: undefined,
  });

  const schema = Yup.object({
    venue_logo: logoSchema,
  });

  const onSubmit = async (values, bag) => {
    const reqBody = {
      venue_logo: values.venue_logo === "" ? null : await getFileObj(values.venue_logo),
    };
    await mutate(
      {
        placeId: place,
        values: reqBody,
      },
      {
        onSuccess: (data) => {
          trackEventSegment("Place logo added");
          bag.setSubmitting(false);
          updatePanel(null);
          const previousPlace = cache.getQueryData(["placeDetail", { place }]);
          const newPlace = { ...previousPlace };
          newPlace.venue_logo = data.data.venue_logo;
          cache.setQueryData(["placeDetail", { place }], newPlace);
        },
        onSettled: () => {
          cache.invalidateQueries(["placeDetail", { place }]);
        },
        onError: (error, newSect, rollback) => {
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              }
              break;
            case 500:
              bag.setStatus(
                "It's not your fault! Our server has encountered an internal error. An adminstrator has been notified. Try refreshing your page in the interim."
              );
              break;
            default:
              bag.setStatus("An unknown error has occured. Try refreshing your page in the interim.");
          }
          bag.setSubmitting(false);
        },
      }
    );
  };

  return (
    <Box pad="xsmall">
      <Text margin={{ bottom: "xsmall" }}>
        Customise the look and feel of your menu with a beautiful image customers will recognise.
      </Text>
      <Box pad="small">
        <Formik initialValues={formData} validationSchema={schema} onSubmit={onSubmit}>
          {(formik) =>
            formik.isSubmitting || isLoading ? (
              <Loader />
            ) : (
              <Form>
                <FileUploadInput
                  preview={logoPreview}
                  exampleFile={logoExample}
                  setPreview={setLogoPrev}
                  text="Logo"
                  name="venue_logo"
                  helpText="Your logo will be used on your dashboard as well as your personalised QR code. Your logo must be square. To see an example of an ideal logo"
                  handleFileInput={(fileName, fieldName) => formik.setFieldValue(fieldName, fileName)}
                  previewNewTab
                />
                <Box width={sizeChart[size]}>
                  <Button type="submit" primary label="Save Changes" />
                </Box>
              </Form>
            )
          }
        </Formik>
      </Box>
    </Box>
  );
};

const FirstMenuForm = ({ updatePanel, place, size }) => {
  const isLargerScreen = useMediaQuery({
    query: "(max-width: 850px)",
  });
  const cache = useQueryClient();
  const navigate = useNavigate();
  const isSmallOrMed = size === "small" || size === "xsmall" || size === "medium";

  const addFormContent = (
    <ThemeProvider theme={breakpoints}>
      <QueryClientProvider client={cache}>
        <MenuForm place={place.uuid} numOfMenus={place.menus.length} addNewMenu={null} />
      </QueryClientProvider>
    </ThemeProvider>
  );

  const menuAdd = () => {
    return ReactSwal.fire({
      html: addFormContent,
      width: isLargerScreen ? "90%" : "50%",
      padding: 0,
      showConfirmButton: false,
      customClass: {
        content: "swal-additem",
      },
    });
  };

  return (
    <Box pad="xsmall">
      <Text margin={{ bottom: "small" }}>Create your first digital menu or upload a PDF of your menu.</Text>
      <Box basis="1/2" width={isSmallOrMed ? "100%" : "50%"} pad="small" direction="row" gap="small">
        <Button
          icon={<FontAwesomeIcon style={{ verticalAlign: "sub" }} icon={faPlus} />}
          label="Create menu"
          primary
          onClick={() => menuAdd()}
        />
        <Button
          icon={<FontAwesomeIcon style={{ verticalAlign: "sub" }} icon={faPlus} />}
          label="Upload PDF Menu"
          primary
          onClick={() => navigate(`/${place.uuid}/menu-editor/pdf`)}
        />
      </Box>
    </Box>
  );
};

const CompleteAccount = () => {
  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";
  const { place } = useParams();
  const { status, data } = usePlace(place);

  if (!data) {
    return <Loader />;
  }

  const score = getSetupScore(data);

  const emailAndPhone = hasEmailAndPhone(data);
  const coverPhoto = hasCoverPhoto(data);
  const logo = hasLogo(data);
  const menu = hasMenu(data);

  const [activeAccordionPanel, setActiveAccordionPanel] = useState(null);

  const updateActivePanel = (panelIndex) => {
    setActiveAccordionPanel(panelIndex);
  };

  return (
    <Container>
      <Box>
        <Heading level={2} margin={{ bottom: "small", top: "none" }} size="medium">
          {score === 0 ? "Congratulations!" : "Complete account setup"}
        </Heading>
        <Heading level={3} size={isSmall ? "medium" : "20px"} margin={{ top: "none", bottom: "medium" }}>
          {score === 0 ? "Your account has been successfully setup" : `There are ${score} remaining steps to complete`}
        </Heading>
      </Box>
      <Box direction="row">
        <Box direction="column" width="full" gap="small">
          <CompleteAccountStep
            title="Add a business email and phone number"
            complete={emailAndPhone}
            content={<EmailPhoneNumberForm updatePanel={updateActivePanel} place={data} size={size} />}
            updatePanel={updateActivePanel}
            index={0}
            activeIndex={activeAccordionPanel}
          />
          <CompleteAccountStep
            title="Add a cover photo"
            complete={coverPhoto}
            content={<CoverPhotoForm updatePanel={updateActivePanel} size={size} />}
            updatePanel={updateActivePanel}
            index={1}
            activeIndex={activeAccordionPanel}
          />
          <CompleteAccountStep
            title="Add a logo"
            complete={logo}
            content={<LogoForm updatePanel={updateActivePanel} size={size} />}
            updatePanel={updateActivePanel}
            index={2}
            activeIndex={activeAccordionPanel}
          />
          <CompleteAccountStep
            title="Add or upload your first menu"
            complete={menu}
            content={<FirstMenuForm updatePanel={updateActivePanel} place={data} size={size} />}
            updatePanel={updateActivePanel}
            index={3}
            activeIndex={activeAccordionPanel}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default CompleteAccount;
