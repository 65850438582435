import { createGlobalStyle } from "styled-components";

import GorditaLightWoff from "./gordita-light.woff";
import GorditaLightWoff2 from "./gordita-light.woff2";

import GorditaRegularWoff from "./gordita-regular.woff";
import GorditaRegularWoff2 from "./gordita-regular.woff2";

import GorditaMediumWoff from "./gordita-medium.woff";
import GorditaMediumWoff2 from "./gordita-medium.woff2";

import GorditaBoldWoff from "./gordita-bold.woff";
import GorditaBoldWoff2 from "./gordita-bold.woff2";

import GorditaBlackWoff from "./gordita-black.woff";
import GorditaBlackWoff2 from "./gordita-black.woff2";

export default createGlobalStyle`
    @font-face {
        font-display: swap;
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 300;
        src: local('Gordita'), local('Gordita'),
        url(${GorditaLightWoff}) format('woff2'),
        url(${GorditaLightWoff2}) format('woff');
    }

    @font-face {
        font-display: swap;
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 400;
        src: local('Gordita'), local('Gordita'),
        url(${GorditaRegularWoff}) format('woff2'),
        url(${GorditaRegularWoff2}) format('woff');
    }

    @font-face {
        font-display: swap;
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 500;
        src: local('Gordita'), local('Gordita'),
        url(${GorditaMediumWoff}) format('woff2'),
        url(${GorditaMediumWoff2}) format('woff');
    }

    @font-face {
        font-display: swap;
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 700;
        src: local('Gordita'), local('Gordita'),
        url(${GorditaBoldWoff}) format('woff2'),
        url(${GorditaBoldWoff2}) format('woff');
    }

    @font-face {
        font-display: swap;
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 900;
        src: local('Gordita'), local('Gordita'),
        url(${GorditaBlackWoff}) format('woff2'),
        url(${GorditaBlackWoff2}) format('woff');
    }
`;
