import styled from "styled-components";
import { up } from "styled-breakpoints";

const FieldGridItem = styled.div`
  width: 100%;
  // margin-bottom: 1.2em;

  ${up("md")} {
    width: 45%;
  }

  ${up("lg")} {
    width: 45%;
  }
`;

export default FieldGridItem;
