import { Box, Heading } from "grommet";
import React from "react";
import BillingGeneral from "./BillingGeneral";
import BillingInvoices from "./BillingInvoices";

const Billing = () => {
  // I tried using the grommet table component here
  // It was janky and was doing werid stuff with padding
  // Hence have chosen simple Box layout. - Liam

  return (
    <>
      <Box>
        <Heading size="small">Billing</Heading>
      </Box>
      <Heading level={2} size="small">
        General
      </Heading>
      <BillingGeneral />
      <Heading level={2} size="small" margin={{ vertical: "medium" }}>
        Invoices
      </Heading>
      <BillingInvoices />
    </>
  );
};

export default Billing;
