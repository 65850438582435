import { useState, useEffect } from "react";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import PropTypes from "prop-types";
import "react-phone-number-input/style.css";
import { Field } from "formik";
import FieldWrap from "./FieldWrap";
import InputError from "./InputError";
import InputLabel from "./InputLabel";
import InputWrap from "./InputWrap";

const PhoneInputInner = (props) => {
  const {
    field: { name, value },
    form: { setFieldValue },
    onChange,
  } = props;

  const [defaultCountry, setDefaultCountry] = useState("AU");

  const onValueChange = (phoneNumber) => {
    setFieldValue(name, phoneNumber);

    if (onChange !== null) {
      onChange(phoneNumber);
    }
  };

  useEffect(() => {
    axios
      .get("https://freegeoip.app/json/")
      .then((res) => {
        setDefaultCountry(res.data.country_code);
      })
      .catch(() => {
        axios
          .get("https://freegeoip.app/json/")
          .then((res) => {
            setDefaultCountry(res.data.country_code);
          })
          .catch(() => {});
      });
  }, []);

  return (
    <PhoneInput
      placeholder="Enter phone number"
      name={name}
      value={value}
      style={{ width: "100%" }}
      onChange={onValueChange}
      defaultCountry={defaultCountry}
    />
  );
};

PhoneInputInner.propTypes = {
  className: PropTypes.string,
  form: PropTypes.any.isRequired,
  field: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  country: PropTypes.string,
  disabled: PropTypes.bool,
};

PhoneInputInner.defaultProps = {
  className: "",
  label: "",
  onChange: null,
  country: "AU",
  disabled: false,
};

const PhoneInputField = ({ text, placeholder, name, ...restProps }) => {
  return (
    <FieldWrap>
      <InputLabel for={name} text={text} />
      <InputWrap hasBackground>
        <Field width="100%" text={text} placeholder={placeholder} name={name} component={PhoneInputInner} />
      </InputWrap>
      <InputError name={name} />
    </FieldWrap>
  );
};

PhoneInputField.defaultProps = {
  type: "text",
  hasLabel: false,
  hasBackground: true,
  isRounded: false,
  hasBorder: false,
};

PhoneInputField.propTypes = {
  text: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  hasLabel: PropTypes.bool.isRequired,
  hasBackground: PropTypes.bool.isRequired,
  hasBorder: PropTypes.bool.isRequired,
  isRounded: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default PhoneInputField;
