import axios from "axios";
import { useQuery } from "react-query";
import ENV from "../constants/envConstants";

export const usePlans = () => {
  return useQuery("usePlans", async () => {
    const { data } = await axios.get(`${ENV.WAGTAIL_API}/plan-list/`, { withCredentials: false });
    return data;
  });
};
