import { Box, ResponsiveContext, Text } from "grommet";
import React, { useContext } from "react";
import { PropTypes } from "prop-types";
import { usePlans } from "../api/wt";
import Loader from "./Loader";
import PricingItem from "./PricingItem";

const PricingTable = ({ monthlyActive, currentPlanId, processSuccess }) => {
  const { data, isFetching } = usePlans();

  const size = useContext(ResponsiveContext);

  const isMediumOrSmaller = size === "medium" || size === "small" || size === "xsmall";

  if (isFetching) {
    return <Loader />;
  }
  const currentPlanObj = data.filter(
    (plan) => plan.chargebee_id === currentPlanId || plan.chargebee_annual_id === currentPlanId
  );

  return (
    <>
      <Box direction="column" height={{ min: "auto" }}>
        <Box
          direction={isMediumOrSmaller ? "column" : "row"}
          fill
          flex="grow"
          gap={isMediumOrSmaller ? "large" : "medium"}
          height={{ min: "auto" }}
          pad={{ bottom: "medium" }}
        >
          {data.map((plan) => {
            return (
              <PricingItem
                plan={plan}
                monthlyActive={monthlyActive}
                currentPlan={currentPlanObj[0]}
                currentPlanId={currentPlanId}
                processSuccess={processSuccess}
              />
            );
          })}
        </Box>
        <Text size="xsmall" margin={{ bottom: "medium" }}>
          * Prices shown are USD and exclusive of any applicable sales taxes.
        </Text>
      </Box>
    </>
  );
};

PricingTable.propTypes = {
  currentPlanId: PropTypes.string.isRequired,
  monthlyActive: PropTypes.bool.isRequired,
};

export default PricingTable;
