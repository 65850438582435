import { Box, ResponsiveContext } from "grommet";
import React, { useContext } from "react";

const Container = ({ children, margin, round }) => {
  const size = useContext(ResponsiveContext);

  return (
    <Box
      pad={size === "medium" ? "32px" : "large"}
      margin={margin || "small"}
      background="white"
      round={round || "medium"}
      height={{ min: "60vh" }}
    >
      {children}
    </Box>
  );
};

export default Container;
