import styled from "styled-components";
import { PropTypes } from "prop-types";
import { up } from "styled-breakpoints";
import { brandRed } from "../constants/colours";

const Link = styled.a`
  color: ${brandRed};
  text-decoration: none;
  font-family: Gordita;
  font-weight: 500;
  text-align: ${(props) => props.align};
  display: block;
  font-size: 0.85em;

  ${up("md")} {
    font-size: 0.9em;
  }

  ${up("lg")} {
    font-size: 0.95em;
  }

  ${up("xxl")} {
    font-size: 1em;
  }
`;

const LinkButton = styled.a`
  color: ${brandRed};
  text-decoration: none;
  font-family: Gordita;
  font-weight: 500;
  text-align: ${(props) => props.align};
  display: block;
  font-size: 0.85em;

  ${up("md")} {
    font-size: 0.9em;
  }

  ${up("lg")} {
    font-size: 0.95em;
  }

  ${up("xxl")} {
    font-size: 1em;
  }
`;

function Hyperlink(props) {
  if (props.href !== "") {
    return (
      <Link align={props.align} onClick={() => props.onClick()} href={props.href}>
        {props.text}
      </Link>
    );
  }
  return (
    <LinkButton align={props.align} onClick={() => props.onClick()}>
      {props.text}
    </LinkButton>
  );
}

Hyperlink.defaultProps = {
  onClick: () => {},
  align: "center",
  href: "",
};

Hyperlink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string.isRequired,
  align: PropTypes.string,
  onClick: PropTypes.func,
};

export default Hyperlink;
