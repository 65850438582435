import { Box, Heading, Card, Button } from "grommet";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faLock, faUser } from "pro-regular-svg-icons";
import ChangePasswordPopup from "./ChangePasswordPopup";
import UpdateProfilePopup from "./UpdateProfilePopup";
import Manage2FA from "./Manage2FA";
import { useUser } from "../api/queries";
import Loader from "./Loader";

const PersonalSettings = () => {
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [updateProfileVisible, setUpdateProfileVisible] = useState(false);
  const [twoFactorVisible, setTwoFactorVisible] = useState(false);

  const { data, isFetching } = useUser();

  if (!data) {
    return <Loader />;
  }

  const closePopups = () => {
    setChangePasswordVisible(false);
    setUpdateProfileVisible(false);
    setTwoFactorVisible(false);
  };

  return (
    <>
      <Box>
        <Heading size="small">Personal Settings</Heading>
      </Box>
      <Card>
        <Box direction="row" pad="medium" border="bottom">
          <Box basis="1/3">Profile</Box>
          <Box basis="1/3" pad={{ left: "large" }} direction="row" gap="small" align="center" />
          <Box basis="1/3" align="end">
            <Button
              plain
              reverse
              label="Update Profile"
              icon={<FontAwesomeIcon icon={faUser} size="1x" />}
              onClick={() => setUpdateProfileVisible(true)}
            />
            {updateProfileVisible && <UpdateProfilePopup closePopup={closePopups} />}
          </Box>
        </Box>
        <Box direction="row" pad="medium" border="bottom">
          <Box basis="1/3">Password</Box>
          <Box basis="1/3" pad={{ left: "large" }} direction="row" gap="small" align="center" />
          <Box basis="1/3" align="end">
            <Button
              plain
              reverse
              label="Change Password"
              icon={<FontAwesomeIcon icon={faLock} size="1x" />}
              onClick={() => setChangePasswordVisible(true)}
            />
            {changePasswordVisible && <ChangePasswordPopup closePopup={closePopups} />}
          </Box>
        </Box>
        {/* <Box direction="row" pad="medium">
          <Box basis="1/3">Two Factor Authentication</Box>
          <Box basis="1/3" pad={{ left: "large" }} direction="row" gap="small" align="center">
            {data.has_2fa ? "Enabled" : "Disabled"}
          </Box>
          <Box basis="1/3" align="end">
            <Button
              plain
              reverse
              label="Manage 2FA"
              icon={<FontAwesomeIcon icon={faKey} size="1x" />}
              onClick={() => setTwoFactorVisible(true)}
            />
            {twoFactorVisible && <Manage2FA closePopup={closePopups} />}
          </Box>
        </Box> */}
      </Card>
    </>
  );
};

export default PersonalSettings;
