import Swal from "sweetalert2";

export const handle500 = () => {
  Swal.fire({
    title: "Oops!",
    text:
      "It's not your fault! Our server has encountered an internal error. An adminstrator has been notified. Try refreshing your page in the interim.",
    width: "35rem",
  });
};

export const handleUnknown = () => {
  Swal.fire({
    title: "Oops!",
    text: "An unknown error has occured. Try refreshing your page in the interim.",
    width: "35rem",
  });
};
