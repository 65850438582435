import ClipLoader from "react-spinners/ClipLoader";
import { PropTypes } from "prop-types";
import BeatLoader from "react-spinners/BeatLoader";
import styled from "styled-components";
import { brandRed } from "../constants/colours";

const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const Loader = (props) => {
  if (props.type === "clip") {
    return (
      <LoaderWrap>
        <ClipLoader size={props.size} color={brandRed} />
      </LoaderWrap>
    );
  }
  if (props.type === "beat") {
    return (
      <LoaderWrap>
        <BeatLoader size={props.size} color={brandRed} />
      </LoaderWrap>
    );
  }
};

Loader.defaultProps = {
  size: 75,
  type: "clip",
};

Loader.propTypes = {
  size: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default Loader;
