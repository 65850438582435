import { useState, useContext } from "react";
import {
  Box,
  Card,
  DateInput,
  Heading,
  Text,
  DataChart,
  CardHeader,
  CardBody,
  DataTable,
  ResponsiveContext,
} from "grommet";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "pro-regular-svg-icons";
import { useParams } from "react-router-dom";
import Container from "./Container";
import { useQRAnalytics } from "../api/queries";
import Loader from "./Loader";

const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: "short",
  day: "numeric",
  year: "numeric",
});

const QRAnalytics = () => {
  const today = dayjs().format("YYYY-MM-DD");
  const sevenDaysAgo = dayjs().subtract(7, "day").format("YYYY-MM-DD");
  const [value, setValue] = useState([sevenDaysAgo, today]);

  const { place } = useParams();
  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";

  const { data, isFetching, isPreviousData } = useQRAnalytics(place, { startDate: value[0], endDate: value[1] });

  const onChange = (event) => {
    const nextValue = event.value;
    setValue(nextValue);
  };

  const genChartData = (scanData = []) => {
    if (scanData && Object.keys(scanData).length > 1) {
      const chartData = [];
      Object.entries(scanData).forEach((val) => {
        const date = dayjs.unix(val[0]).format("YYYY-MM-DD");
        chartData.push({ date, scans: val[1] });
      });
      return chartData;
    }
    return [];
  };

  return (
    <Container>
      <Box direction={isSmall ? "column" : "row"} justify="between">
        <Box>
          <Heading margin={{ bottom: "medium", top: "none" }} size="small">
            QR code analytics
          </Heading>
        </Box>
        <Box>
          <DateInput
            buttonProps={{
              label: `${dateFormat.format(new Date(value[0]))} - ${dateFormat.format(new Date(value[1]))}`,
              secondary: true,
            }}
            onChange={onChange}
            value={value}
          />
        </Box>
      </Box>
      {isFetching || isPreviousData ? (
        <Loader />
      ) : (
        <>
          <Card pad="medium" direction="row" justify="between" margin={{ vertical: "medium" }}>
            <Box justify="between">
              <Text>Total scans</Text>
              <Text>{data.total_scans}</Text>
            </Box>
            <Box>
              <FontAwesomeIcon size="4x" icon={faQrcode} />
            </Box>
          </Card>
          {data.total_scans > 0 && (
            <Box>
              <Box direction={isSmall ? "column" : "row"} justify="between" gap="large">
                <Card pad="medium" basis="1/2">
                  <CardHeader>
                    <Text>Scans over time</Text>
                  </CardHeader>
                  <CardBody>
                    <DataChart
                      data={genChartData(data.scan_data)}
                      series={["date", "scans"]}
                      chart={[
                        { property: ["scans"], type: "area", thickness: "xsmall", opacity: "medium" },
                        { property: ["scans"], type: "line", thickness: "xsmall", round: true },
                        { property: ["scans"], type: "point", round: true, thickness: "xsmall" },
                      ]}
                      axis={{ x: "date", y: { property: "scans", granularity: "medium" } }}
                      guide={{ x: { granularity: "fine" }, y: true }}
                      alignSelf="start"
                      bounds="align"
                      detail
                      size={{ width: "fill" }}
                    />
                  </CardBody>
                </Card>
                <Card pad="medium" basis="1/2">
                  <CardHeader>
                    <Text>Scans by city</Text>
                  </CardHeader>
                  <CardBody>
                    <DataTable
                      data={data.scans_by_city}
                      columns={[
                        { property: "city", header: <Text>City</Text>, primary: true },
                        { property: "scan_num", header: <Text>Scans</Text> },
                        // { property: "perc", header: <Text>Percentage</Text> },
                      ]}
                      fill
                    />
                  </CardBody>
                </Card>
              </Box>
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default QRAnalytics;
