import styled from "styled-components";
import { PropTypes } from "prop-types";

const Label = styled.label`
  font-family: Gordita;
  font-weight: 500;
  margin-bottom: 0.5em;
  display: block;
`;

function InputLabel(props) {
  return (
    <Label id={props.for} htmlFor={props.for}>
      {props.text}
    </Label>
  );
}

InputLabel.propTypes = {
  text: PropTypes.string.isRequired,
  for: PropTypes.string.isRequired,
};

export default InputLabel;
