import { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { up } from "styled-breakpoints";

const SelectWrap = styled.div`
    background: ${(props) => (props.enabled ? "#FF5265" : "#E8E8E8")};
    padding: 0.5em 1em;
    color: ${(props) => (props.enabled ? "white" : "black")};
    font-weight: 500;
    border-radius: 18px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 0.75em;
    font-size: 0.75em;
    z-index: 1;
    position: inherit;

    ${up("sm")} {
        font-size: 0.8em;
    }

    ${up("md")} {
        font-size: 0.85em;
    }

    ${up("lg")} {
        width: auto;
        flex-direction: row;
        display; inline;
        width: auto;
        font-size: 0.9em;
        margin-right: 0.25em;
    }

    ${up("xxl")} {
        padding: 0.5em 1.5em;
        margin: 0;
        font-size: 0.95em;
    }

    ${up("giant")} {
        font-size: 1em;
    }
`;

const SelectOption = (props) => {
  const [active, setActive] = useState(props.active);

  useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  const disableOption = (option) => {
    setActive(false);
    props.disableOption(option);
  };

  const enableOption = (option) => {
    setActive(true);
    props.enableOption(option);
  };

  const onClick = (item) => {
    if (active) {
      disableOption(item);
    } else {
      enableOption(item);
    }
  };

  return (
    <SelectWrap enabled={active} onClick={onClick}>
      {props.name}
    </SelectWrap>
  );
};

SelectOption.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  enableOption: PropTypes.func.isRequired,
  disableOption: PropTypes.func.isRequired,
};

export default SelectOption;
