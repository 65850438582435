import React, { cloneElement } from "react";
import { useMediaQuery } from "react-responsive";
import { PropTypes } from "prop-types";
import styled, { ThemeProvider } from "styled-components";
import { QueryClientProvider, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import ReactSwal from "../utils/swalUtils";
import SectionForm from "./SectionForm";
import breakpoints from "../constants/breakpoints";

const FormWrap = styled.div`
  padding: 2em;
  border: #ff4256 2px solid;
  border-radius: 0.5rem;
`;

const SectionFormPopup = ({ element, numOfSections, menuId, section }) => {
  const isLargerScreen = useMediaQuery({
    query: "(max-width: 850px)",
  });

  const cache = useQueryClient();
  const { place } = useParams();

  const formContent = (
    <ThemeProvider theme={breakpoints}>
      <FormWrap>
        <QueryClientProvider client={cache}>
          <SectionForm menuId={menuId} numOfSections={numOfSections} section={section} place={place} />
        </QueryClientProvider>
      </FormWrap>
    </ThemeProvider>
  );
  const addSection = () => {
    ReactSwal.fire({
      showCloseButton: true,
      html: formContent,
      width: isLargerScreen ? "90%" : "50%",
      padding: 0,
      showConfirmButton: false,
      customClass: {
        content: "swal-additem",
      },
    });
  };

  const El = cloneElement(element, { onClick: () => addSection() });
  return El;
};

SectionFormPopup.defaultProps = {
  section: null,
};

SectionFormPopup.propTypes = {
  menuId: PropTypes.string.isRequired,
  numOfSections: PropTypes.number.isRequired,
  section: PropTypes.object,
  element: PropTypes.element.isRequired,
};

export default SectionFormPopup;
