import React from "react";
import { Card, Text, CardHeader, CardFooter } from "grommet";
import { faPhone, faEnvelope, faClock } from "pro-regular-svg-icons";
import dayjs from "dayjs";
import CardTrait from "./CardTrait";

const GuestRegisterItem = (props) => {
  const { contact } = props;

  const timeScanned = dayjs(contact.date).format("HH:MM A");

  return (
    <Card background="white" pad={{ vertical: "medium", horizontal: "medium" }} flex>
      <CardHeader>
        <Text weight="bold" margin={{ bottom: "medium" }}>
          {contact.name}
        </Text>
      </CardHeader>
      <CardFooter justify="start">
        <CardTrait content={contact.phone_number} icon={faPhone} />
        {contact.email && <CardTrait content={contact.email} icon={faEnvelope} />}
        <CardTrait content={timeScanned} icon={faClock} />
      </CardFooter>
    </Card>
  );
};

export default GuestRegisterItem;
