import { useQuery, useInfiniteQuery } from "react-query";
import axios from "../axiosConfig";
import ENV from "../constants/envConstants";

export function useUser() {
  return useQuery(
    "userDetail",
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/users/`, {
        withCredentials: true,
      });
      return data;
    },
    {
      retry: false,
    },
  );
}
export function useCsrf() {
  return useQuery(
    "csrfToken",
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/set-csrf/`);
      return data;
    },
    {
      retry: false,
    },
  );
}

export function usePlace(placeId) {
  return useQuery(
    ["placeDetail", { place: placeId }],
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/`, {
        withCredentials: true,
      });
      return data;
    },
    {
      enabled: !!placeId,
      cacheTime: 10000,
      retry: 2,
    },
  );
}

export function usePlaceSubscription(placeId) {
  return useQuery(
    ["placeSubscription", { place: placeId }],
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/subscription/`, {
        withCredentials: true,
      });
      return data;
    },
    {
      enabled: !!placeId,
    },
  );
}

export function useContacts(placeId, pageNum, date) {
  return useQuery(
    ["contactList", { place: placeId }, pageNum, date],
    async () => {
      const { data } = await axios.get(
        `${ENV.BACKEND_URL}/restaurants/contacttracing/${placeId}/contacts?date=${date}&page=${pageNum}`,
        {
          withCredentials: true,
        },
      );
      return data;
    },
    {
      keepPreviousData: true,
    },
  );
}

export function useDishes(placeId, searchQuery) {
  return useInfiniteQuery(
    ["dishList", { place: placeId }, searchQuery],
    async ({ pageParam = 0 }) => {
      const params = {};
      if (searchQuery !== "") {
        params.search = searchQuery;
      }
      if (pageParam !== 0) {
        params.cursor = pageParam;
      }
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/dishes/${placeId}/all`, {
        withCredentials: true,
        params,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next;
      },
    },
  );
}

export function useFeedback(placeId, params) {
  return useQuery(
    ["feedbackList", { place: placeId }, params],
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/feedback/${placeId}/feedback/`, {
        params,
      });
      return data;
    },
    {
      keepPreviousData: true,
    },
  );
}

export function useLeads(placeId, params) {
  return useQuery(
    ["leadList", { place: placeId }, params],
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/leads/${placeId}/leads/`, {
        withCredentials: true,
        params,
      });
      return data;
    },
    {
      keepPreviousData: true,
    },
  );
}

export const leadExportUrl = (placeId, modified_before, modified_after) => {
  return `${ENV.BACKEND_URL}/restaurants/leads/${placeId}/leads/export/?modified_before=${modified_before}&modified_after=${modified_after}`;
};

export function useOffers(placeId, params) {
  return useQuery(
    ["offerList", { place: placeId }, params],
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/leads/${placeId}/offers/`, {
        withCredentials: true,
        params,
      });
      return data;
    },
    {
      keepPreviousData: true,
    },
  );
}

export const useDiscoverySettings = () => {
  return useQuery(
    "discoverySettings",
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/discovery-settings/`, {
        withCredentials: true,
      });
      return data;
    },
    { cacheTime: 2000 },
  );
};

export const useQRCode = (placeId, params) => {
  return useQuery(["useQRCode", { place: placeId }, params], async () => {
    const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/qrcodes/${placeId}/`, {
      withCredentials: true,
      params,
    });
    return data;
  });
};

export const useQRAnalytics = (placeId, params) => {
  return useQuery(
    ["useQRAnalytics", { place: placeId }, params],
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/analytics/${placeId}/qrcode/`, {
        withCredentials: true,
        params,
      });
      return data;
    },
    { keepPreviousData: true },
  );
};

export const usePlaceViewAnalytics = (placeId, params) => {
  return useQuery(
    ["usePlaceViewAnalytics", { place: placeId }, params],
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/analytics/${placeId}/place-views/`, {
        withCredentials: true,
        params,
      });
      return data;
    },
    { keepPreviousData: true },
  );
};

export const usePopularDishesAnalytics = (placeId, params) => {
  return useQuery(
    ["usePopularDishesAnalytics", { place: placeId }, params],
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/analytics/${placeId}/popular-dishes/`, {
        withCredentials: true,
        params,
      });
      return data;
    },
    { keepPreviousData: true },
  );
};

export function useDishesAnalytics(placeId, params) {
  return useInfiniteQuery(
    ["dishAnalyticsList", { place: placeId }, params],
    async ({ pageParam = 0 }) => {
      const newParams = { ...params };
      if (pageParam !== 0) {
        newParams.cursor = pageParam;
      }
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/analytics/${placeId}/dishes/`, {
        withCredentials: true,
        params: newParams,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next;
      },
    },
  );
}

export const useInvoices = (placeId, params) => {
  return useQuery(["useInvoices", { place: placeId }, params], async () => {
    const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/billing/${placeId}/invoices/`, {
      withCredentials: true,
      params,
    });
    return data;
  });
};

export const usePrimaryPaymentSource = (placeId, params) => {
  return useQuery(["usePrimaryPaymentSource", { place: placeId }, params], async () => {
    const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/billing/${placeId}/primary-payment-method/`, {
      withCredentials: true,
      params,
    });
    return data;
  });
};

export function useOfferSettings(placeId) {
  return useQuery(
    ["offerSettings", { place: placeId }],
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/leads/settings/${placeId}/`, {
        withCredentials: true,
      });
      return data;
    },
    {
      enabled: !!placeId,
    },
  );
}

export function useOffersNoPagination(placeId) {
  return useQuery(
    ["offerListNoPaginaiton", { place: placeId }],
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/leads/${placeId}/offers-no-pages/`, {
        withCredentials: true,
      });
      return data;
    },
    {
      enabled: !!placeId,
    },
  );
}

export function usePlaceActions(placeId, params) {
  return useQuery(
    ["placeActions", { place: placeId }, params],
    async () => {
      const { data } = await axios.get(`${ENV.BACKEND_URL}/restaurants/place-actions/`, {
        withCredentials: true,
        params,
      });
      return data;
    },
    {
      enabled: !!placeId,
    },
  );
}

export function usePlaceSEOOptions(placeId) {
  return useQuery(
    ["placeSEOOptions", { place: placeId }],
    async () => {
      const { data } = await axios.options(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/settings/seo/`, {
        withCredentials: true,
      });
      return data;
    },
    {
      enabled: !!placeId,
      cacheTime: 10000,
      retry: 2,
    },
  );
}

export function useDishOptions(placeId) {
  return useQuery(
    ["dishOptions", { place: placeId }],
    async () => {
      const { data } = await axios.options(`${ENV.BACKEND_URL}/restaurants/dishes/${placeId}/create/`, {
        withCredentials: true,
      });
      return data;
    },
    {
      cacheTime: 1800000,
      staleTime: 1800000,
      retry: 2,
    },
  );
}

export function useMenuSettingsOptions(placeId) {
  return useQuery(
    ["menuSettingsOptions", { place: placeId }],
    async () => {
      const { data } = await axios.options(`${ENV.BACKEND_URL}/restaurants/places/${placeId}/settings/menu/`, {
        withCredentials: true,
      });
      return data;
    },
    {
      cacheTime: 1800000,
      staleTime: 1800000,
      retry: 2,
    },
  );
}
