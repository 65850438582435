import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Anchor, Box, Button, DateInput, Heading, Paragraph, ResponsiveContext } from "grommet";
import dayjs from "dayjs";
import { useContacts } from "../api/queries";
import Loader from "./Loader";
import GuestRegisterItem from "./GuestRegisterItem";
import Container from "./Container";
import ReactSwal from "../utils/swalUtils";
import Pagination from "./Pagination";
import PaginationLoader from "./PaginationLoader";

const GuestRegister = () => {
  const { place } = useParams();
  const size = useContext(ResponsiveContext);

  const [page, setPage] = useState(1);

  const [numPages, setNumPages] = useState(1);

  const [date, setDate] = useState(dayjs());

  const { data, isPreviousData, isFetching } = useContacts(place, page, date.format("YYYY-MM-DD"));

  const dateChange = (changedDate) => {
    setDate(dayjs(changedDate));
    setPage(1);
  };

  useEffect(() => {
    if (data) {
      const numOfPages = Math.ceil(data.count / 12);
      setNumPages(numOfPages);
    }
  }, [data]);

  if (!data) {
    return <Loader />;
  }

  const isSmall = size === "small" || size === "xsmall";

  const updatePage = (num) => {
    setPage(num);
  };

  const exportMessage = (
    <Box>
      <Paragraph textAlign="start" fill>
        In order to export contact tracing data, you will need to send an email to{" "}
        <Anchor href="mailto:contact@eatsee.menu">contact@eatsee.menu</Anchor>.
      </Paragraph>
      <Paragraph textAlign="start" fill>
        The email must contain evidence that contact tracing information is required to be exported for contact tracing
        purposes.
      </Paragraph>
      <Paragraph textAlign="start" fill>
        If contact tracing information is urgently required, please call{" "}
        <Anchor href="tel:+61455226695">+61455226695</Anchor>.
      </Paragraph>
    </Box>
  );
  const exportMessagePopup = () => {
    ReactSwal.fire({
      titleText: "Contact tracing export",
      icon: "warning",
      width: "50rem",
      padding: "1rem",
      html: exportMessage,
      iconColor: "#f6ad55",
      confirmButtonText: "Got it",
    });
  };

  return (
    <Box height={{ min: "auto" }}>
      <Container>
        <Box direction={isSmall ? "column" : "row"} justify="between" gap="large">
          <Box basis="1/2">
            <Heading level={2} margin={{ bottom: "small", top: "none" }} size="medium">
              Guest Register
            </Heading>
            <Heading level={3} size="20px" margin={{ top: "none", bottom: "medium" }}>
              View contact tracing details left by customers.
            </Heading>
          </Box>
          <Box basis="1/2" direction="row" justify="between" gap="medium" align="start">
            <Box basis="1/2">
              <DateInput
                value={date.toISOString()}
                secondary
                justify="start"
                onChange={({ value }) => dateChange(value)}
                buttonProps={{ secondary: true, label: date.format("DD MMM YYYY") }}
              />
            </Box>
            <Box basis="1/2">
              <Button fill="horizontal" width="1/2" primary label="Export" onClick={() => exportMessagePopup()} />
            </Box>
          </Box>
        </Box>
        {data.results.length === 0 ? (
          <Box margin={{ top: "large" }}>
            <Paragraph>No guests registered on this date.</Paragraph>
          </Box>
        ) : (
          <Pagination
            isPreviousData={isPreviousData}
            numPages={numPages}
            currentPage={page}
            latestData={data}
            updatePage={updatePage}
          />
        )}
        <PaginationLoader loading={isFetching} />
        <Box gap="medium" height={{ min: "auto" }}>
          {data.results.map((contact) => {
            return <GuestRegisterItem key={contact.pk} contact={contact} />;
          })}
        </Box>
      </Container>
    </Box>
  );
};

export default GuestRegister;
