import { Box, Button, Heading, Layer, Paragraph } from "grommet";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useOffers } from "../api/queries";
import Container from "./Container";
import Loader from "./Loader";
import OfferForm from "./OfferForm";
import OfferItem from "./OfferItem";
import Pagination from "./Pagination";

const Offers = () => {
  const { place } = useParams();

  const [newFormVisible, setNewFormVisible] = useState(false);

  const [page, setPage] = useState(1);

  const [numPages, setNumPages] = useState(1);

  const { data, isPreviousData, isFetching } = useOffers(place, { page });

  useEffect(() => {
    if (data) {
      const numOfPages = Math.ceil(data.count / 12);
      setNumPages(numOfPages);
    }
  }, [data]);

  const hideNewForm = () => {
    setNewFormVisible(false);
  };

  const updatePage = (num) => {
    setPage(num);
  };

  const newForm = (
    <Layer position="center" onEsc={() => hideNewForm()} onClickOutside={() => hideNewForm()} round>
      <Box
        background="white"
        border={{ color: "brand", size: "small" }}
        round="small"
        pad="large"
        width="xlarge"
        overflow={{ vertical: "auto" }}
      >
        <OfferForm closeForm={hideNewForm} />
      </Box>
    </Layer>
  );

  return (
    <Container>
      <Box margin={{ vertical: "medium" }} direction="row" justify="between">
        <Heading alignSelf="center" level={2} margin="none">
          Lead generators
        </Heading>
        <Button primary label="Create new lead generator" onClick={() => setNewFormVisible(true)} />
        {newFormVisible && newForm}
      </Box>
      {!data ? (
        <Loader />
      ) : (
        <>
          {data.results.length === 0 ? (
            <Box margin={{ top: "large" }}>
              <Paragraph>No lead generators have been created.</Paragraph>
            </Box>
          ) : (
            <Pagination
              numPages={numPages}
              isPreviousData={isPreviousData}
              currentPage={page}
              latestData={data}
              updatePage={updatePage}
            />
          )}
          <Box gap="medium">
            {data.results.map((offer) => {
              return <OfferItem page={page} offer={offer} key={offer.uuid} />;
            })}
          </Box>
        </>
      )}
    </Container>
  );
};

export default Offers;
