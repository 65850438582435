import { Formik, Form } from "formik";
import { Box, Heading, Button } from "grommet";
import React, { useState } from "react";
import * as Yup from "yup";
import { PropTypes } from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import forEach from "lodash/forEach";
import { faWindowClose } from "pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "./Loader";
import InputDiv from "./InputDiv";
import SwitchInput from "./SwitchInput";
import { editOffer, createOffer } from "../api/mutations";
import { trackEventSegment } from "../utils/segmentUtils";
import Toast from "./Toast";
import ReactSwal from "../utils/swalUtils";

const OfferForm = ({ offer, closeForm }) => {
  const { place } = useParams();

  const [formData, setFormData] = useState({
    title: offer ? offer.title : "",
    description: offer ? offer.description : "",
    days_valid: offer ? offer.days_valid : "",
    in_venue_only: offer ? offer.in_venue_only : true,
    claimable: offer ? offer.claimable : false,
    email_subject: offer ? offer.email_subject : "",
    email_content: offer ? offer.email_content : "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const schema = Yup.object({
    title: Yup.string().required("This field is required."),
    description: Yup.string().required("This field is required."),
    days_valid: Yup.number("Please enter only numerical values.")
      .when("claimable", {
        is: true,
        then: Yup.number("Please enter only numerical values.").required("This field is required."),
      })
      .nullable(),
    claimable: Yup.boolean(),
    email_subject: Yup.string()
      .when("claimable", {
        is: true,
        then: Yup.string().required("This field is required."),
      })
      .nullable(),
    email_content: Yup.string()
      .when("claimable", {
        is: true,
        then: Yup.string().required("This field is required."),
      })
      .nullable(),
  });

  const cache = useQueryClient();

  const { mutate: editMutate } = useMutation(editOffer);
  const { mutate: createMutate } = useMutation(createOffer);

  const onSubmit = async (values, bag) => {
    const reqContent = {
      values: {
        place,
        title: values.title,
        description: values.description,
        in_venue_only: values.in_venue_only,
        claimable: values.claimable,
      },
    };
    if (values.claimable) {
      reqContent.values = {
        ...reqContent.values,
        days_valid: values.days_valid,
        email_subject: values.email_subject,
        email_content: values.email_content,
      };
    }
    setIsLoading(true);
    if (offer) {
      reqContent.offerId = offer.uuid;
      await editMutate(reqContent, {
        onSuccess: async (resData) => {
          await cache.refetchQueries(["offerList", { place }]);
          trackEventSegment("Updated lead generator");
          closeForm();
          Toast.fire({
            title: "Lead generator updated",
            icon: "success",
          });
        },
        onError: (error, variables) => {
          const { data } = error.response;
          switch (error.response.status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              }
              break;
            case 500:
              ReactSwal.fire({
                title: "Oops!",
                text:
                  "It's not your fault! Our server has encountered an internal error. An adminstrator has been notified. Try refreshing your page in the interim.",
                width: "35rem",
              });
              break;
            default:
              ReactSwal.fire({
                title: "Oops!",
                text: "An unknown error has occured. Try refreshing your page in the interim.",
                width: "35rem",
              });
          }
        },
      });
    } else {
      await createMutate(reqContent, {
        onSuccess: async (resData) => {
          await cache.refetchQueries(["offerList", { place }]);
          bag.resetForm();
          trackEventSegment("Created lead generator");
          closeForm();
          Toast.fire({
            title: "Lead generator created",
            icon: "success",
          });
        },
        onError: (error, variables) => {
          const { data } = error.response;
          switch (error.response.status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              }
              break;
            case 500:
              ReactSwal.fire({
                title: "Oops!",
                text:
                  "It's not your fault! Our server has encountered an internal error. An adminstrator has been notified. Try refreshing your page in the interim.",
                width: "35rem",
              });
              break;
            default:
              ReactSwal.fire({
                title: "Oops!",
                text: "An unknown error has occured. Try refreshing your page in the interim.",
                width: "35rem",
              });
          }
        },
      });
    }
  };

  if (isLoading) {
    return <Loader type="beat" size={20} />;
  }

  return (
    <Box>
      <Formik validationSchema={schema} initialValues={formData} onSubmit={onSubmit}>
        {(formik) =>
          formik.isSubmitting ? (
            <Loader type="beat" size={20} />
          ) : (
            <Form>
              <Box>
                <Box flex direction="row" justify="between">
                  <Box basis="3/4">
                    <Heading margin={{ bottom: "medium", top: "none" }} size="small">
                      {offer ? "Edit" : "Create new"} lead generator
                    </Heading>
                  </Box>
                  <Box basis="1/4" align="end">
                    <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => closeForm()} />
                  </Box>
                </Box>
                <InputDiv text="Title" placeholder="Add lead generator title" name="title" hasLabel />
                <InputDiv
                  type="textarea"
                  text="Description"
                  placeholder="Add lead generator description"
                  name="description"
                  hasLabel
                />
                <SwitchInput
                  defaultChecked={formik.values.in_venue_only}
                  toggleChange={(value) => formik.setFieldValue("in_venue_only", !formik.values.in_venue_only)}
                  label="In venue only"
                  helpText="Designates if this lead generator will be shown ONLY to restaurants at the venue."
                />
                <SwitchInput
                  defaultChecked={formik.values.claimable}
                  toggleChange={(value) => formik.setFieldValue("claimable", !formik.values.claimable)}
                  label="Claimable offer"
                  helpText="Designates if this lead generator is an offer that can be 'claimed' or 'redeemed'. Claimable offers will send an email to customers after 24 hours with a link to 'redeem'."
                />
                {formik.values.claimable && (
                  <>
                    <InputDiv text="Days Valid" placeholder="Add days offer is valid for" name="days_valid" hasLabel />
                    <InputDiv
                      text="Email Subject"
                      placeholder="Add an offer email subject"
                      name="email_subject"
                      hasLabel
                    />
                    <InputDiv
                      type="textarea"
                      text="Email Content"
                      placeholder="Add offer email content"
                      name="email_content"
                      hasLabel
                    />
                  </>
                )}
                <Button
                  primary
                  label={offer ? "Save changes" : "Create offer"}
                  type="submit"
                  margin={{ bottom: "small" }}
                />
              </Box>
            </Form>
          )
        }
      </Formik>
    </Box>
  );
};

OfferForm.propTypes = {
  offer: PropTypes.object,
  closeForm: PropTypes.func.isRequired,
};

export default OfferForm;
