const breakpoints = {
  breakpoints: {
    xs: "319px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1500px",
    giant: "1900px",
  },
};
export default breakpoints;
