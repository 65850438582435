import React, { useContext } from "react";
import { Layer, Box, Heading, ResponsiveContext } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "pro-solid-svg-icons";
import MenuReorder from "./MenuReorder";

const MenuReorderPopup = ({ closePopup }) => {
  const size = useContext(ResponsiveContext);

  return (
    <Layer
      full={size === "small" || size === "xsmall"}
      position="center"
      onClickOutside={closePopup}
      onEsc={closePopup}
    >
      <Box fill={size === "small" || size === "xsmall"} pad="large" width="xlarge" background="white" round="small">
        <Box>
          <Box direction="row" justify="between" margin={{ bottom: "large" }}>
            <Heading level={2} size="small" margin="none">
              Menu order
            </Heading>
            <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => closePopup()} />
          </Box>
          <MenuReorder />
        </Box>
      </Box>
    </Layer>
  );
};

export default MenuReorderPopup;
