import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { useMutation } from "react-query";
import forEach from "lodash/forEach";
import AuthBase from "./AuthBase";
import Error from "./Error";
import InputDiv from "./InputDiv";
import Button from "./Button";
import Loader from "./Loader";
import { forgotPassword } from "../api/mutations";
import { trackEventSegment } from "../utils/segmentUtils";
import ReactSwal from "../utils/swalUtils";
import Hyperlink from "./Hyperlink";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const initialData = {
    email: "",
  };

  const [linkSent, setLinkSent] = useState(false);

  const { mutate } = useMutation(forgotPassword);

  const schema = Yup.object({
    email: Yup.string().required("This field is required.").email("Please enter a valid email."),
  });

  const onSubmit = async (values, bag) => {
    await mutate(
      { values },
      {
        onSuccess: () => {
          trackEventSegment("Password reset sent");
          setLinkSent(true);
        },
        onError: (error, variables) => {
          const { data } = error.response;
          switch (error.response.status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              }
              break;
            case 500:
              ReactSwal.fire({
                title: "Oops!",
                text:
                  "It's not your fault! Our server has encountered an internal error. An adminstrator has been notified. Try refreshing your page in the interim.",
                width: "35rem",
              });
              break;
            default:
              ReactSwal.fire({
                title: "Oops!",
                text: "An unknown error has occured. Try refreshing your page in the interim.",
                width: "35rem",
              });
          }
        },
      }
    );
  };

  return (
    <AuthBase header="Forgot password" subheader="Enter your email below to reset your password">
      {linkSent ? (
        <>
          <p>An email with a link to reset your password has been sent.</p>
        </>
      ) : (
        <Formik initialValues={initialData} onSubmit={onSubmit} validationSchema={schema}>
          {(formik) =>
            formik.isSubmitting ? (
              <Loader type="beat" size={20} />
            ) : (
              <Form>
                <Hyperlink onClick={() => navigate("/login")} text="< Back" align="start" />
                <Error>{formik.status}</Error>
                <div style={{ marginBottom: "1em" }} />
                <InputDiv text="Email address" name="email" placeholder="Your email address" />
                <Button type="submit" text="Send password reset link" />
              </Form>
            )
          }
        </Formik>
      )}
    </AuthBase>
  );
};

export default ForgotPassword;
