import React from "react";
import { Outlet } from "react-router";
import Container from "./Container";

const Settings = () => {
  return (
    <Container>
      <Outlet />
    </Container>
  );
};

export default Settings;
