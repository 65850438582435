import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Heading, Text, Grid, ResponsiveContext } from "grommet";
import { faClock } from "pro-solid-svg-icons";
import { useContext } from "react";
import dayjs from "dayjs";
import PlaceViewCard from "./analytics/PlaceViewCard";
import PopularDishCard from "./analytics/PopularDishCard";
import Container from "./Container";

const VisualMenuAnalytics = () => {
  const today = dayjs().format("YYYY-MM-DD");
  const thirtyDaysAgo = dayjs().subtract(30, "day").format("YYYY-MM-DD");
  const size = useContext(ResponsiveContext);

  return (
    <Container>
      <Box direction="row" align="center" justify="between">
        <Heading size="small">Visual Menu Analytics</Heading>
        <Box direction="row" background="brand" pad="xsmall" round align="center">
          <FontAwesomeIcon icon={faClock} size="sm" />
          <Text margin={{ left: "xsmall" }}>Last 30 days</Text>
        </Box>
      </Box>
      <Grid
        columns={size !== "medium" && size !== "small" ? { count: 2, size: "auto" } : "100%"}
        gap={{ row: "large", column: "medium" }}
        margin={{ bottom: "medium" }}
      >
        <PlaceViewCard startDate={thirtyDaysAgo} endDate={today} />
        <PopularDishCard startDate={thirtyDaysAgo} endDate={today} />
        {/* <MenuViewCard startDate={thirtyDaysAgo} endDate={today} /> */}
        {/* <PeakPeriodCard startDate={thirtyDaysAgo} endDate={today} /> */}
      </Grid>
      {/* <ComingSoonCard /> */}
    </Container>
  );
};

export default VisualMenuAnalytics;
