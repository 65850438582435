import { PropTypes } from "prop-types";
import { Box, Stack } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "pro-regular-svg-icons";
import { faCheckCircle } from "pro-solid-svg-icons";
import DishItem from "./DishItem";
import { brandRed } from "../constants/colours";

const DishItemSelectable = ({ dish, selectedDish, selectItem }) => {
  const selected = selectedDish === dish;

  if (selected) {
    return (
      <Stack anchor="top-right" onClick={() => selectItem(null)}>
        <Box round overflow="hidden" border={{ color: "brand", size: "small" }} onClick={() => selectItem(null)}>
          <DishItem dish={dish} draggable={false} hasEditOptions={false} />
        </Box>
        <Box pad="small">
          <FontAwesomeIcon color={brandRed} size="2x" icon={faCheckCircle} />
        </Box>
      </Stack>
    );
  }
  return (
    <Stack anchor="top-right" onClick={() => selectItem(dish)}>
      <Box overflow="hidden" round onClick={() => selectItem(dish)}>
        <DishItem dish={dish} draggable={false} hasEditOptions={false} />
      </Box>
      <Box pad="small">
        <FontAwesomeIcon color={brandRed} size="2x" icon={faCircle} />
      </Box>
    </Stack>
  );
};

DishItemSelectable.propTypes = {
  dish: PropTypes.object.isRequired,
  selectedDish: PropTypes.object.isRequired,
  selectItem: PropTypes.func.isRequired,
};

export default DishItemSelectable;
