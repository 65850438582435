import { useState, useEffect } from "react";
import { Formik, FieldArray, Form } from "formik";
import { useMutation, useQueryClient } from "react-query";
import forEach from "lodash/forEach";
import { Button } from "grommet";
import Availability from "./Availability";
import { trackEventSegment } from "../utils/segmentUtils";
import { handleUnknown, handle500 } from "../utils/errorHandles";
import Loader from "./Loader";
import { updatePlaceOpeningHours } from "../api/mutations";
import Toast from "./Toast";

const OpeningHoursForm = ({ place, closePopup }) => {
  const [loaded, setLoaded] = useState(false);

  const parseOpenHoursData = (menu) => {
    const openHours = [];
    place.opening_hours.map((item) => {
      openHours.push({
        day: item.days,
        startTime: item.start_time,
        endTime: item.end_time,
      });
    });

    return openHours;
  };

  const [formData, setFormData] = useState({
    opening_hours: [],
  });

  const cache = useQueryClient();

  const { mutate, isLoading } = useMutation(updatePlaceOpeningHours);

  useEffect(() => {
    setFormData({
      opening_hours: place ? parseOpenHoursData(place) : [],
    });
    setLoaded(true);
  }, [place]);

  const onSubmit = (values, bag) => {
    const formattedOpeningHours = [];
    forEach(values.opening_hours, (value, key) => {
      formattedOpeningHours.push({
        days: value.day,
        end_time: `${value.endTime}:00`,
        start_time: `${value.startTime}:00`,
      });
    });
    const formattedValues = {
      opening_hours: formattedOpeningHours,
    };
    mutate(
      { values: formattedValues, placeId: place.uuid },
      {
        onSuccess: (response) => {
          const previousPlace = cache.getQueryData(["placeDetail", { place: place.uuid }]);
          const newPlace = { ...previousPlace };
          newPlace.opening_hours = response.data.opening_hours;
          cache.setQueryData(["placeDetail", { place: place.uuid }], newPlace);
          trackEventSegment("Updated place opening hours");
          Toast.fire({
            title: "Opening hours successfully updated",
            icon: "success",
          });
          closePopup();
        },
        onError: (error) => {
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
        },
      }
    );
  };

  if (!loaded) {
    return <Loader />;
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={formData} enableReinitialize>
      {(formik) =>
        isLoading ? (
          <Loader />
        ) : (
          <>
            <Form>
              <FieldArray
                name="opening_hours"
                render={(arrayHelpers) => <Availability arrayHelpers={arrayHelpers} />}
              />
              <Button submitting={formik.isSubmitting} primary type="submit" label="Update opening hours" />
            </Form>
          </>
        )
      }
    </Formik>
  );
};

export default OpeningHoursForm;
