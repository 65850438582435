import React, { useState, useEffect, useContext } from "react";
import { Box, DateInput, Heading, Paragraph, ResponsiveContext } from "grommet";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import Container from "./Container";
import FeedbackItem from "./FeedbackItem";
import { useFeedback } from "../api/queries";
import Loader from "./Loader";
import Pagination from "./Pagination";

const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: "short",
  day: "numeric",
  year: "numeric",
});

const Feedback = () => {
  const { place } = useParams();

  const [page, setPage] = useState(1);
  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";

  const [numPages, setNumPages] = useState(1);

  const today = dayjs();
  const sevenDaysAgo = dayjs().subtract(7, "day");

  const [value, setValue] = useState([sevenDaysAgo, today]);

  const { data, isPreviousData, isFetching } = useFeedback(place, {
    page,
    modified_after: value[0].format("YYYY-MM-DD"),
    modified_before: value[1].format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (data) {
      const numOfPages = Math.ceil(data.count / 12);
      setNumPages(numOfPages === 0 ? 1 : numOfPages);
    }
  }, [data]);

  const updatePage = (num) => {
    setPage(num);
  };

  const dateChange = (event) => {
    const dateFrom = dayjs(event.value[0]);
    const dateTo = dayjs(event.value[1]);
    setValue([dateFrom, dateTo]);
    setPage(1);
  };

  if (!data) {
    return <Loader />;
  }

  return (
    <Box height={{ min: "auto" }}>
      <Container height={{ min: "auto" }}>
        <Box direction={isSmall ? "column" : "row"} justify="between" gap="large" height={{ min: "auto" }}>
          <Box basis="1/2">
            <Heading level={2} margin={{ bottom: "small", top: "none" }} size="medium">
              Customer feedback
            </Heading>
            <Heading level={3} size={isSmall ? "medium" : "20px"} margin={{ top: "none", bottom: "medium" }}>
              View private feedback left by customers.
            </Heading>
          </Box>
          <Box basis="1/2" direction="row" justify={isSmall ? "start" : "end"} align="start">
            <Box>
              <DateInput
                buttonProps={{
                  label: `${dateFormat.format(new Date(value[0]))} - ${dateFormat.format(new Date(value[1]))}`,
                  border: {
                    size: "1px solid",
                    color: "black",
                  },
                  secondary: true,
                }}
                onChange={dateChange}
                value={value}
              />
            </Box>
          </Box>
        </Box>
        {data.results.length === 0 ? (
          <Box margin={{ top: "large" }} height={{ min: "auto" }}>
            <Paragraph>No private feedback received during this period.</Paragraph>
          </Box>
        ) : (
          <Pagination
            numPages={numPages}
            currentPage={page}
            isPreviousData={isPreviousData}
            latestData={data}
            updatePage={updatePage}
          />
        )}
        {isFetching ? <span> Loading...</span> : null}
        <Box gap="medium" flex fill height={{ min: "auto" }}>
          {data.results.map((feedbackItem) => {
            return <FeedbackItem key={feedbackItem.pk} feedbackItem={feedbackItem} />;
          })}
        </Box>
      </Container>
    </Box>
  );
};

export default Feedback;
