import parseEnvironmentVariables from "../utils/envUtils";

const ENV_VARIABLES_RAW = {
  ENVIRONMENT: process.env.NODE_ENV,
  ROOT_URL: process.env.REACT_APP_ROOT_URL,
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  BACKEND_BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL,
  VISUAL_MENU_URL: process.env.REACT_APP_VISUAL_MENU_URL,
  SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE,
  WAGTAIL_API: process.env.REACT_APP_WAGTAIL_API,
};

const ENV_VARIABLES = parseEnvironmentVariables(ENV_VARIABLES_RAW);

const ENV = {
  ...ENV_VARIABLES,

  IS_PRODUCTION: process.env.NODE_ENV === "production",
  IS_DEVELOPMENT: process.env.NODE_ENV === "development",
  IS_TEST: process.env.NODE_ENV === "test",
};

if (ENV.IS_DEVELOPMENT) {
  console.log(ENV);
}

export default ENV;
