import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, ResponsiveContext, TextInput, Button, Heading, DateInput } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "pro-regular-svg-icons";
import dayjs from "dayjs";
import { useDishesAnalytics } from "../api/queries";
import Loader from "./Loader";
import Container from "./Container";
import AnalyticsDishItem from "./AnalyticsDishItem";

const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: "short",
  day: "numeric",
  year: "numeric",
});

const DishAnalytics = () => {
  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";
  const { place } = useParams();
  const today = dayjs().format("YYYY-MM-DD");
  const thirtyDaysAgo = dayjs().subtract(30, "day").format("YYYY-MM-DD");
  const [dateRange, setDateRange] = useState([thirtyDaysAgo, today]);

  const [searchQuery, setSearchQuery] = React.useState("");

  const { status, data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, error } = useDishesAnalytics(
    place,
    { q: searchQuery, startDate: dateRange[0], endDate: dateRange[1] }
  );

  const onChange = (event) => {
    const nextValue = event.value;
    setDateRange(nextValue);
  };

  return (
    <Container margin={{ horizontal: "small", bottom: "small" }} round={{ size: "medium" }}>
      <Box direction={isSmall ? "column" : "row"} justify="between">
        <Box>
          <Heading level={2} size="small">
            Menu item analytics
          </Heading>
        </Box>
        <Box>
          <DateInput
            buttonProps={{
              label: `${dateFormat.format(new Date(dateRange[0]))} - ${dateFormat.format(new Date(dateRange[1]))}`,
              secondary: true,
            }}
            onChange={onChange}
            value={dateRange}
          />
        </Box>
      </Box>
      <Box margin={{ vertical: "medium" }}>
        <TextInput
          icon={<FontAwesomeIcon icon={faSearch} />}
          placeholder="Search..."
          onChange={(event) => setSearchQuery(event.target.value)}
          value={searchQuery}
        />
      </Box>
      {!data ? (
        <Loader />
      ) : (
        <>
          <Grid columns={size !== "small" ? "medium" : "100%"} gap="large">
            {data.pages.map((cursor) =>
              cursor.results.map((dish) => {
                return <AnalyticsDishItem key={dish.uuid} dish={dish} />;
              })
            )}
          </Grid>

          <Button
            label={isFetchingNextPage ? "Loading more..." : hasNextPage ? "Load More" : "Nothing more to load"}
            primary
            type="button"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            margin={{ top: "medium" }}
          />
          <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div>
        </>
      )}
    </Container>
  );
};

export default DishAnalytics;
