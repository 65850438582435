import styled from "styled-components";
import { PropTypes } from "prop-types";
import { up } from "styled-breakpoints";
import { brandRed } from "../constants/colours";
import Loader from "./Loader";

const ButtonEl = styled.button`
  background: ${(props) => (props.inversed ? "white" : brandRed)};
  width: ${(props) => props.width};
  border: ${(props) => (props.inversed ? `2px${brandRed} solid` : "none")};
  border-radius: 5px;
  padding: 1em;
  display: block;
  color: ${(props) => (props.inversed ? brandRed : "white")};
  font-weight: 500;
  font-family: Gordita;
  margin: ${(props) => (props.width !== "100%" ? "0 auto" : "none")};
  margin-bottom: ${(props) => (props.hasMargin ? "2em" : "0")};
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  font-size: 0.85em;
  box-sizing: border-box;

  ${up("md")} {
    font-size: 0.9em;
    margin-bottom: ${(props) => (props.hasMargin ? "2.5em" : "0")};
  }

  ${up("lg")} {
    font-size: 0.95em;
    margin-bottom: ${(props) => (props.hasMargin ? "2.75em" : "0")};
  }

  ${up("xl")} {
    margin-bottom: ${(props) => (props.hasMargin ? "3em" : "0")};
  }

  ${up("xxl")} {
    margin-bottom: ${(props) => (props.hasMargin ? "3.5em" : "0")};
    font-size: 1em;
  }

  ${up("giant")} {
    margin-bottom: ${(props) => (props.hasMargin ? "4em" : "0")};
  }
`;

const ClipWrapEl = styled.span`
  div {
    vertical-align: bottom;
  }
  margin-right: 1em;
`;

function Button(props) {
  return (
    <ButtonEl
      type={props.type}
      width={props.width}
      inversed={props.inversed}
      onClick={props.onClick}
      hasMargin={props.hasMargin}
      disabled={props.disabled}
    >
      {props.submitting && (
        <ClipWrapEl>
          <Loader size={15} />
        </ClipWrapEl>
      )}
      {props.text}
    </ButtonEl>
  );
}

Button.defaultProps = {
  inversed: false,
  width: "100%",
  hasMargin: true,
  submitting: false,
  disabled: false,
  onClick: () => {},
};

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  inversed: PropTypes.bool,
  width: PropTypes.string,
  type: PropTypes.string.isRequired,
  hasMargin: PropTypes.bool,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
