import { Formik, Form } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import forEach from "lodash/forEach";
import { PropTypes } from "prop-types";
import findIndex from "lodash/findIndex";
import { trackEventSegment } from "../utils/segmentUtils";
import { handle500, handleUnknown } from "../utils/errorHandles";
import { updateSection, createSection } from "../api/mutations";
import SwitchInput from "./SwitchInput";
import Loader from "./Loader";
import InputDiv from "./InputDiv";
import Button from "./Button";
import ReactSwal from "../utils/swalUtils";

const FormHeading = styled.span`
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 0.6em;
  display: block;
`;

const FormSubheading = styled.span`
  font-weight: 500;
  font-size: 0.9em;
  display: block;
  margin-bottom: 2em;
`;

const SectionForm = ({ section, menuId, numOfSections, place }) => {
  const cache = useQueryClient();

  const { mutate: mutateUpdate, isLoading: isUpdating } = useMutation(updateSection, {
    onMutate: (newSectData) => {
      const newSect = newSectData.values;
      newSect.dishes = section.dishes;
      newSect.uuid = section.uuid;
      const newSectId = newSectData.sectionId;
      cache.cancelQueries(["placeDetail", { place }]);

      const previousPlace = cache.getQueryData(["placeDetail", { place }]);
      const newPlace = { ...previousPlace };
      const menuIndex = findIndex(newPlace.menus, ["uuid", menuId]);
      const newSectIndex = findIndex(newPlace.menus[menuIndex].menu_categories, ["uuid", newSectId]);
      newPlace.menus[menuIndex].menu_categories[newSectIndex] = newSect;
      cache.setQueryData(["placeDetail", { place }], newPlace);
      return () => cache.setQueryData(["placeDetail", { place }], previousPlace);
    },
  });

  const { mutate: mutateCreate, isLoading: isCreating } = useMutation(createSection, {
    onSuccess: (res) => {
      const newSect = res.data;
      const previousPlace = cache.getQueryData(["placeDetail", { place }]);
      const newPlace = { ...previousPlace };
      const menuIndex = findIndex(newPlace.menus, ["uuid", menuId]);
      newPlace.menus[menuIndex].menu_categories = newPlace.menus[menuIndex].menu_categories.concat(newSect);
      cache.setQueryData(["placeDetail", { place }], newPlace);
      trackEventSegment("Added Subcategory", {
        name: newSect.name,
        uuid: newSect.uuid,
      });
      ReactSwal.fire({
        title: "Section successfully created",
        icon: "success",
        text: `Section ${newSect.name} has been created!`,
        timer: 2000,
      });
    },
  });

  const sectionUpdate = async (values, bag) => {
    await mutateUpdate(
      {
        values: {
          menu: menuId,
          name: values.name,
          description: values.description,
          display: values.displayWithImages ? "GRID_WITH_IMAGES" : "BASIC",
        },
        sectionId: section.uuid,
      },
      {
        onSuccess: (data) => {
          trackEventSegment("Added Subcategory", {
            name: data.data.name,
            uuid: data.data.uuid,
          });
          ReactSwal.fire({
            title: "Section successfully updated",
            icon: "success",
            text: `Section ${data.data.name} has been updated!`,
          });
        },
        onSettled: () => {
          cache.invalidateQueries(["placeDetail", { place }]);
        },
        onError: (error, newSect, rollback) => {
          rollback();
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
        },
      }
    );
    bag.setSubmitting(false);
  };

  const sectionCreate = async (values, bag) => {
    await mutateCreate(
      {
        values: {
          menu: menuId,
          order: numOfSections + 1,
          name: values.name,
          description: values.description,
          display: values.displayWithImages ? "GRID_WITH_IMAGES" : "BASIC",
          dishes: [],
        },
      },
      {
        onError: (error, newSect) => {
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
        },
      }
    );
    bag.setSubmitting(false);
  };

  const schema = Yup.object({
    name: Yup.string().required("This field is required."),
  });

  const initialValues = {
    name: section?.name,
    description: section?.description,
    displayWithImages: section ? section.display === "GRID_WITH_IMAGES" : true,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={section ? sectionUpdate : sectionCreate} validationSchema={schema}>
      {(formik) =>
        formik.isSubmitting || isUpdating || isCreating ? (
          <Loader />
        ) : (
          <>
            <FormHeading>{section ? "Edit" : "Add"} menu section</FormHeading>
            <FormSubheading>
              You can drag and drop the items to change their order, but only after you’re finished editing.
            </FormSubheading>
            <Form>
              <InputDiv text="Section name" placeholder="For example desserts" name="name" hasLabel />
              <InputDiv
                text="Section description (optional)"
                placeholder="For example available all day"
                name="description"
                hasLabel
                type="textarea"
              />
              <SwitchInput
                toggleChange={() => formik.setFieldValue("displayWithImages", !formik.values.displayWithImages)}
                defaultChecked={formik.values.displayWithImages}
                label="Display menu section with pictures"
                helpText={
                  "If you don't have photos of your menu items, unselect this box and your menu items will display as text"
                }
              />
              <Button
                text={`${section ? "Edit" : "Create"} menu section`}
                hasMargin={false}
                type="submit"
                submitting={formik.isSubmitting}
              />
            </Form>
          </>
        )
      }
    </Formik>
  );
};

SectionForm.defaultProps = {
  section: null,
};

SectionForm.propTypes = {
  menuId: PropTypes.string.isRequired,
  numOfSections: PropTypes.number.isRequired,
  place: PropTypes.string.isRequired,
  section: PropTypes.object,
};

export default SectionForm;
