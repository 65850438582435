import { PropTypes } from "prop-types";
import styled from "styled-components";
import includes from "lodash/includes";
import { up } from "styled-breakpoints";
import SelectOption from "./SelectOption";
import InputLabel from "./InputLabel";

const SelectFieldWrap = styled.div`
  margin-top: 1.5em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  flex-direction: column;

  ${up("md")} {
    margin-bottom: 1.5em;
  }

  ${up("lg")} {
    flex-direction: row;
    margin-bottom: 2em;
  }

  ${up("xl")} {
    margin-bottom: 2.5em;
  }

  ${up("xxl")} {
    flex-direction: row;
    margin-bottom: 3em;
  }
`;

const SelectField = (props) => {
  const enableOption = (option) => {
    const choices = props.selectedChoices;
    choices.push(option);
    props.handleSelectedChoices(choices);
  };

  const disableOption = (option) => {
    const choices = props.selectedChoices;
    choices.pop(option);
    props.handleSelectedChoices(choices);
  };

  return (
    <div style={{ marginTop: "2em" }}>
      <InputLabel text={props.label} />
      <SelectFieldWrap>
        {props.choices.map((item, index) => (
          <SelectOption
            name={item}
            key={index}
            active={includes(props.selectedChoices, item)}
            enableOption={() => enableOption(item)}
            disableOption={() => disableOption(item)}
          />
        ))}
      </SelectFieldWrap>
    </div>
  );
};

SelectField.propTypes = {
  choices: PropTypes.array.isRequired,
  selectedChoices: PropTypes.array.isRequired,
  handleSelectedChoices: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default SelectField;
