import { useState } from "react";
import { Field, useFormikContext } from "formik";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import InputError from "./InputError";
import HelpText from "./HelpText";
import { brandRed } from "../constants/colours";
import ReactSwal from "../utils/swalUtils";

const FileUploadWrap = styled.div`
  input[type="file"] {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 1em;
`;

const FileUploadTitle = styled.div`
  font-family: Gordita;
  margin-bottom: 0.5em;
  font-weight: 500;
`;

const FileUploadLabel = styled.label`
  font-family: Gordita;
  display: block;
  border: 2px dashed #ccc;
  display: inline-block;
  padding: 1em 4em;
  cursor: pointer;
  font-weight: 500;
  width: 40%;
  text-align: center;
  word-break: break-word;
`;

const PreviewImg = styled.img`
  width: 50%;
  height: auto;
  max-width: 50%;
`;

const ClearButton = styled.button`
  width: 50%;
  font-weight: 500;
  padding: 0.2em;
  background: ${brandRed};
  border-radius: 5px;
  display: inline;
  border: 0;
  color: white;
  margin: 0.5em 0;
`;

const Here = styled.span`
  border-bottom: dimgrey 1px dashed;
  cursor: pointer;
`;

const HereAnchor = styled.a`
  border-bottom: dimgrey 1px dashed;
  text-decoration: none;
  color: dimgrey;
`;

function FileUploadInput(props) {
  const [fileName, setFileName] = useState("");
  const { setFieldValue } = useFormikContext();

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.currentTarget.files[0];
    setFileName(file.name);
    props.handleFileInput(file, props.name);
    props.setPreview(URL.createObjectURL(file));
  };

  const clearField = () => {
    setFieldValue(props.name, "");
    props.setPreview(null);
  };

  const exampleImage = () => {
    return <img alt="Example dish" style={{ width: "100%" }} src={props.exampleFile} />;
  };
  const examplePreview = () => {
    ReactSwal.fire({
      html: exampleImage(),
      padding: "4em 0",
      text: "Pictur",
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const hereEl = () => {
    if (props.previewNewTab) {
      return (
        <HereAnchor target="_blank" href={props.exampleFile}>
          here
        </HereAnchor>
      );
    }
    return <Here onClick={() => examplePreview()}>here</Here>;
  };

  return (
    <FileUploadWrap>
      <FileUploadTitle>{props.text}</FileUploadTitle>
      <HelpText>
        {props.helpText} {props.exampleFile && <>click {hereEl()}.</>}
      </HelpText>
      {props.preview && (
        <>
          <PreviewImg src={props.preview} />
          <ClearButton type="button" onClick={() => clearField()}>
            Clear
          </ClearButton>
        </>
      )}
      <FileUploadLabel htmlFor={props.name}>
        {fileName || (props.preview ? "Change image" : "Upload image")}
      </FileUploadLabel>
      <Field
        id={props.name}
        name={props.name}
        type="file"
        render={() => {
          return (
            <input
              name={props.name}
              id={props.name}
              type="file"
              onChange={(event) => {
                handleFileChange(event);
              }}
            />
          );
        }}
      />
      <InputError name={props.name} />
    </FileUploadWrap>
  );
}

FileUploadInput.defaultProps = {
  previewNewTab: false,
};

FileUploadInput.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
  exampleFile: PropTypes.any,
  handleFileInput: PropTypes.func.isRequired,
  preview: PropTypes.string,
  removePreview: PropTypes.func,
  previewNewTab: PropTypes.bool,
};

export default FileUploadInput;
