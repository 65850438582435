import { Box, Card, Heading, Layer, Text, Button, ResponsiveContext } from "grommet";
import React, { useState, useContext } from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { faList, faPlus, faWindowClose } from "pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "pro-regular-svg-icons";
import { useParams } from "react-router-dom";
import { brandRed } from "../constants/colours";
import MenuItemExisting from "./MenuItemExisting";
import MenuItemForm from "./MenuItemForm";

const NewDishItemEl = styled.div`
  border: 1px solid ${brandRed};
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 22.5em;
`;

const NewDishText = styled.span`
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 1em;
`;

const MenuItemAdd = ({ sectionId, menuId, numOfDishes, hasImageField }) => {
  const [itemType, setItemType] = useState(null);
  const [visible, setVisible] = useState(false);
  const size = useContext(ResponsiveContext);
  const { place } = useParams();

  const isSmall = size === "small" || size === "xsmall";

  const closePopup = () => {
    setItemType(null);
    setVisible(false);
  };

  const backButton = (
    <Box margin={{ bottom: "small" }} height={{ min: "auto" }}>
      <Button
        alignSelf="start"
        plain
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        onClick={() => setItemType(null)}
      />
    </Box>
  );

  const updateItemType = (type) => {
    setItemType(type);
  };

  let formContentInner = (
    <>
      <Heading level={2} alignSelf="center">
        What kind of menu item would you like to add?
      </Heading>
      <Box direction={isSmall ? "column" : "row"} justify="between" gap="medium">
        <Card gap="medium" align="center" flex pad={{ vertical: "xlarge" }} onClick={() => updateItemType("new")}>
          <Text weight={500}>New menu item</Text>
          <FontAwesomeIcon size="2x" icon={faPlus} />
        </Card>
        <Card
          gap="medium"
          align="center"
          direction="column"
          flex
          pad={{ vertical: "xlarge" }}
          onClick={() => updateItemType("existing")}
        >
          <Text weight={500}>Existing menu item</Text>
          <FontAwesomeIcon size="2x" icon={faList} />
        </Card>
      </Box>
    </>
  );

  if (itemType === "new") {
    formContentInner = (
      <>
        {backButton}
        <MenuItemForm
          sectionId={sectionId}
          menuId={menuId}
          numOfDishes={numOfDishes}
          hasImageField={hasImageField}
          place={place}
          embedded
          closePopup={closePopup}
        />
      </>
    );
  }

  if (itemType === "existing") {
    formContentInner = (
      <>
        {backButton}
        <MenuItemExisting closePopup={closePopup} numOfDishes={numOfDishes} menuId={menuId} sectionId={sectionId} />
      </>
    );
  }

  const formContent = (
    <Layer
      position="center"
      onEsc={() => closePopup()}
      onClickOutside={() => closePopup()}
      round
      full={size === "small" || size === "xsmall"}
    >
      <Box
        background="white"
        border={{ color: "brand", size: "small" }}
        round="small"
        width="xlarge"
        fill={size === "small" || size === "xsmall"}
        overflow={{ vertical: "auto" }}
      >
        <Box align="end" pad={{ right: "medium", top: "medium" }}>
          <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => closePopup()} />
        </Box>
        <Box pad={{ bottom: "large", left: "large", right: "large", top: "medium" }}>{formContentInner}</Box>
      </Box>
    </Layer>
  );

  return (
    <>
      <NewDishItemEl onClick={() => setVisible(true)}>
        <FontAwesomeIcon icon={faPlus} />
        <NewDishText>Add Menu Item</NewDishText>
      </NewDishItemEl>
      {visible && formContent}
    </>
  );
};

MenuItemAdd.propTypes = {
  sectionId: PropTypes.string.isRequired,
  menuId: PropTypes.string.isRequired,
  numOfDishes: PropTypes.number.isRequired,
};

export default MenuItemAdd;
