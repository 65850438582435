import { Text, Anchor, Box, Accordion, AccordionPanel, ResponsiveContext } from "grommet";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "pro-regular-svg-icons";
import { useContext } from "react";

const DashboardNavItem = ({ icon, text, to, children, place, perm, setUpgradePopup, pageTitle }) => {
  const hasPerm = !place || !perm || place.permissions[perm];

  const size = useContext(ResponsiveContext);

  const isSmallOrMed = size === "small" || size === "xsmall" || size === "medium";

  const label = (
    <Box direction="row" align="center">
      <Box width={isSmallOrMed ? "25px" : "30px"} margin={{ right: "xsmall" }}>
        <FontAwesomeIcon size={isSmallOrMed ? "sm" : "lg"} icon={icon} />
      </Box>
      <Text size={isSmallOrMed ? "small" : "medium"} color="black" weight={400}>
        {text}
      </Text>
    </Box>
  );

  const anchor = hasPerm ? (
    <Anchor color="black" as={Link} to={to} state={{ title: pageTitle }}>
      {label}
    </Anchor>
  ) : (
    <>
      <Anchor color="black" onClick={() => setUpgradePopup(true, text)}>
        <Box direction="row" justify="between">
          <Box direction="row" align="center" flex>
            <Box width={isSmallOrMed ? "25px" : "30px"} margin={{ right: "xsmall" }}>
              <FontAwesomeIcon size={isSmallOrMed ? "sm" : "lg"} icon={icon} />
            </Box>
            <Box direction="row" align="center" justify="between" flex="grow">
              <Text size={isSmallOrMed ? "small" : "medium"} color="black" weight={400}>
                {text}
              </Text>
              <Box pad={{ left: "small", right: "15px" }}>
                <FontAwesomeIcon icon={faLock} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Anchor>
    </>
  );

  if (children && hasPerm) {
    return (
      <Accordion plain>
        <AccordionPanel plain label={label}>
          <Box
            pad="small"
            gap="medium"
            border={{ side: "left", size: "small" }}
            margin={{ left: "medium", top: "small" }}
          >
            {children}
          </Box>
        </AccordionPanel>
      </Accordion>
    );
  }
  return anchor;
};

DashboardNavItem.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
};

export default DashboardNavItem;
