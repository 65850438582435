import React from "react";
import { Grommet, Box, Main, Image, Heading, Text } from "grommet";
import { theme } from "../constants/theme";
import logo from "../assets/images/logo.png";

const ErrorPage = () => {
  return (
    <Grommet theme={theme} full>
      <Main pad="large">
        <Box width="small" height="xsmall" pad={{ bottom: "small" }}>
          <Image src={logo} fit="contain" />
        </Box>
        <Box align="center" justify="center" alignSelf="center">
          <Heading size="medium" level={1}>
            Something went wrong
          </Heading>
          <Text margin={{ bottom: "xsmall" }}>Sorry, something went wrong loading this page.</Text>
          <Text>Please refresh the page and try again.</Text>
        </Box>
      </Main>
    </Grommet>
  );
};

export default ErrorPage;
