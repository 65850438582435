import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider, QueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import has from "lodash/has";
import Dashboard from "./Dashboard";
import ENV from "../constants/envConstants";
import Login from "./Login";
import PlaceChoose from "./PlaceChoose";
import BusinessProfile from "./BusinessProfile";
import GuestRegister from "./GuestRegister";
import DashboardNotFound from "./DashboardNotFound";
import NotFound from "./NotFound";
import MenuBuilder from "./MenuBuilder";
import MenuContentEditor from "./MenuContentEditor";
import QRGenerator from "./QRGenerator";
import PDFMenu from "./PDFMenu";
import Feedback from "./Feedback";
import QRAnalytics from "./QRAnalytics";
import MenuItems from "./MenuItems";
import MenuSettings from "./MenuSettings";
import Settings from "./Settings";
import Billing from "./Billing";
import LeadData from "./LeadData";
import PersonalSettings from "./PersonalSettings";
import Offers from "./Offers";
import OfferPreferences from "./OfferPreferences";
import Logout from "./Logout";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordConfirm from "./ForgotPasswordConfirm";
import Toast from "./Toast";
import { configureSentry } from "../utils/sentryUtils";
import CompleteAccount from "./CompleteAccount";
import ErrorPage from "./ErrorPage";
import SEOSettings from "./SEOSettings";
import VisualMenuAnalytics from "./VisualMenuAnalytics";
import DishAnalytics from "./DishAnalytics";
import GuestRegistrationSettings from "./GuestRegistrationSettings";

if (!ENV.IS_DEVELOPMENT) {
  configureSentry();
}

const queryClient = new QueryClient();

const RouteList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.title) {
      document.title = `Eatsee Dashboard - ${location.state.title}`;
    }
    window.analytics.page();
  }, [location]);

  const errorHandle = (error) => {
    const isCancelledError = error ? error.constructor.name === "CancelledError" : false;
    if (!isCancelledError) {
      if (has(error, "response")) {
        if (has(error.response, "status")) {
          const { status } = error.response;
          if (status === 401) {
            navigate("/login");
            Toast.fire({
              title: "You are not logged in.",
              icon: "error",
            });
          } else if (status === 403) {
            if (error?.response?.data?.errors[0]?.message === "Authentication credentials were not provided.") {
              navigate("/login");
              if (location.pathname !== "/") {
                Toast.fire({
                  title: "You are not logged in.",
                  icon: "error",
                });
              }
            } else {
              navigate(-1);
              Toast.fire({
                title: "Access to that resource is forbidden.",
                icon: "error",
              });
            }
          }
        } else {
          return <ErrorPage />;
        }
      } else {
        return <ErrorPage />;
      }
    }
  };

  queryClient.setDefaultOptions({
    queries: {
      useErrorBounaries: true,
      staleTime: 10000,
      onError: errorHandle,
    },
    mutations: {
      onError: errorHandle,
    },
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {ENV.IS_DEVELOPMENT && <ReactQueryDevtools initialIsOpen={false} />}
        <Helmet>
          {location.state ? <title>Eatsee Dashboard - {location.state.title} </title> : <title>Eatsee Dashboard</title>}
        </Helmet>
        <Routes>
          <Route path="/" element={<PlaceChoose />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="forgot-password-confirm/:uid/:token" element={<ForgotPasswordConfirm />} />
          <Route path="logout" element={<Logout />} />
          <Route path=":place" element={<Dashboard />}>
            <Route path="menu-editor" element={<MenuBuilder />}>
              <Route path="builder" element={<MenuContentEditor />} />
              <Route path="items" element={<MenuItems />} />
              <Route path="pdf" element={<PDFMenu />} />
            </Route>
            <Route path="guest-register" element={<GuestRegister />} />
            <Route path="account-setup" element={<CompleteAccount />} />
            <Route path="qr-generator" element={<QRGenerator />} />
            <Route path="customer-feedback" element={<Feedback />} perm="private_feedback" />
            <Route path="analytics" perm="analytics">
              <Route path="qrcode" element={<QRAnalytics />} perm="analytics" />
              <Route path="menu" perm="analytics">
                <Route path="" element={<VisualMenuAnalytics />} perm="analytics" />
                <Route path="dishes" element={<DishAnalytics />} perm="analytics" />
              </Route>
            </Route>
            <Route path="leads" perm="lead_gen">
              <Route path="data" element={<LeadData />} perm="lead_gen" />
              <Route path="offers" element={<Offers />} perm="lead_gen" />
            </Route>
            <Route path="settings" element={<Settings />}>
              <Route path="business" element={<BusinessProfile />} />
              <Route path="menu" element={<MenuSettings />} />
              <Route path="guest-registration" element={<GuestRegistrationSettings />} />
              <Route path="offer" element={<OfferPreferences />} perm="lead_gen" />
              <Route path="seo" element={<SEOSettings />} />
              <Route path="billing" element={<Billing />} />
              <Route path="personal" element={<PersonalSettings />} />
            </Route>
            <Route path="*" element={<DashboardNotFound />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </QueryClientProvider>
    </>
  );
};

function App() {
  return (
    <Router>
      <RouteList />
    </Router>
  );
}

export default App;
