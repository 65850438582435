import { Anchor, ResponsiveContext } from "grommet";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import Emoji from "a11y-react-emoji";
import { useContext } from "react";

const DashboardNavItemChild = ({ emoji, text, to, pageTitle }) => {
  const size = useContext(ResponsiveContext);

  const isSmallOrMed = size === "small" || size === "xsmall" || size === "medium";

  return (
    <Anchor color="black" size={isSmallOrMed ? "small" : "medium"} as={Link} to={to} state={{ title: pageTitle }}>
      {emoji && <Emoji symbol={emoji} />} {text}
    </Anchor>
  );
};

DashboardNavItemChild.defaultProps = {
  emoji: null,
};

DashboardNavItemChild.propTypes = {
  emoji: PropTypes.string,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default DashboardNavItemChild;
