import React, { useState } from "react";
import findIndex from "lodash/findIndex";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { PropTypes } from "prop-types";
import { Box, Button, Card, Heading, Layer, Paragraph, Text } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faChevronLeft } from "pro-regular-svg-icons";
import { faEyeSlash, faWindowClose } from "pro-solid-svg-icons";
import { removeDish, deleteDish } from "../api/mutations";
import { trackEventSegment } from "../utils/segmentUtils";
import Loader from "./Loader";

const DishItemRemove = ({ dish, closePopup, sectionId, menuId, sectionDishId }) => {
  const { place } = useParams();

  const [deleteType, setDeleteType] = useState(null);

  const [loading, setLoading] = useState(false);

  const cache = useQueryClient();

  const onMutate = (data) => {
    if (menuId != null) {
      const { dishId } = data;
      cache.cancelQueries(["placeDetail", { place }]);
      const previousPlace = cache.getQueryData(["placeDetail", { place }]);

      const newPlace = { ...previousPlace };
      const menuIndex = findIndex(newPlace.menus, ["uuid", menuId]);
      const sectIndex = findIndex(newPlace.menus[menuIndex].menu_categories, ["uuid", sectionId]);
      const dishIndex = findIndex(newPlace.menus[menuIndex].menu_categories[sectIndex].dishes, ["uuid", dishId]);
      newPlace.menus[menuIndex].menu_categories[sectIndex].dishes.splice(dishIndex, 1);
      cache.setQueryData(["placeDetail", { place }], newPlace);
      return () => cache.setQueryData(["placeDetail", { place }], previousPlace);
    }
  };

  const { mutate: removeMutate } = useMutation(removeDish, { onMutate });
  const { mutate: deleteMutate } = useMutation(deleteDish, { onMutate });

  const backButton = (
    <Box margin={{ bottom: "small" }}>
      <Button
        alignSelf="start"
        plain
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        onClick={() => setDeleteType(null)}
      />
    </Box>
  );

  const processDeleteDish = async () => {
    setLoading(true);
    await deleteMutate(
      { dishId: dish.uuid },
      {
        onError: (resError) => {
          console.log("error", resError);
        },
        onSuccess: () => {
          setLoading(false);
          trackEventSegment("Removed Dish", {
            uuid: dish.uuid,
            name: dish.name,
          });
          closePopup();
        },
      },
    );
  };

  const processRemoveDish = async () => {
    setLoading(true);
    await removeMutate(
      { dishId: sectionDishId },
      {
        onError: (resError) => {
          console.log("error", resError);
        },
        onSuccess: () => {
          setLoading(false);
          trackEventSegment("Removed Dish", {
            uuid: dish.uuid,
            name: dish.name,
          });
          closePopup();
        },
      },
    );
  };

  let formContentInner = (
    <>
      <Heading fill level={2} alignSelf="center">
        Permanently delete or remove from section?
      </Heading>
      <Box direction="row" justify="between" gap="medium">
        <Card gap="medium" align="center" flex pad={{ vertical: "xlarge" }} onClick={() => setDeleteType("delete")}>
          <Text weight={500}>Permanently delete</Text>
          <FontAwesomeIcon size="2x" icon={faTrashAlt} />
        </Card>
        <Card
          gap="medium"
          align="center"
          direction="column"
          flex
          pad={{ vertical: "xlarge" }}
          onClick={() => setDeleteType("remove")}
        >
          <Text weight={500}>Remove from section</Text>
          <FontAwesomeIcon size="2x" icon={faEyeSlash} />
        </Card>
      </Box>
    </>
  );

  if (deleteType === "remove") {
    formContentInner = (
      <>
        {backButton}
        <Heading fill level={2} alignSelf="center">
          Are you sure you want to remove this menu item?
        </Heading>
        <Paragraph fill>
          Removing this menu item from the section will not permanently delete it. The menu item can be added back to
          any section at any time.
        </Paragraph>
        <Box direction="row" basis="1/2" width="1/2" gap="large" justify="center">
          <Button primary label="Yes, remove from section" onClick={() => processRemoveDish()} />
          <Button secondary label="No, cancel" onClick={() => closePopup()} />
        </Box>
      </>
    );
  }

  if (deleteType === "delete") {
    formContentInner = (
      <>
        {backButton}
        <Heading fill level={2} alignSelf="center">
          Are you sure you want to permanently delete this menu item?
        </Heading>
        <Paragraph fill>
          Permanently deleting this menu item is an irreversible action. Analytics associated with the dish will no
          longer be visible.
        </Paragraph>
        <Box direction="row" basis="1/2" width="1/2" gap="large" justify="center">
          <Button primary label="Yes, permanently delete" onClick={() => processDeleteDish()} />
          <Button secondary label="No, cancel" onClick={() => closePopup()} />
        </Box>
      </>
    );
  }

  const formContent = (
    <Layer position="center" onEsc={() => closePopup()} onClickOutside={() => closePopup()} round>
      <Box background="white" border={{ color: "brand", size: "small" }} round="small" width="xlarge">
        <Box align="end" pad={{ right: "medium", top: "medium" }}>
          <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => closePopup()} />
        </Box>
        <Box pad={{ bottom: "large", left: "large", right: "large", top: "medium" }}>
          {loading ? <Loader /> : formContentInner}
        </Box>
      </Box>
    </Layer>
  );

  return formContent;
};

DishItemRemove.defaultProps = {
  menuId: null,
};

DishItemRemove.propTypes = {
  dish: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired,
  menuId: PropTypes.string,
  sectionId: PropTypes.string.isRequired,
  sectionDishId: PropTypes.string.isRequired,
};

export default DishItemRemove;
