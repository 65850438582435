import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Button, Layer } from "grommet";
import { faCreditCard, faExchange } from "pro-regular-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import { usePlace, usePrimaryPaymentSource } from "../api/queries";
import Loader from "./Loader";
import ENV from "../constants/envConstants";
import UpgradePopup from "./UpgradePopup";
import PaymentMethodLogo from "./PaymentMethodLogo";

const BillingGeneral = () => {
  const { place } = useParams();

  const { data: placeData } = usePlace(place);
  const { data: primaryPaymentMethod } = usePrimaryPaymentSource(place);

  const [upgradeVisible, setUpgradeVisible] = useState(false);

  const cbInstance = window.Chargebee.getInstance();

  const toggleUpgrade = (state) => {
    setUpgradeVisible(state);
  };

  const paymentMethodUpdate = () => {
    cbInstance.setPortalSession(() => {
      return axios
        .post(`${ENV.BACKEND_URL}/chargebee/`, {
          customerId: place,
        })
        .then((response) => response.data);
    });
    const cbPortal = cbInstance.createChargebeePortal();
    cbPortal.openSection({
      sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES,
    });
  };

  if (!placeData || !primaryPaymentMethod) {
    return <Loader />;
  }

  return (
    <Card>
      <Box direction="row" pad="medium" border="bottom">
        <Box basis="1/3">Plan</Box>
        <Box basis="1/3" pad={{ left: "large" }}>
          {placeData.current_plan}
        </Box>
        <Box basis="1/3" align="end">
          <Button
            plain
            label="Change Plan"
            reverse
            icon={<FontAwesomeIcon icon={faExchange} size="1x" />}
            onClick={() => setUpgradeVisible(true)}
          />
          {upgradeVisible && (
            <Layer
              position="center"
              animation="fadeIn"
              onClickOutside={() => setUpgradeVisible(false)}
              onEsc={() => setUpgradeVisible(false)}
            >
              <UpgradePopup togglePopup={toggleUpgrade} title="Change plan" />
            </Layer>
          )}
        </Box>
      </Box>
      <Box direction="row" pad="medium">
        <Box basis="1/3">Payment Method</Box>
        <Box basis="1/3" pad={{ left: "large" }} direction="row" gap="small" align="center">
          {primaryPaymentMethod.method_text}
          <PaymentMethodLogo method={primaryPaymentMethod.method_type} />
        </Box>
        <Box basis="1/3" align="end">
          <Button
            reverse
            plain
            icon={<FontAwesomeIcon icon={faCreditCard} size="1x" />}
            onClick={() => paymentMethodUpdate()}
            label="Update"
          />
        </Box>
      </Box>
    </Card>
  );
};

export default BillingGeneral;
