import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcPaypal,
  faAlipay,
  faWeixin,
  faIdeal,
  faGooglePay,
  faCcDiscover,
  faCcDinersClub,
  faCcAmazonPay,
  faCcJcb,
  faCcApplePay,
} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import { faMoneyCheck, faCreditCardFront } from "pro-regular-svg-icons";

const methodDict = {
  visa: faCcVisa,
  mastercard: faCcMastercard,
  american_express: faCcAmex,
  jcb: faCcJcb,
  diners_club: faCcDinersClub,
  discover: faCcDiscover,
  other: faCreditCardFront,
  paypal_express_checkout: faCcPaypal,
  amazon_payments: faCcAmazonPay,
  direct_debit: faMoneyCheck,
  generic: faMoneyCheck,
  alipay: faAlipay,
  unionpay: faMoneyCheck,
  apple_pay: faCcApplePay,
  wechat_pay: faWeixin,
  ideal: faIdeal,
  google_pay: faGooglePay,
  sofort: faMoneyCheck,
  bancontact: faMoneyCheck,
  giropay: faMoneyCheck,
  dotpay: faMoneyCheck,
};

const PaymentMethodLogo = ({ method }) => {
  return <FontAwesomeIcon icon={methodDict[method]} size="lg" />;
};

export default PaymentMethodLogo;
