import { useState, lazy, Suspense } from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import Button from "./Button";
import Loader from "./Loader";
import {pdfjs} from "react-pdf";

const Page = lazy(() => import("./ReactPDFPage"));
const Document = lazy(() => import("./ReactPDFDocument"));
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const PrevWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  position: relative;
`;

const ButtonPosition = styled.div`
  position: absolute;
  width: 50%;
  left: 25%;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  top: 50%;
`;

const PDFPreview = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { setFieldValue } = useFormikContext();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const clearPdf = () => {
    setFieldValue("pdfUpload", null);
    props.clearOption();
  };

  return (
    <PrevWrap>
      <Suspense fallback={<Loader />}>
        <Document loading={<Loader />} file={props.url} onLoadSuccess={onDocumentLoadSuccess}>
          <div>
            <p>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </p>
          </div>
          <ButtonPosition>
            <FontAwesomeIcon
              icon={faChevronLeft}
              size="2x"
              color={pageNumber <= 1 ? "grey" : "black"}
              onClick={pageNumber <= 1 ? null : previousPage}
              text="Prev Page"
            />
            <FontAwesomeIcon
              icon={faChevronRight}
              size="2x"
              color={pageNumber >= numPages ? "grey" : "black"}
              onClick={pageNumber >= numPages ? null : nextPage}
            />
          </ButtonPosition>
          <Page pageNumber={pageNumber} width={300} />
          <Button type="button" text="Clear" inversed width="50%" onClick={() => clearPdf()} />
        </Document>
      </Suspense>
    </PrevWrap>
  );
};

PDFPreview.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PDFPreview;
