import { PropTypes } from "prop-types";
import { Field } from "formik";
import styled from "styled-components";
import InputWrap from "./InputWrap";

const Prefix = styled.span`
  align-self: center;
  margin-right: 0.25em;
`;

function InputField(props) {
  let field;
  if (props.type === "textarea") {
    field = (
      <Field
        component="textarea"
        rows={6}
        name={props.name}
        placeholder={props.placeholder}
        aria-label={props.ariaLabel ? props.text : null}
        aria-labelledby={props.ariaLabel ? null : props.name}
      />
    );
  } else {
    field = (
      <Field
        type={props.type}
        name={props.name}
        onClick={props.onClick ? () => props.onClick() : null}
        placeholder={props.placeholder}
        aria-label={props.ariaLabel ? props.text : null}
        aria-labelledby={props.ariaLabel ? null : props.name}
      />
    );
  }
  return (
    <InputWrap hasBackground={props.hasBackground} hasBorder={props.hasBorder} isRounded={props.isRounded}>
      {props.inputPrefix && <Prefix>{props.inputPrefix}</Prefix>}
      {field}
    </InputWrap>
  );
}

InputField.defaultProps = {
  type: "text",
  hasBackground: true,
  isRounded: false,
  hasBorder: false,
};

InputField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  hasBackground: PropTypes.bool,
  hasBorder: PropTypes.bool,
  isRounded: PropTypes.bool,
  inputPrefix: PropTypes.string,
  onClick: PropTypes.func,
};

export default InputField;
