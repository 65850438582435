import styled from "styled-components";
import { up } from "styled-breakpoints";

const Error = styled.span`
  color: red;
  margin-top: 0.5em;
  font-family: Gordita;
  display: block;
  font-size: 0.85em;

  ${up("md")} {
    font-size: 0.9em;
  }

  ${up("lg")} {
    font-size: 0.95em;
  }

  ${up("xxl")} {
    font-size: 1em;
  }
`;

export default Error;
