import { useState } from "react";
import { Field, useFormikContext } from "formik";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import InputError from "./InputError";

const FileUploadWrap = styled.div`
  input[type="file"] {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 1em;
`;

const FileUploadLabel = styled.label`
  font-family: Gordita;
  display: block;
  border: 2px dashed #ccc;
  display: inline-block;
  padding: 4em 0;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  background: #f4f4f4;
  text-align: center;
`;

function PDFMenuUpload(props) {
  const { setFieldValue, submitForm } = useFormikContext();
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    event.preventDefault();
    props.setLoading();
    submitForm();
    setFileName(event.currentTarget.files[0].name);
    setFieldValue(props.name, event.currentTarget.files[0]);
  };

  return (
    <FileUploadWrap>
      <FileUploadLabel htmlFor={props.name}>{fileName || "Browse files or\ndrag and drop to upload"}</FileUploadLabel>
      <Field
        id={props.name}
        name={props.name}
        type="file"
        render={() => {
          return (
            <input
              name={props.name}
              id={props.name}
              type="file"
              onChange={(event) => {
                handleFileChange(event);
              }}
            />
          );
        }}
      />
      <InputError name={props.name} />
    </FileUploadWrap>
  );
}

PDFMenuUpload.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
};

export default PDFMenuUpload;
