import { Box, Card, Layer, Text, Heading, Paragraph, Button } from "grommet";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPencil, faWindowClose } from "pro-solid-svg-icons";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import findIndex from "lodash/findIndex";
import OfferForm from "./OfferForm";
import { trackEventSegment } from "../utils/segmentUtils";
import { removeOffer } from "../api/mutations";
import Loader from "./Loader";

const OfferItem = ({ offer, page }) => {
  const { place } = useParams();

  const [loading, setLoading] = useState(false);

  const [editActive, setEditActive] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);

  const hideAllPopups = () => {
    setEditActive(false);
    setDeleteActive(false);
  };

  const cache = useQueryClient();

  const onMutate = (data) => {
    cache.cancelQueries(["offerList", { place }]);
    const previousOffers = cache.getQueryData(["offerList", { place }, { page }]);
    const newOffers = { ...previousOffers };
    const offerIndex = findIndex(newOffers.results, ["uuid", offer.uuid]);
    newOffers.results.splice(offerIndex, 1);
    cache.setQueryData(["offerList", { place }, { page }], newOffers);
    return () => cache.setQueryData(["offerList", { place }, { page }], previousOffers);
  };

  const { mutate: deleteMutate } = useMutation(removeOffer, { onMutate });

  const processDeleteOffer = async () => {
    setLoading(true);
    await deleteMutate(
      { offerId: offer.uuid },
      {
        onError: (resError) => {
          console.log("error", resError);
        },
        onSuccess: () => {
          setLoading(false);
          trackEventSegment("Removed Offer", {
            uuid: offer.uuid,
            name: offer.title,
          });
          hideAllPopups();
        },
      }
    );
  };

  const offerEdit = (
    <Layer position="center" onEsc={() => hideAllPopups()} onClickOutside={() => hideAllPopups()} round>
      <Box background="white" border={{ color: "brand", size: "small" }} round="small" pad="large" width="xlarge">
        <OfferForm offer={offer} closeForm={hideAllPopups} />
      </Box>
    </Layer>
  );

  const offerDelete = (
    <Layer position="center" onEsc={() => hideAllPopups()} onClickOutside={() => hideAllPopups()} round>
      <Box background="white" border={{ color: "brand", size: "small" }} round="small" width="xlarge">
        <Box align="end" pad={{ right: "medium", top: "medium" }}>
          <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => hideAllPopups()} />
        </Box>
        <Box pad={{ bottom: "large", left: "large", right: "large", top: "medium" }}>
          <Heading fill level={2} alignSelf="center" margin={{ bottom: "small", top: "none" }}>
            Permanently delete this offer?
          </Heading>
          <Paragraph fill level={3} alignSelf="center" margin="none">
            {offer.title}
          </Paragraph>
          <Box
            direction="row"
            justify="between"
            width="50%"
            margin={{ horizontal: "auto", top: "xlarge", bottom: "xsmall" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                <Button label="Yes, delete now" primary basis="1/2" onClick={() => processDeleteOffer()} />
                <Button label="No, go back" secondary basis="1/2" onClick={() => hideAllPopups()} />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Layer>
  );

  return (
    <Card pad="medium" direction="row" justify="between">
      <Box>
        <Text size="large">{offer.title}</Text>
      </Box>
      <Box direction="row" gap="large" pad={{ right: "small" }}>
        <FontAwesomeIcon color="#919191" size="lg" icon={faPencil} onClick={() => setEditActive(true)} />
        <FontAwesomeIcon color="#919191" size="lg" icon={faTrashAlt} onClick={() => setDeleteActive(true)} />
      </Box>
      {editActive && offerEdit}
      {deleteActive && offerDelete}
    </Card>
  );
};

export default OfferItem;
