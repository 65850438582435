import { Box, Heading } from "grommet";
import { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PDFMenuUploadField from "./PDFMenuUploadField";
import { usePlace } from "../api/queries";
import { uploadPdfMenu } from "../api/mutations";
import Loader from "./Loader";
import PDFPreview from "./PDFPreview";
import { trackEventSegment } from "../utils/segmentUtils";

const ALLOWED_FILE_TYPES = ["application/pdf"];

function PDFMenu(props) {
  const { place } = useParams();

  const cache = useQueryClient();
  const { mutate, status } = useMutation(uploadPdfMenu);

  const [loaded, setLoaded] = useState(false);

  const [formData, setFormData] = useState({});

  const { data } = usePlace(place);

  useEffect(() => {
    if (loaded === false && data) {
      setFormData({
        pdfUpload: data.menu_pdf,
      });
      setLoaded(true);
    }
  }, [loaded, data]);

  const onSubmit = async (values, bag) => {
    setLoaded(false);
    const reader = new FileReader();
    reader.onloadend = async () => {
      await mutate(
        {
          placeId: place,
          values: {
            menu_pdf: reader.result,
          },
        },
        {
          onSuccess: (data) => {
            trackEventSegment("Uploaded PDF Menu", {
              pdfUrl: data.data.menu_pdf,
            });
          },
        }
      );
      bag.setSubmitting(false);
      setFormData({ pdfUpload: values.pdfUpload });
    };
    reader.readAsDataURL(values.pdfUpload);
  };

  const clearOption = () => {
    setFormData({});
    mutate(
      {
        placeId: place,
        values: {},
      },
      {
        onSuccess: (data) => {
          trackEventSegment("Cleared uploaded PDF");
        },
      }
    ).then(() => {
      cache.invalidateQueries(["placeDetail", { place }]);
    });
  };

  const schema = Yup.object({
    pdfUpload: Yup.mixed().test({
      name: "fileFormat",
      test: function test(value) {
        if (value) {
          if (!ALLOWED_FILE_TYPES.includes(value.type)) {
            return this.createError({
              path: "pdfUpload",
              message: `File format '${value.type}' is not supported.`,
            });
          }
        }
        return true;
      },
    }),
  });

  const handleLoading = () => {
    setLoaded(false);
  };

  return (
    <>
      <Box>
        <Heading level={2} size="small">
          PDF menu
        </Heading>
      </Box>
      <Formik onSubmit={onSubmit} initialValues={formData} validationSchema={schema}>
        {(formik) =>
          status === "loading" ? (
            <Loader />
          ) : (
            <Form>
              {formData.pdfUpload && <PDFPreview url={formData.pdfUpload} clearOption={clearOption} />}
              <PDFMenuUploadField name="pdfUpload" text="text" helpText="help text" setLoading={handleLoading} />
            </Form>
          )
        }
      </Formik>
    </>
  );
}

export default PDFMenu;
