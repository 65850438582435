import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import LoaderScreen from "./LoaderScreen";
import { logout } from "../api/mutations";
import Toast from "./Toast";
import { trackEventSegment } from "../utils/segmentUtils";

const Logout = () => {
  const navigate = useNavigate();

  const { mutate, isSuccess } = useMutation(logout);

  mutate();

  useEffect(() => {
    trackEventSegment("Logged out");
    window.analytics.reset();
    if (window.Intercom) {
      window.Intercom("shutdown");
    }
    navigate("/login");
    Toast.fire({
      title: "Logged out successfully",
      icon: "success",
    });
  }, [isSuccess]);

  return <LoaderScreen />;
};

export default Logout;
