import Toggle from "react-toggle";
import "react-toggle/style.css";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { Text } from "grommet";
import HelpText from "./HelpText";

const SwitchWrap = styled.div`
  display: flex;
  font-family: Gordita;
  margin-bottom: 2em;
  justify-content: space-between;

  .react-toggle-track {
    width: 58px;
    height: 30px;
  }

  .react-toggle-trackhover {
    background-color: inherit;
  }

  .react-toggle-thumb {
    border: 0;
    width: 28px;
    height: 28px;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #ff4256;
  }

  .react-toggle--checked .react-toggle-track {
    background: #ff4256;
  }
  .react-toggle--checked .react-toggle-thumb {
    left: 29px;
  }
`;

const SwitchText = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2em;
`;

const SwitchLabel = styled.label`
  font-size: ${(props) => (props.size === "large" ? "1.4em" : "1em")};
  font-weight: 500;
  margin-bottom: 0.5em;
`;

const SwitchInput = (props) => {
  return (
    <SwitchWrap>
      <SwitchText>
        <SwitchLabel size={props.size}>
          {props.label} {!props.hasPerm && "🔒"}
        </SwitchLabel>
        <HelpText>{props.helpText}</HelpText>
        {!props.hasPerm && <HelpText>This feature requires a premium plan.</HelpText>}
      </SwitchText>
      <Toggle
        icons={false}
        defaultChecked={props.defaultChecked}
        onChange={props.toggleChange}
        disabled={!props.hasPerm}
      />
    </SwitchWrap>
  );
};

SwitchInput.defaultProps = {
  hasPerm: true,
};

SwitchInput.propTypes = {
  toggleChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  defaultChecked: PropTypes.bool,
  hasPerm: PropTypes.bool,
};

export default SwitchInput;
