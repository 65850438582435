import styled from "styled-components";
import { up } from "styled-breakpoints";

const FieldWrap = styled.div`
  margin-bottom: 1em;
  width: 100%;

  ${up("md")} {
    margin-bottom: 1.25em;
  }

  ${up("lg")} {
  }

  ${up("xl")} {
    margin-bottom: 1.5em;
  }

  ${up("xxl")} {
    margin-bottom: 1.75em;
  }

  ${up("giant")} {
    margin-bottom: 2em;
  }
`;

export default FieldWrap;
