import { Twemoji } from "react-emoji-render";
import { Text } from "grommet";
import styled from "styled-components";
import { trackEventSegment } from "../utils/segmentUtils";

const UpgradeButtonEl = styled.button`
  background: white;
  padding: 1em 0;
  border: 0;
  border-radius: 12px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.16) !important;
`;

const UpgradeButton = ({ togglePopup }) => {
  const buttonClick = () => {
    trackEventSegment("Clicked upgrade plan button");
    togglePopup(true, "Upgrade plan");
  };
  return (
    <UpgradeButtonEl type="button" onClick={() => buttonClick()}>
      <Text weight={500}>
        <Twemoji text="Upgrade plan 🎉" />
      </Text>
    </UpgradeButtonEl>
  );
};

export default UpgradeButton;
