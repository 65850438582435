import { Formik, Form } from "formik";
import { Box, Heading, Button, Layer, ResponsiveContext } from "grommet";
import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { useQueryClient, useMutation } from "react-query";
import forEach from "lodash/forEach";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "pro-solid-svg-icons";
import ReactSwal from "../utils/swalUtils";
import { trackEventSegment } from "../utils/segmentUtils";
import Toast from "./Toast";
import Loader from "./Loader";
import InputDiv from "./InputDiv";
import { updateUser } from "../api/mutations";
import { useUser } from "../api/queries";

const UpdateProfilePopup = ({ closePopup }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";

  const { data } = useUser();

  const cache = useQueryClient();

  const schema = Yup.object({
    name: Yup.string().required("This field is required."),
    email: Yup.string().required("This field is required.").email("Please enter a valid email"),
  });

  const { mutate, isLoading } = useMutation(updateUser);

  const onSubmit = async (values, bag) => {
    const reqContent = { values };
    await mutate(reqContent, {
      onSuccess: (data, variables) => {
        cache.setQueryData(["userDetail"], data.data);
        trackEventSegment("Updated user profile");
        closePopup();
        Toast.fire({
          title: "Profile updated",
          icon: "success",
        });
      },
      onError: (error, variables) => {
        const { data } = error.response;
        switch (error.response.status) {
          case 400:
            if (data.error_type === "ValidationError") {
              forEach(data.errors, (error) => {
                bag.setFieldError(error.field, error.message);
              });
            }
            break;
          case 500:
            ReactSwal.fire({
              title: "Oops!",
              text:
                "It's not your fault! Our server has encountered an internal error. An adminstrator has been notified. Try refreshing your page in the interim.",
              width: "35rem",
            });
            break;
          default:
            ReactSwal.fire({
              title: "Oops!",
              text: "An unknown error has occured. Try refreshing your page in the interim.",
              width: "35rem",
            });
        }
      },
    });
  };

  useEffect(() => {
    if (data) {
      setFormData({ name: data.name, email: data.email });
    }
  }, [data]);

  if (!data) {
    return <Loader />;
  }

  return (
    <Layer position="center" onClickOutside={closePopup} onEsc={closePopup} full={isSmall}>
      <Box background="white" border={{ color: "brand", size: "small" }} round="small" width="xlarge" fill={isSmall}>
        <Box align="end" pad={{ right: "medium", top: "medium" }}>
          <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={() => closePopup()} />
        </Box>
        <Box pad={{ bottom: "large", left: "large", right: "large", top: "medium" }}>
          <Formik validationSchema={schema} initialValues={formData} onSubmit={onSubmit}>
            {(formik) =>
              formik.isSubmitting || isLoading ? (
                <Loader />
              ) : (
                <Form>
                  <Box flex direction="row" justify="between">
                    <Heading margin={{ bottom: "medium", top: "none" }} size="small">
                      Update profile
                    </Heading>
                  </Box>
                  <InputDiv type="text" text="Name" placeholder="Enter your name" name="name" hasLabel />
                  <InputDiv
                    text="Email address"
                    placeholder="Enter your email address"
                    name="email"
                    type="email"
                    hasLabel
                  />
                  <Button primary label="Update profile" type="submit" />
                </Form>
              )
            }
          </Formik>
        </Box>
      </Box>
    </Layer>
  );
};

export default UpdateProfilePopup;
