import { useMutation } from "react-query";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import forEach from "lodash/forEach";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { login } from "../api/mutations";
import InputDiv from "./InputDiv";
import Loader from "./Loader";
import Button from "./Button";
import { identifySegment, trackEventSegment } from "../utils/segmentUtils";
import Hyperlink from "./Hyperlink";
import AuthBase from "./AuthBase";
import { handle500, handleUnknown } from "../utils/errorHandles";
import Error from "./Error";
import { useCsrf } from "../api/queries";
import ENV from "../constants/envConstants";

const Login = () => {
  const { mutate: doLogin, isLoading } = useMutation(login);

  const initialData = {
    username: "",
    password: "",
  };

  const { data } = useCsrf();

  const navigate = useNavigate();

  const schema = Yup.object({
    username: Yup.string().required("Please enter your email.").email("Please enter a valid email."),
    password: Yup.string().required("Please enter your password."),
  });

  const onSubmit = async (values, bag) => {
    await doLogin(
      {
        values: {
          username: values.username,
          password: values.password,
        },
      },
      {
        onSuccess: (data) => {
          identifySegment(data.data.user.uuid, {
            name: values.firstName,
            email: values.username,
            id: data.data.user.uuid,
          });
          trackEventSegment("User signed in");
          navigate("/", { state: { title: "Select Venue" } });
        },
        onError: (error) => {
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  if (error.field) {
                    bag.setFieldError(error.field, error.message);
                  } else {
                    bag.setStatus(error.message);
                  }
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
        },
      },
    );
    bag.setSubmitting(false);
  };

  return (
    <AuthBase header="Dashboard login" subheader="Enter your details to log in to the eatsee dashboard">
      <Helmet>
        <title>Eatsee Dashboard - Login</title>
      </Helmet>
      <Formik initialValues={initialData} onSubmit={onSubmit} validationSchema={schema}>
        {(formik) =>
          isLoading ? (
            <Loader type="beat" size={20} />
          ) : (
            <Form>
              <Error>{formik.status}</Error>
              <div style={{ marginBottom: "1em" }} />
              <InputDiv text="Email address" name="username" />
              <InputDiv type="password" text="Password" name="password" />
              <Button type="submit" text="Log in" />
              <Hyperlink onClick={() => navigate("/forgot-password")} text="Forgotten password?" />
            </Form>
          )
        }
      </Formik>
    </AuthBase>
  );
};

export default Login;
