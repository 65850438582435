import React, { useEffect, useState, useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import forEach from "lodash/forEach";
import { useParams } from "react-router-dom";
import { Box, Heading, Select, Button, ResponsiveContext } from "grommet";
import Loader from "./Loader";
import InputDiv from "./InputDiv";
import SwitchInput from "./SwitchInput";
import { updatePlaceMenuSettings } from "../api/mutations";
import { trackEventSegment } from "../utils/segmentUtils";
import { handle500, handleUnknown } from "../utils/errorHandles";
import { usePlace, useMenuSettingsOptions } from "../api/queries";
import InputLabel from "./InputLabel";
import FieldGrid from "./FieldGrid";
import FieldGridItem from "./FieldGridItem";
import FileUploadInput from "./FileUploadInput";
import coverExample from "../assets/images/cover-example.jpg";
import logoExample from "../assets/images/logo-example.png";
import Toast from "./Toast";
import MenuReorderPopup from "./MenuReorderPopup";
import { getFileObj } from "../utils/fileUtils";
import { coverPhotoSchema, logoSchema } from "../utils/schemaUtils";

const MenuSettings = (props) => {
  const cache = useQueryClient();

  const { place } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [menuReorderVisible, setMenuReorderVisible] = useState(false);

  const [currencyOptions, setCurrencyOptions] = useState(null);

  const closeReorder = () => {
    setMenuReorderVisible(false);
  };

  const { mutate, isLoading } = useMutation(updatePlaceMenuSettings);
  const { status, data } = usePlace(place);
  const { data: settingsOptions } = useMenuSettingsOptions(place);
  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";

  const displayOptions = [
    { label: "PDF (Uploaded PDF file will be shown)", value: "PDF_MENU" },
    { label: "Visual Menu (Interactive visual menu will be shown)", value: "VISUAL_MENU" },
  ];

  const [logoPreview, setLogoPreview] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);

  const [formData, setFormData] = useState({
    display_setting: "Visual Menu",
    slug: "",
    cover_image: undefined,
    venue_logo: undefined,
    remove_eatsee_branding: null,
    logo_as_favicon: null,
    published: null,
    default_currency: "",
  });

  useEffect(() => {
    if (loaded === false && data && settingsOptions) {
      const loadedData = {
        display_setting: data.display_setting,
        slug: data.slug,
        published: data.published,
        remove_eatsee_branding: data.remove_eatsee_branding,
        logo_as_favicon: data.logo_as_favicon,
        default_currency: data.default_currency,
      };
      setFormData(loadedData);
      if (data.cover_image || data.venue_logo) {
        setLogoPreview(data.venue_logo);
        setCoverPreview(data.cover_image);
      }
      setCurrencyOptions(settingsOptions.actions.PUT.default_currency.choices);
      setLoaded(true);
    }
  }, [data, loaded, settingsOptions]);

  useEffect(() => {
    if (status === "idle") {
      setLoaded(true);
    }
  }, [status]);

  const schema = Yup.object({
    slug: Yup.string().required("This field is required.").lowercase("URL must be lowercase.").strict(),
    cover_image: coverPhotoSchema,
    venue_logo: logoSchema,
  });

  const onSubmit = async (values, bag) => {
    let reqBody = { ...values };
    if (values.cover_image !== undefined) {
      reqBody = {
        ...reqBody,
        cover_image: values.cover_image === "" ? null : await getFileObj(values.cover_image),
      };
    }
    if (values.venue_logo !== undefined) {
      reqBody = {
        ...reqBody,
        venue_logo: values.venue_logo === "" ? null : await getFileObj(values.venue_logo),
      };
    }
    await mutate(
      {
        placeId: place,
        values: reqBody,
      },
      {
        onSuccess: (data) => {
          trackEventSegment("Menu settings updated");
          Toast.fire({
            title: "Menu Settings updated",
            icon: "success",
          });
          bag.setSubmitting(false);
        },
        onSettled: () => {
          cache.invalidateQueries(["placeDetail", { place }]);
        },
        onError: (error, newSect, rollback) => {
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
          bag.setSubmitting(false);
        },
      }
    );
  };

  const setCoverPrev = (url) => {
    setCoverPreview(url);
  };

  const setLogoPrev = (url) => {
    setLogoPreview(url);
  };

  if (loaded === false) {
    return <Loader />;
  }

  return (
    <>
      <Formik initialValues={formData} onSubmit={onSubmit} validationSchema={schema}>
        {(formik) =>
          formik.isSubmitting || isLoading ? (
            <Loader />
          ) : (
            <Form>
              <Box flex direction="row" justify="between">
                <Box basis="3/4">
                  <Heading margin={{ bottom: "medium", top: "none" }} size="small">
                    Menu preferences
                  </Heading>
                </Box>
                <Box basis="1/4">
                  <Button primary type="submit" label={isSmall ? "Save" : "Save Changes"} />
                </Box>
              </Box>
              <Box margin={{ vertical: "medium" }}>
                <FieldGrid>
                  <FieldGridItem>
                    <InputLabel text="Menu Display" for="display_setting" />
                    <Select
                      options={displayOptions}
                      value={formik.values.display_setting}
                      labelKey="label"
                      onChange={({ value: nextValue }) => formik.setFieldValue("display_setting", nextValue)}
                      name="display_setting"
                      valueKey={{ key: "value", reduce: true }}
                    />
                  </FieldGridItem>
                  <FieldGridItem>
                    <InputLabel text="Default currency" for="default_currency" />
                    <Select
                      options={currencyOptions}
                      value={formik.values.default_currency}
                      valueKey={{ key: "value", reduce: true }}
                      labelKey="display_name"
                      onChange={({ value: nextValue }) => formik.setFieldValue("default_currency", nextValue)}
                      name="default_currency"
                      onSearch={(text) => {
                        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
                        const exp = new RegExp(escapedText, "i");
                        setCurrencyOptions(
                          settingsOptions.actions.PUT.default_currency.choices.filter(
                            ({ value, display_name }) => exp.test(value) || exp.test(display_name)
                          )
                        );
                      }}
                    />
                  </FieldGridItem>
                </FieldGrid>
              </Box>

              <Button
                secondary
                label="Update menu order"
                margin={{ bottom: "medium" }}
                onClick={() => setMenuReorderVisible(true)}
              />
              {menuReorderVisible && <MenuReorderPopup closePopup={closeReorder} />}
              <SwitchInput
                defaultChecked={formik.values.published}
                toggleChange={(value) => formik.setFieldValue("published", !formik.values.published)}
                label="Published"
                helpText="Designates whether or not your menu is published."
              />
              <InputDiv
                text="Digital menu URL"
                placeholder="For example thediner"
                name="slug"
                inputPrefix="eatsee.menu/"
                helpText="Your digital menu will be hosted on an eatsee.menu link such as eatsee.menu/thediner. A unique URL is created for each restaurant. If your restaurant name is taken, please try something else."
                hasLabel
              />
              <FieldGrid>
                <FieldGridItem>
                  <SwitchInput
                    defaultChecked={formik.values.remove_eatsee_branding}
                    hasPerm={data.permissions.remove_eatsee_branding}
                    toggleChange={(value) =>
                      formik.setFieldValue("remove_eatsee_branding", !formik.values.remove_eatsee_branding)
                    }
                    label="Remove Eatsee Branding"
                    helpText="Remove all Eatsee branding from your visual Menu."
                  />
                </FieldGridItem>

                <FieldGridItem>
                  <SwitchInput
                    defaultChecked={formik.values.logo_as_favicon}
                    hasPerm={data.permissions.logo_as_favicon}
                    toggleChange={(value) => formik.setFieldValue("logo_as_favicon", !formik.values.logo_as_favicon)}
                    label="Logo as favicon"
                    helpText="Set your visual menu favicon as your logo."
                  />
                </FieldGridItem>
              </FieldGrid>

              <FieldGrid>
                <FieldGridItem>
                  <FileUploadInput
                    preview={coverPreview}
                    exampleFile={coverExample}
                    setPreview={setCoverPrev}
                    text="Cover photo"
                    name="cover_image"
                    helpText="This image will be used as the background image and cover photo for your digital menu. Recommended image dimensions 1280x720px, to see an example of an ideal image"
                    handleFileInput={(fileName, fieldName) => formik.setFieldValue(fieldName, fileName)}
                    previewNewTab
                  />
                </FieldGridItem>
                <FieldGridItem>
                  <FileUploadInput
                    preview={logoPreview}
                    exampleFile={logoExample}
                    setPreview={setLogoPrev}
                    text="Logo"
                    name="venue_logo"
                    helpText="Your logo will be used on your dashboard as well as your personalised QR code. Your logo must be square. To see an example of an ideal logo"
                    handleFileInput={(fileName, fieldName) => formik.setFieldValue(fieldName, fileName)}
                    previewNewTab
                  />
                </FieldGridItem>
              </FieldGrid>
            </Form>
          )
        }
      </Formik>
    </>
  );
};

export default MenuSettings;
