import * as Sentry from "@sentry/react";
import ENV from "../constants/envConstants";

export const configureSentry = () => {
  Sentry.init({
    dsn: ENV.SENTRY_DSN,
    debug: ENV.IS_DEVELOPMENT,
    environment: ENV.SENTRY_ENVIRONMENT,
    release: ENV.SENTRY_RELEASE,
  });
};
