import React, { useState, useContext } from "react";
import { Box, DateInput, Heading, Paragraph, ResponsiveContext, Text, Layer } from "grommet";
import { useParams } from "react-router-dom";
import Confetti from "react-confetti";
import Container from "./Container";
import PricingTable from "./PricingTable";
import { usePlace } from "../api/queries";
import Loader from "./Loader";
import SwitchInput from "./SwitchInput";

const UpgradePage = () => {
  const [monthlyActive, setMonthlyActive] = useState(true);
  const [successActive, setSuccessActive] = useState(false);
  const [successPlan, setSuccessPlan] = useState("");
  const [showConfetti, setShowConfetti] = useState(true);

  const processSuccess = (planName) => {
    setSuccessActive(true);
    setSuccessPlan(planName);
  };

  const { place } = useParams();

  const size = useContext(ResponsiveContext);

  const isSmall = size === "small" || size === "xsmall";

  const { data, isFetching } = usePlace(place);

  if (!data) {
    return <Loader />;
  }

  const headingContent = isSmall ? (
    <Box>
      <Box direction="row" justify="between" margin={{ bottom: "medium" }}>
        <Box>
          <Heading margin={{ bottom: "xsmall", top: "none" }} level={3}>
            Upgrade your plan
          </Heading>
          <Text size="small">🔑 Upgrade to unlock premium features</Text>
        </Box>
      </Box>
      <Box direction="row" justify="between">
        <Box direction="row">
          <Text weight="bold">{monthlyActive ? "Monthly" : "Yearly"}</Text>
          <SwitchInput defaultChecked toggleChange={() => setMonthlyActive(!monthlyActive)} />
        </Box>
        <Box direction="column">
          <Box background="brand" round pad="small">
            <Text weight="bold" size="xsmall">
              Save 20%
            </Text>
          </Box>
          <Text alignSelf="center" size="xsmall" weight="bold">
            Yearly plans
          </Text>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box>
      <Box direction="row" justify="between">
        <Heading margin="none" level={3}>
          Upgrade your plan
        </Heading>
        <Box direction="row">
          <Text weight="bold">{monthlyActive ? "Monthly" : "Yearly"}</Text>
          <SwitchInput defaultChecked toggleChange={() => setMonthlyActive(!monthlyActive)} />
        </Box>
      </Box>
      <Box direction="row" justify="between" align="center">
        <Text>🔑 Upgrade to unlock premium features</Text>
        <Box direction="row">
          <Text alignSelf="center" size="small" weight="bold">
            Yearly subscriptions
          </Text>
          <Box margin={{ left: "small" }} background="brand" round pad="small">
            <Text weight="bold" size="xsmall">
              Save 20%
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const successContent = (
    <Box>
      {showConfetti && (
        <Layer full plain onClick={() => setShowConfetti(false)}>
          <Confetti
            numberOfPieces={1000}
            gravity={0.15}
            recycle={false}
            onConfettiComplete={() => setShowConfetti(false)}
          />
        </Layer>
      )}
      <Box direction="row" justify="between" margin={{ bottom: "medium" }}>
        <Box>
          <Heading margin={{ bottom: "xsmall", top: "none" }} level={2}>
            Upgrade complete
          </Heading>
        </Box>
      </Box>
      <Paragraph fill>You have successfully upgraded to the {successPlan} plan!</Paragraph>
    </Box>
  );

  return (
    <Box height={{ min: "auto" }}>
      <Container height={{ min: "auto" }}>
        {successActive ? (
          successContent
        ) : (
          <>
            <Box margin={{ bottom: "medium" }}>{headingContent}</Box>
            <PricingTable
              monthlyActive={monthlyActive}
              currentPlanId={data.current_plan_id}
              processSuccess={processSuccess}
            />
          </>
        )}
      </Container>
    </Box>
  );
};

export default UpgradePage;
