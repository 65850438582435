import { Nav } from "grommet";

const DashboardNav = ({ children, size }) => {
  return (
    <Nav gap={size === "small" ? "large" : "medium"} margin={{ bottom: "large" }}>
      {children}
    </Nav>
  );
};

export default DashboardNav;
