import React from "react";
import { Card, Text, Heading, List, CardFooter, Box, Anchor } from "grommet";
import { useParams, useNavigate, Link } from "react-router-dom";
import { faArrowDown, faLongArrowAltDown, faLongArrowAltUp } from "pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePopularDishesAnalytics } from "../../api/queries";
import Loader from "../Loader";

const PopularDishCard = ({ startDate, endDate }) => {
  const { place } = useParams();
  const { status, data } = usePopularDishesAnalytics(place, { startDate, endDate, compare: true });
  const navigate = useNavigate();

  if (!data) {
    return <Loader />;
  }

  let isPositive;
  if (data.change_percentage === null) {
    isPositive = null;
  } else {
    isPositive = Math.sign(data.change_percentage) === 1;
  }

  return (
    <Card basis="1/2" justify="between">
      <Box margin={{ horizontal: "xsmall", vertical: "small" }} direction="row" align="center" justify="between">
        <Heading level={3} size="small">
          Popular Menu Items
        </Heading>
        <Anchor as={Link} to="dishes" label="View all menu items >" color="black" />
      </Box>
      <List data={data.top_ten_dishes} primaryKey="dish__name" secondaryKey="views" />
      <CardFooter background="brand" pad="small" margin={{ top: "small" }}>
        <Box>
          <Text size="small" weight="bold">
            Menu item views
          </Text>
          <Text>{data.total_views}</Text>
          <Box direction="row">
            {isPositive !== null ? (
              <>
                <Text size="xsmall" margin={{ right: "xsmall" }}>
                  {data.change_percentage}%
                </Text>
                <FontAwesomeIcon
                  color={isPositive ? "green" : "orange"}
                  icon={isPositive ? faLongArrowAltUp : faLongArrowAltDown}
                  size="sm"
                />
              </>
            ) : (
              <Text size="xsmall">No previous data to compare</Text>
            )}
          </Box>
        </Box>
      </CardFooter>
    </Card>
  );
};

export default PopularDishCard;
