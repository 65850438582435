import { Box, Text } from "grommet";
import React from "react";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardTrait = (props) => {
  const { icon, content } = props;

  return (
    <Box background="grey" round="xsmall" border flex="shrink" direction="row" gap="xsmall" pad="xsmall">
      <FontAwesomeIcon icon={icon} />
      <Text size="small">{content}</Text>
    </Box>
  );
};

CardTrait.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default CardTrait;
