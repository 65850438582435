import React, { useEffect, useState, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Box, Heading, Select, Button, ResponsiveContext } from "grommet";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import forEach from "lodash/forEach";
import Loader from "./Loader";
import InputDiv from "./InputDiv";
import SwitchInput from "./SwitchInput";
import { updatePlaceSEOSettings } from "../api/mutations";
import { usePlace, usePlaceSEOOptions } from "../api/queries";
import { trackEventSegment } from "../utils/segmentUtils";
import { handle500, handleUnknown } from "../utils/errorHandles";
import Toast from "./Toast";
import InputLabel from "./InputLabel";
import FieldGrid from "./FieldGrid";
import FieldGridItem from "./FieldGridItem";
import HelpText from "./HelpText";
import OpeningHoursPopup from "./OpeningHoursPopup";
import CuisinePopup from "./CuisinePopup";

const SEOSettings = () => {
  const cache = useQueryClient();
  const { place } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [openingHoursVisible, setOpeningHoursVisible] = useState(false);
  const [cuisinesVisible, setCuisinesVisible] = useState(false);

  const closeCuisines = () => {
    setCuisinesVisible(false);
  };

  const closeOpeningHours = () => {
    setOpeningHoursVisible(false);
  };

  const { mutate, isLoading } = useMutation(updatePlaceSEOSettings);
  const { loading: placeLoading, status, data: placeData } = usePlace(place);
  const { loading: optionsLoading, data: seoOptions } = usePlaceSEOOptions(place);
  const size = useContext(ResponsiveContext);
  const isSmall = size === "small" || size === "xsmall";

  const [formData, setFormData] = useState({
    place_type: "",
    place_price_range: "",
    accepts_reservations: null,
    reservation_url: null,
    facebook_url: "",
    instagram_url: "",
    description: "",
  });

  useEffect(() => {
    if (placeData && !loaded && seoOptions) {
      const loadedData = {
        place_type: placeData.place_type,
        place_price_range: placeData.place_price_range ? placeData.place_price_range : "",
        accepts_reservations: placeData.accepts_reservations,
        reservation_url: placeData.reservation_url,
        facebook_url: placeData.facebook_url,
        instagram_url: placeData.instagram_url,
        description: placeData.description,
      };
      setFormData(loadedData);
      setLoaded(true);
    }
  }, [placeData, seoOptions]);

  const schema = Yup.object({
    place_type: Yup.string().required("This field is required."),
    reservation_url: Yup.string().url("Please enter a valid URL.").nullable(),
    facebook_url: Yup.string().url("Please enter a valid URL.").nullable(),
    instagram_url: Yup.string().url("Please enter a valid URL.").nullable(),
  });

  const onSubmit = async (values, bag) => {
    const reqBody = { ...values };
    await mutate(
      {
        placeId: place,
        values: reqBody,
      },
      {
        onSuccess: (data) => {
          trackEventSegment("SEO Settings updated");
          Toast.fire({
            title: "SEO optimisation preferences updated",
            icon: "success",
          });
          bag.setSubmitting(false);
        },
        onSettled: () => {
          cache.invalidateQueries(["placeDetail", { place }]);
        },
        onError: (error, newSect, rollback) => {
          const { status } = error.response;
          const { data } = error.response;
          switch (status) {
            case 400:
              if (data.error_type === "ValidationError") {
                forEach(data.errors, (error) => {
                  bag.setFieldError(error.field, error.message);
                });
              } else {
                handleUnknown();
              }
              break;
            case 500:
              handle500();
              break;
            default:
              handleUnknown();
              break;
          }
          bag.setSubmitting(false);
        },
      }
    );
  };

  if (!loaded) {
    return <Loader />;
  }
  return (
    <>
      <Formik initialValues={formData} onSubmit={onSubmit} validationSchema={schema}>
        {(formik) =>
          formik.isSubmitting || isLoading ? (
            <Loader />
          ) : (
            <Form>
              <Box flex direction="row" justify="between">
                <Box basis="3/4">
                  <Heading margin={{ bottom: "medium", top: "none" }} size="small">
                    SEO Optimisation
                  </Heading>
                </Box>
                <Box basis="1/4">
                  <Button primary type="submit" label={isSmall ? "Save" : "Save Changes"} />
                </Box>
              </Box>
              <FieldGrid>
                <FieldGridItem>
                  <Box margin={{ vertical: "medium" }}>
                    <InputLabel text="Place Type" for="place_type" />
                    <HelpText>These types are used by Google to classify your venue in search results.</HelpText>
                    <Select
                      options={seoOptions.actions.PUT.place_type.choices}
                      valueKey={{ key: "value", reduce: true }}
                      labelKey="display_name"
                      value={formik.values.place_type}
                      onChange={({ value: nextValue }) => formik.setFieldValue("place_type", nextValue)}
                      name="place_type"
                    />
                  </Box>
                </FieldGridItem>
                <FieldGridItem>
                  <Box margin={{ vertical: "medium" }}>
                    <InputLabel text="Place Price Range" for="place_price_range" />
                    <HelpText>These price ranges are used by Google to classify your venue in search results.</HelpText>
                    <Select
                      options={seoOptions.actions.PUT.place_price_range.choices}
                      placeholder="Select place price range"
                      clear={{ label: "Clear selected price range" }}
                      valueKey={{ key: "value", reduce: true }}
                      labelKey="display_name"
                      value={formik.values.place_price_range}
                      onChange={({ value: nextValue }) => formik.setFieldValue("place_price_range", nextValue)}
                      name="place_price_range"
                    />
                  </Box>
                </FieldGridItem>
              </FieldGrid>
              <InputDiv
                text="Description"
                name="description"
                helpText="Your description is used by social media companies and Google to for shared links to your venue and in search results."
                type="textarea"
                hasLabel
              />
              <FieldGrid>
                <FieldGridItem>
                  <SwitchInput
                    defaultChecked={formik.values.accepts_reservations}
                    toggleChange={(value) =>
                      formik.setFieldValue("accepts_reservations", !formik.values.accepts_reservations)
                    }
                    label="Accepts Reservations"
                    helpText="Designates whether or not your venue accepts bookings/reservations. Google uses this information to display in search results."
                  />
                </FieldGridItem>
                {formik.values.accepts_reservations === true && (
                  <FieldGridItem>
                    <InputDiv
                      text="Reservation URL"
                      name="reservation_url"
                      helpText="URL customers can access to make a booking/reservation at your venue. Google will display this URL in search results."
                      hasLabel
                    />
                  </FieldGridItem>
                )}
              </FieldGrid>
              <Button
                secondary
                label="Update opening hours"
                margin={{ bottom: "medium" }}
                onClick={() => setOpeningHoursVisible(true)}
                margin={{ right: "small" }}
              />
              {openingHoursVisible && <OpeningHoursPopup closePopup={closeOpeningHours} place={placeData} />}
              <Button
                secondary
                label="Update cuisines"
                margin={{ bottom: "medium" }}
                onClick={() => setCuisinesVisible(true)}
              />
              {cuisinesVisible && <CuisinePopup closePopup={closeCuisines} place={placeData} />}
              <FieldGrid>
                <FieldGridItem>
                  <InputDiv
                    text="Facebook URL"
                    placeholder="For example https://facebook.com/thediner"
                    name="facebook_url"
                    helpText="Google uses your Facebook URL to display as part of your venue in search results."
                    hasLabel
                  />
                </FieldGridItem>
                <FieldGridItem>
                  <InputDiv
                    text="Instagram URL"
                    placeholder="For example https://instagram.com/thediner"
                    name="instagram_url"
                    helpText="Google uses your Instagram URL to display as part of your venue in search results."
                    hasLabel
                  />
                </FieldGridItem>
              </FieldGrid>
            </Form>
          )
        }
      </Formik>
    </>
  );
};

export default SEOSettings;
