import { Grommet, Box, ResponsiveContext } from "grommet";
import { useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { theme } from "../constants/theme";
import DashboardMain from "./DashboardMain";
import DashboardSidebar from "./DashboardSidebar";
import { usePlace } from "../api/queries";
import breakpoints from "../constants/breakpoints";
import DashboardHeader from "./DashboardHeader";
import LoaderScreen from "./LoaderScreen";
import NotFound from "./NotFound";
import ErrorPage from "./ErrorPage";

const Dashboard = () => {
  const { place } = useParams();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { data, isLoading, isError, error } = usePlace(place);
  const navigate = useNavigate();

  const location = useLocation();

  if (location.pathname === `/${place}`) {
    navigate(`/${place}/menu-editor/builder`, { state: { title: "Menu Builder" } });
  }

  if (isLoading || !data) {
    return (
      <Grommet theme={theme} full>
        <LoaderScreen />
      </Grommet>
    );
  }

  if (isError) {
    if (error.response.status === 404) {
      return <NotFound />;
    }
    return <ErrorPage />;
  }
  const toggleSidebar = (bool) => {
    setSidebarOpen(bool);
  };

  return (
    <Grommet theme={theme}>
      <ResponsiveContext.Consumer>
        {(size) => (
          <ThemeProvider theme={breakpoints}>
            {size === "small" && <DashboardHeader toggleSidebar={toggleSidebar} />}
            <Box flex direction="row" fill align="stretch" height={{ min: "100vh" }}>
              <DashboardSidebar place={data} size={size} fill sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
              <DashboardMain place={data} size={size} fill />
            </Box>
          </ThemeProvider>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
};
export default Dashboard;
