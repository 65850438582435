import styled from "styled-components";

const AddMore = styled.button`
  padding: 1em 0;
  color: #ff5265;
  font-weight: 600;
  font-size: 1em;
  background: none;
  margin-bottom: 1em;
  border: 0;
  text-transform: uppercase;
  text-align: left;
  margin-left: 0.2em;
  margin-top: 1em;

  svg {
    margin-right: 1em;
  }
`;

export default AddMore;
