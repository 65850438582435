import { Card, Text, Box, Menu } from "grommet";
import { Logout, User, FormDown } from "grommet-icons";
import { useNavigate } from "react-router";
import { ThemeContext } from "styled-components";
import SidebarAvatar from "./SidebarAvatar";

const SidebarProfile = ({ size, place }) => {
  const navigate = useNavigate();

  return (
    <ThemeContext.Extend
      value={{
        button: {
          default: {
            padding: { vertical: "0", horizontal: "0" },
          },
        },
      }}
    >
      <Card direction="row" background="brand" pad="medium" width="100%">
        <Menu
          fill
          dropAlign={{ top: "bottom", left: "left" }}
          dropBackground="white"
          dropProps={{ border: { size: "small" } }}
          items={[
            {
              label: (
                <Box fill alignSelf="center">
                  Switch Venue
                </Box>
              ),
              gap: "medium",
              onClick: () => {
                navigate("/", { state: { title: "Select Venue" } });
              },
              icon: (
                <Box pad="small">
                  <User size="small" />
                </Box>
              ),
            },
            {
              label: (
                <Box fill alignSelf="center">
                  Logout
                </Box>
              ),
              onClick: () => {
                navigate("/logout");
              },
              gap: "medium",
              icon: (
                <Box pad="small">
                  <Logout size="small" />
                </Box>
              ),
            },
          ]}
        >
          {(els) => {
            return (
              <Box direction="row">
                <SidebarAvatar place={place} />
                <Box direction="row" justify="between" justifyContent="between" fill>
                  <Box gap="xxsmall">
                    <Text size="small" color="white" textAlign="start" weight="bold">
                      {place.name}
                    </Text>
                    <Text color="white" size="xsmall" textAlign="start">
                      {place.current_plan} Plan
                    </Text>
                    {(place.subscription_status === "cancelled" || place.subscription_status === "expired") && (
                      <Box background="status-warning" pad="xxsmall" round>
                        <Text color="white" size="10px" textAlign="center">
                          Canclled/Expired
                        </Text>
                      </Box>
                    )}
                  </Box>
                  <Box alignSelf="center">
                    <FormDown />
                  </Box>
                </Box>
              </Box>
            );
          }}
        </Menu>
      </Card>
    </ThemeContext.Extend>
  );
};

export default SidebarProfile;
