import { PropTypes } from "prop-types";
import styled from "styled-components";
import { faGripVertical } from "pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "grommet";

const Wrap = styled.div`
  display: flex;
  border: 2px #e5e5e5 solid;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 2em;
  padding: 2em;
  box-sizing: border-box;
  background: white;
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 1.15em;
  align-self: center;
  color: #626262;
`;

const MenuListItem = (props) => {
  return (
    <Wrap>
      <Box flex direction="row">
        <Title>{props.menu.name}</Title>
      </Box>
      <Box>
        <FontAwesomeIcon color="#919191" size="lg" icon={faGripVertical} />
      </Box>
    </Wrap>
  );
};

MenuListItem.propTypes = {
  menu: PropTypes.object.isRequired,
};

export default MenuListItem;
