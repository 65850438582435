import { Box } from "grommet";
import Loader from "./Loader";

const LoaderScreen = () => {
  return (
    <Box fill justify="center">
      <Loader />
    </Box>
  );
};

export default LoaderScreen;
