import { Box, Card, Text } from "grommet";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../axiosConfig";
import Button from "./Button";
import ENV from "../constants/envConstants";

const PricingItem = ({ monthlyActive, plan, currentPlan, currentPlanId, processSuccess }) => {
  const { place } = useParams();

  const periodText = monthlyActive ? "per month" : "per year";
  const price = monthlyActive ? Math.round(plan.monthly_price_raw) : Math.round(plan.annual_price_raw);

  const cbInstance = window.Chargebee.getInstance();

  const [buttonText, setButtonText] = useState("Upgrade now");

  const planCost = currentPlanId.includes("annual") ? currentPlan.annual_price_raw : currentPlan.monthly_price_raw;

  useEffect(() => {
    let newButtonText = "Upgrade now";
    if (monthlyActive) {
      if (plan.monthly_price_raw < planCost) {
        newButtonText = "Change";
      } else if (plan.chargebee_id === currentPlanId) {
        newButtonText = "Current plan";
      }
    } else if (plan.annual_price_raw < planCost) {
      newButtonText = "Change";
    } else if (plan.chargebee_annual_id === currentPlanId) {
      newButtonText = "Current plan";
    }
    setButtonText(newButtonText);
  }, [monthlyActive]);

  const clickUpgrade = () => {
    cbInstance.openCheckout({
      hostedPage() {
        return axios
          .post(`${ENV.BACKEND_URL}/chargebee/checkout/`, {
            customerId: place,
            planId: monthlyActive ? plan.chargebee_id : `${plan.chargebee_id}-annual`,
          })
          .then((response) => response.data);
      },
      success(hostedPageId) {
        axios
          .post(`${ENV.BACKEND_URL}/chargebee/checkout/success/`, { hostedpageId: hostedPageId })
          .then((response) => response.data)
          .then(() => processSuccess(plan.name));
      },
    });
  };

  return (
    <Card pad="medium" flex="grow" key={place.chargebee_id} height={{ min: "auto" }}>
      <Box margin={{ bottom: "small" }}>
        <Text weight="bold" size="xxlarge" margin={{ bottom: "xsmall" }}>
          {plan.name}
        </Text>
        <Box direction="row">
          <Text size="xxlarge" margin={{ right: "xsmall" }}>
            ${price}
          </Text>
          <Text size="small" style={{ wordSpacing: "100vw", width: "min-content" }}>
            {plan.is_free ? "free forever" : periodText}
          </Text>
        </Box>
      </Box>
      <Button text={buttonText} onClick={() => clickUpgrade()} disabled={buttonText === "Current plan"} />
      <Box gap="small">
        {plan.features_raw_text.map((feature) => {
          return <Text weight="bold">{feature}</Text>;
        })}
      </Box>
    </Card>
  );
};

export default PricingItem;
