import { PropTypes } from "prop-types";
import styled from "styled-components";
import { useState } from "react";
import { Box, Layer, Text, Image } from "grommet";
import Loader from "./Loader";
import ENV from "../constants/envConstants";
import comingSoonImage from "../assets/images/coming-soon.jpg";
import logo from "../assets/images/eatsee.svg";

const DishWrap = styled.div`
  border-radius: 12px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.16);
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: flex;
  height: 100%;
  background: white;
`;

const AnalyticsDishItem = ({ dish, displayImage, sectionId, numDishes, menuId, sectionDishId }) => {
  if (dish) {
    const correctImageUrl = () => {
      if (dish.image.includes("http")) {
        return dish.image;
      }
      return ENV.BACKEND_BASE_URL + dish.image;
    };

    const priceFormatted = new Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: dish.price_currency,
    }).format(dish.price);

    return (
      <>
        <DishWrap>
          <Box height="200px">
            {displayImage ? (
              <Image fit="cover" fill src={dish.image ? correctImageUrl() : comingSoonImage} />
            ) : (
              <Image fit="cover" fill width="100%" height="200px" src={logo} />
            )}
          </Box>
          <Box pad="small" flex justify="around">
            <Text
              weight="500"
              alignSelf="center"
              color="#626262"
              margin={{ bottom: "small" }}
              wordBreak="break-word"
              size="1.1em"
            >
              {dish.name} - {priceFormatted}{" "}
            </Text>
          </Box>
          <Box background="brand" pad="small" direction="row" justify="between">
            <Box>
              <Text size="small" weight="bold">
                Pageviews
              </Text>
              <Text>{dish.page_views}</Text>
            </Box>
            <Box>
              <Text size="small" weight="bold">
                Popup views
              </Text>
              <Text>{dish.modal_views}</Text>
            </Box>
            <Box>
              <Text size="small" weight="bold">
                Total views
              </Text>
              <Text>{dish.total_views}</Text>
            </Box>
          </Box>
        </DishWrap>
      </>
    );
  }
  return <Loader />;
};

AnalyticsDishItem.defaultProps = {
  displayImage: true,
};

AnalyticsDishItem.propTypes = {
  dish: PropTypes.object.isRequired,
  menuId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  numDishes: PropTypes.number.isRequired,
  displayImage: PropTypes.bool,
  sectionDishId: PropTypes.string.isRequired,
};

export default AnalyticsDishItem;
