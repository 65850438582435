import { PropTypes } from "prop-types";
import styled from "styled-components";
import { faTrashAlt, faGripVertical, faPencil } from "pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQueryClient } from "react-query";
import { up } from "styled-breakpoints";
import Swal from "sweetalert2";
import findIndex from "lodash/findIndex";
import { useEffect, useState, useContext } from "react";
import { Box, Card, ResponsiveContext, Text } from "grommet";
import { useParams } from "react-router";
import { trackEventSegment } from "../utils/segmentUtils";
import Loader from "./Loader";
import { usePlace } from "../api/queries";
import SectionImageList from "./SectionImageList";
import { removeMenuSection } from "../api/mutations";
import SectionFormPopup from "./SectionFormPopup";

const Title = styled.span`
  font-weight: 500;
  font-size: 1.15em;
  align-self: center;
  margin-bottom: 0.75em;

  ${up("xs")} {
    margin-bottom: 0;
  }
`;

const Heading = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  flex-wrap: wrap;

  ${up("xs")} {
    flex-wrap: nowrap;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  justify-content: space-between;

  ${up("xs")} {
    width: 30%;
  }

  ${up("sm")} {
    width: 20%;
  }

  ${up("md")} {
    width: 18%;
  }

  ${up("lg")} {
    width: 15%;
  }

  ${up("xl")} {
    width: 12%;
  }

  ${up("xxl")} {
    width: 10%;
  }
`;

const SectionItem = (props) => {
  const { place } = useParams();

  const size = useContext(ResponsiveContext);

  const isSmallOrMed = size === "small" || size === "xsmall" || size === "medium";

  const cache = useQueryClient();

  const { data } = usePlace(place);

  const [section, setSection] = useState(null);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (data) {
      const menuIndex = findIndex(data.menus, ["uuid", props.menuId]);
      const sectIndex = findIndex(data.menus[menuIndex].menu_categories, ["uuid", props.sectionId]);
      setSection(data.menus[menuIndex].menu_categories[sectIndex]);
      // setDishes(data.menus[menuIndex].menu_categories[sectIndex].dishes);
      setLoaded(true);
    }
  }, [data, section]);

  const onMutateRemove = (data) => {
    const sectId = data.menuId;
    cache.cancelQueries(["placeDetail", { place }]);
    const previousPlace = cache.getQueryData(["placeDetail", { place }]);
    const newPlace = { ...previousPlace };
    const menuIndex = findIndex(newPlace.menus, ["uuid", props.menuId]);
    const sectIndex = findIndex(newPlace.menus[menuIndex].menu_categories, ["uuid", sectId]);
    newPlace.menus[menuIndex].menu_categories.splice(sectIndex, 1);
    cache.setQueryData(["placeDetail", { place }], newPlace);
    return () => cache.setQueryData(["placeDetail", { place }], previousPlace);
  };

  const { mutate: removeMutate } = useMutation(removeMenuSection, { onMutate: onMutateRemove });

  const clickDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      text: "Deleting this menu section will also remove any dishes associated with it.",
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showLoaderOnConfirm: true,
      preConfirm: async (result) => {
        await removeMutate(
          { menuId: section.uuid },
          {
            onError: (resError) => {},
            onSuccess: () => {
              trackEventSegment("Removed Subcategory", {
                uuid: section.uuid,
                name: section.name,
              });
            },
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const changeLoaded = (state) => {
    setLoaded(state);
  };

  if (!loaded) {
    return <Loader />;
  }

  return (
    <Card pad={isSmallOrMed ? "medium" : "large"} background="white">
      <Heading>
        <Box direction="row" flex="grow" align="center">
          <Box margin={{ right: "small" }}>
            <Title>{section.name}</Title>
          </Box>
          {section.display == "BASIC" && (
            <Box background="brand" round pad="xsmall">
              <Text weight="bold" size="small">
                Text Only
              </Text>
            </Box>
          )}
        </Box>
        <Buttons {...props.dragHandle}>
          <SectionFormPopup
            menuId={props.menuId}
            numOfSections={props.numOfSections}
            section={section}
            element={<FontAwesomeIcon color="#919191" size="lg" icon={faPencil} />}
          />
          <FontAwesomeIcon color="#919191" size="lg" icon={faTrashAlt} onClick={clickDelete} />
          <FontAwesomeIcon color="#919191" size="lg" icon={faGripVertical} />
        </Buttons>
      </Heading>
      <SectionImageList menuId={props.menuId} section={section} changeLoaded={changeLoaded} />
    </Card>
  );
};

SectionItem.propTypes = {
  sectionId: PropTypes.string.isRequired,
  menuId: PropTypes.string.isRequired,
  dragHandle: PropTypes.any,
};

export default SectionItem;
