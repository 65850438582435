import { Box, Main } from "grommet";
import { Outlet, useOutlet } from "react-router-dom";
import DashboardFooter from "./DashboardFooter";
import UpgradePage from "./UpgradePage";

const DashboardMain = ({ size, place }) => {
  const padDict = {
    xsmall: "xsmall",
    small: "xsmall",
    medium: "medium",
    large: "large",
    xlarge: "large",
  };
  let hasPerm = true;
  const outlet = useOutlet();

  if (outlet !== null) {
    const perm = outlet.props.value.route.element.props?.perm;

    if (perm !== undefined && perm !== null) {
      const hasCorrectPerm = place.permissions[perm];
      hasPerm = hasCorrectPerm;
    }
  }

  return (
    <Box flex="grow" width="small">
      <Main
        background="grey"
        pad={padDict[size]}
        basis={size === "small" ? "full" : "auto"}
        overflow={{ vertical: "auto" }}
        margin={{ top: size === "small" || size === "xsmall" ? "55.5px" : "none" }}
        fill={false}
      >
        <Box height={{ min: "auto" }}>{hasPerm ? <Outlet /> : <UpgradePage />}</Box>
        <DashboardFooter />
      </Main>
    </Box>
  );
};

export default DashboardMain;
