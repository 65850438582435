import { Card, CardFooter, Text, Box, Heading } from "grommet";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { Chip } from "@nivo/tooltip";
import { linearGradientDef } from "@nivo/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faLongArrowAltDown, faLongArrowAltUp } from "pro-solid-svg-icons";
import { usePlaceViewAnalytics } from "../../api/queries";
import Loader from "../Loader";
import { brandRed } from "../../constants/colours";

const PlaceViewCard = ({ startDate, endDate }) => {
  const { place } = useParams();
  const { status, data } = usePlaceViewAnalytics(place, { startDate, endDate, compare: true });

  const theme = {
    fontFamily: "Gordita",
  };

  if (!data) {
    return <Loader />;
  }

  const tooltip = (point) => {
    return (
      <Box background="white" round="small" elevation="small" pad={{ vertical: "xsmall", horizontal: "xsmall" }}>
        <Text size="small" weight="bold">
          {point.data.xFormatted}
        </Text>
        <Box direction="row" align="center">
          <Chip color={point.serieColor} />
          <Text margin={{ left: "xsmall" }} size="small">
            Pageviews: {point.data.yFormatted}
          </Text>
        </Box>
      </Box>
    );
  };

  let isPositive;
  if (data.change_percentage === null) {
    isPositive = null;
  } else {
    isPositive = Math.sign(data.change_percentage) === 1;
  }

  return (
    <Card basis="1/2" justify="between">
      <Heading margin={{ left: "xsmall", vertical: "small" }} level={3} size="small">
        Place Views
      </Heading>
      <Box height="450px">
        <ResponsiveLine
          margin={{ top: 30, right: 20, bottom: 18, left: 30 }}
          animate
          data={[
            {
              id: "Page Views",
              data: data.view_data,
            },
          ]}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%B %e, %Y"
          enableGridX={false}
          yScale={{
            type: "linear",
            stacked: false,
          }}
          axisLeft={{
            legendOffset: 12,
          }}
          tooltip={(input) => {
            return tooltip(input.point);
          }}
          theme={theme}
          axisBottom={{
            format: "%b %d",
            tickValues: "every 4 days",
            tickPadding: 0,
          }}
          legends={[
            {
              anchor: "top-left",
              direction: "column",
              justify: false,
              translateX: -20,
              translateY: -30,
              label: "test",
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          defs={[
            linearGradientDef("gradientA", [
              { offset: 0, color: "inherit" },
              { offset: 100, color: "inherit", opacity: 0 },
            ]),
          ]}
          fill={[{ match: "*", id: "gradientA" }]}
          curve="monotoneX"
          enableArea
          colors={[brandRed]}
          useMesh
          enableSlices={false}
        />
      </Box>
      <CardFooter background="brand" pad="small" margin={{ top: "small" }}>
        <Box>
          <Text size="small" weight="bold">
            Pageviews
          </Text>
          <Text>{data.total_views}</Text>
          <Box direction="row">
            {isPositive !== null ? (
              <>
                <Text size="xsmall" margin={{ right: "xsmall" }}>
                  {data.change_percentage}%
                </Text>
                <FontAwesomeIcon
                  color={isPositive ? "green" : "orange"}
                  icon={isPositive ? faLongArrowAltUp : faLongArrowAltDown}
                  size="sm"
                />
              </>
            ) : (
              <Text size="xsmall">No previous data to compare</Text>
            )}
          </Box>
        </Box>
      </CardFooter>
    </Card>
  );
};

export default PlaceViewCard;
