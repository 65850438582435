import { Outlet, useParams, useLocation } from "react-router-dom";
import { Box, Heading, Button, Text } from "grommet";
import { faEye, faInfoCircle } from "pro-solid-svg-icons";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PreviewButton from "./PreviewButton";
import Container from "./Container";
import { usePlace } from "../api/queries";
import Loader from "./Loader";

function MenuBuilder() {
  const { place } = useParams();
  const loc = useLocation();
  const navigate = useNavigate();
  const { data } = usePlace(place);

  const paths = {
    builder: "Menu Builder",
    items: "Menu Items",
    pdf: "Menu PDF",
  };

  const getTitle = () => {
    const initialPath = `/${place}/menu-editor/`;
    let title = "Menu Builder";
    Object.entries(paths).forEach(([path, titleText]) => {
      if (loc.pathname === `${initialPath}${path}`) {
        title = titleText;
      }
    });
    return title;
  };

  if (!data) {
    return <Loader />;
  }

  const banner = (
    <Box
      background="brand"
      pad={{ vertical: "small", horizontal: "medium" }}
      direction="row"
      justify="center"
      round={{ size: "medium", corner: "top" }}
      margin={{ horizontal: "small" }}
      align="center"
    >
      <FontAwesomeIcon icon={faInfoCircle} />
      <Text alignSelf="center" margin={{ right: "medium", left: "xsmall" }} weight="bold">
        Your menu is currently unpublished
      </Text>
      <Button size="small" secondary label="Go to settings" onClick={() => navigate(`/${place}/settings/menu`)} />
    </Box>
  );

  return (
    <>
      {!data.published && banner}
      <Container
        margin={{ horizontal: "small", bottom: "small" }}
        round={data.published ? { size: "medium" } : { size: "medium", corner: "bottom" }}
      >
        <Box direction="row" justify="between" margin={{ bottom: "small" }} align="center">
          <Box direction="row" gap="small">
            <Heading level={1} size="small">
              {getTitle()}
            </Heading>
          </Box>
          {!data ? <Loader /> : <PreviewButton title="Preview Menu" icon={faEye} small href={data.preview_url} />}
        </Box>
        <Outlet />
      </Container>
    </>
  );
}

export default MenuBuilder;
