import { ErrorMessage } from "formik";
import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "grommet";
import InputLabel from "./InputLabel";
import HelpText from "./HelpText";
import "flatpickr/dist/themes/material_green.css";
import Error from "./Error";
import InputDiv from "./InputDiv";
import AddMore from "./AddMore";

const Cuisines = ({ arrayHelpers, label, helpText }) => {
  const addNew = () => {
    arrayHelpers.push({
      cuisine: "",
    });
  };
  return (
    <Box margin={{ bottom: "xlarge" }}>
      {label && <InputLabel text={label} />}
      {helpText && <HelpText>{helpText}</HelpText>}
      {arrayHelpers.form.values[arrayHelpers.name].map((menuAvailability, index) => (
        <Box direction="row" align="center" margin={{ bottom: "medium" }} flex justify="between">
          <Box flex>
            <InputDiv
              name={`${arrayHelpers.name}.${index}.cuisine`}
              hasFieldWrap={false}
              placeholder="For example: Italian"
            />
          </Box>
          <Box margin={{ left: "medium" }} direction="row" onClick={() => arrayHelpers.remove(index)}>
            <FontAwesomeIcon icon={faTrashAlt} size="lg" color="grey" />
          </Box>
        </Box>
      ))}
      <ErrorMessage component={Error} name={arrayHelpers.name} />
      {arrayHelpers.form.values[arrayHelpers.name].length < 3 && (
        <AddMore onClick={() => addNew()} type="button">
          <FontAwesomeIcon icon={faPlus} size="lg" color="black" />
          Add another
        </AddMore>
      )}
    </Box>
  );
};

export default Cuisines;
