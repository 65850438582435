import map from "lodash/map";
import isEmpty from "lodash/isEmpty";

const hasEmailAndPhone = (place) => !isEmpty(place.phone_number) && !isEmpty(place.email);
const hasCoverPhoto = (place) => !isEmpty(place.cover_image);
const hasLogo = (place) => !isEmpty(place.venue_logo);
const isPublished = (place) => place.published;
const hasMenu = (place) => place.menus.length > 0 || place.menu_pdf;

const scoreFuncs = [hasEmailAndPhone, hasCoverPhoto, hasLogo, hasMenu];

const getSetupScore = (place) => {
  let score = 0;
  map(scoreFuncs, (value) => {
    if (!value(place)) {
      score += 1;
    }
  });
  return score;
};

export { hasEmailAndPhone, hasCoverPhoto, hasLogo, hasMenu, isPublished, getSetupScore };
