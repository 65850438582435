import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import { up } from "styled-breakpoints";

const IconWrap = styled.div`
  background: #dbdbdb;
  height: ${(props) => (props.small ? "auto" : "100%")};
  display: block;
  padding: ${(props) => (props.small ? "0.3em" : "0.8em")};
  border-radius: 10px;

  ${up("xxl")} {
    padding: ${(props) => (props.small ? "0.5em" : "1em")};
  }
`;

const ButtonIcon = (props) => {
  return (
    <IconWrap small={props.small}>
      <FontAwesomeIcon size={props.small ? "lg" : "2x"} icon={props.icon} color="black" />
    </IconWrap>
  );
};

ButtonIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  small: PropTypes.bool,
};

export default ButtonIcon;
