import { Box, ResponsiveContext, Text } from "grommet";
import { useContext } from "react";
import { useUser } from "../api/queries";
import Loader from "./Loader";
import PlaceItem from "./PlaceItem";

const PlaceList = () => {
  const { data } = useUser();
  const size = useContext(ResponsiveContext);

  if (!data) {
    return <Loader />;
  }

  return (
    <Box
      width="full"
      justify="center"
      align="center"
      gap={size === "xsmall" || size === "small" ? "large" : "medium"}
      pad={size === "xsmall" || size === "small" ? "large" : "xsmall"}
      overflow={{ vertical: "auto" }}
    >
      {data.managed_places.length > 0 ? (
        data.managed_places.map((place) => <PlaceItem place={place} />)
      ) : (
        <Text>You do not have access to any venues.</Text>
      )}
    </Box>
  );
};

export default PlaceList;
