import { Box, Image } from "grommet";
import eatsee from "../assets/images/eatsee.svg";

const SidebarAvatar = ({ place }) => {
  let image;

  if (place.venue_logo) {
    image = place.venue_logo;
  } else {
    image = eatsee;
  }
  return (
    <Box margin={{ right: "small" }} height="xxsmall" width="xxsmall">
      <Image src={image} fit="contain" />
    </Box>
  );
};

export default SidebarAvatar;
