import { Sidebar, Layer, Anchor, Box } from "grommet";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import {
  faUtensils,
  faAddressBook,
  faQrcode,
  faCommentDots,
  faChartLine,
  faEnvelope,
  faCog,
  faSearchPlus,
} from "pro-solid-svg-icons";
import styled from "styled-components";
import DashboardNav from "./DashboardNav";
import DashboardNavItem from "./DashboardNavItem";
import DashboardNavItemChild from "./DashboardNavItemChild";
import SidebarProfile from "./SidebarProfile";
import UpgradeButton from "./UpgradeButton";
import UpgradePopup from "./UpgradePopup";
import AccountSetupButton from "./AccountSetupButton";
import { getSetupScore } from "../utils/setupScore";

const StickySidebar = styled(Sidebar)`
  position: sticky;
  top: 0;
`;

const DashboardSidebar = (props) => {
  const { place, size, sidebarOpen, toggleSidebar } = props;

  const location = useLocation();

  const [upgradeVisible, setUpgradeVisible] = useState(false);
  const [upgradeText, setUpgradeText] = useState("");

  const setUpgradePopup = (state, text) => {
    setUpgradeVisible(state);
    setUpgradeText(text);
  };

  useEffect(() => {
    if (size === "small") {
      toggleSidebar(false);
    }
  }, [location]);

  const score = getSetupScore(place);

  const isSmall = size === "small" || size === "xsmall";

  const smallerThanLarge = size !== "large" && size !== "xlarge" && size !== "xxlarge";

  const menuItems = (
    <DashboardNav size={size}>
      <DashboardNavItem text="Menu editor" icon={faUtensils} setUpgradePopup={setUpgradePopup}>
        <DashboardNavItemChild text="Menu Builder" to="menu-editor/builder" pageTitle="Menu Builder" />
        <DashboardNavItemChild text="Menu Items" to="menu-editor/items" pageTitle="Menu Items" />
        <DashboardNavItemChild text="Menu PDF" to="menu-editor/pdf" pageTitle="Menu PDF" />
      </DashboardNavItem>
      <DashboardNavItem
        text="Guest register"
        setUpgradePopup={setUpgradePopup}
        to="guest-register"
        icon={faAddressBook}
        pageTitle="Guest Register"
        perm="guest_registration"
        place={place}
      />
      <DashboardNavItem
        text={smallerThanLarge ? "QR generator" : "QR code generator"}
        setUpgradePopup={setUpgradePopup}
        pageTitle="QR Code Generator"
        to="qr-generator"
        icon={faQrcode}
      />
      <DashboardNavItem
        text={smallerThanLarge ? "Feedback" : "Customer feedback"}
        to="customer-feedback"
        pageTitle="Customer Feedback"
        icon={faCommentDots}
        setUpgradePopup={setUpgradePopup}
        perm="private_feedback"
        place={place}
      />
      <DashboardNavItem icon={faSearchPlus} text="SEO optimisation" to="settings/seo" pageTitle="SEO Settings" />
      <DashboardNavItem
        place={place}
        setUpgradePopup={setUpgradePopup}
        text="Analytics"
        to="analytics"
        icon={faChartLine}
        perm="analytics"
      >
        <DashboardNavItemChild text="QR Codes" to="analytics/qrcode" pageTitle="QR Code Analytics" />
        <DashboardNavItemChild text="Visual Menu" to="analytics/menu" pageTitle="Visual Menu Analytics" />
      </DashboardNavItem>
      <DashboardNavItem
        text={smallerThanLarge ? "Lead Capture" : "Customer lead capture"}
        to="leads"
        setUpgradePopup={setUpgradePopup}
        icon={faEnvelope}
        perm="lead_gen"
        place={place}
      >
        <DashboardNavItemChild text="Lead data" to="leads/data" pageTitle="Lead Data" />
        <DashboardNavItemChild text="Lead generators" to="leads/offers" pageTitle="Lead Generators" />
        <DashboardNavItemChild
          text="Lead preferences"
          to="settings/offer"
          perm="lead_gen"
          pageTitle="Lead preferences"
        />
      </DashboardNavItem>
      <DashboardNavItem text="Settings" setUpgradePopup={setUpgradePopup} to="settings" icon={faCog}>
        <DashboardNavItemChild text="Business" to="settings/business" pageTitle="Business Settings" />
        <DashboardNavItemChild text="Menu preferences" to="settings/menu" pageTitle="Menu Preferences" />
        <DashboardNavItemChild text="Guest register" to="settings/guest-registration" pageTitle="Guest Register" />
        <DashboardNavItemChild text="Billing" to="settings/billing" pageTitle="Billing" />
        <DashboardNavItemChild text="Personal" to="settings/personal" pageTitle="Personal Settings" />
      </DashboardNavItem>
      {score > 0 && <AccountSetupButton place={place} />}
    </DashboardNav>
  );

  if (isSmall) {
    if (sidebarOpen) {
      return (
        <Layer flex pad="medium" animation="fadeIn">
          {upgradeVisible ? (
            <UpgradePopup togglePopup={setUpgradePopup} title={upgradeText} />
          ) : (
            <>
              <Box background="brand" pad="medium" overflow="auto">
                <Anchor onClick={() => toggleSidebar(false)}>Close</Anchor>
              </Box>
              <Box pad="medium" background="white" fill>
                <Box margin={{ bottom: "large" }}>
                  <SidebarProfile size={size} place={place} />
                </Box>
                {menuItems}
                {place.current_plan === "Starter" && <UpgradeButton togglePopup={setUpgradePopup} />}
              </Box>
            </>
          )}
        </Layer>
      );
    }
    return null;
  }

  return (
    <StickySidebar
      pad="medium"
      fill
      width={{ min: "auto" }}
      basis={size === "medium" ? "28%" : "medium"}
      header={<SidebarProfile place={place} />}
    >
      {menuItems}
      {place.current_plan === "Starter" && <UpgradeButton togglePopup={setUpgradePopup} />}
      {upgradeVisible && (
        <Layer
          position="center"
          animation="fadeIn"
          onClickOutside={() => setUpgradePopup(false)}
          onEsc={() => setUpgradePopup(false)}
        >
          <UpgradePopup togglePopup={setUpgradePopup} title={upgradeText} />
        </Layer>
      )}
    </StickySidebar>
  );
};

export default DashboardSidebar;
