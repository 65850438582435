import styled from "styled-components";

const HelpText = styled.div`
  font-family: Gordita;
  margin-bottom: 1em;
  font-size: 0.85em;
  color: dimgrey;
  line-height: 1.2;
`;

export default HelpText;
