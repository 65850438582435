import styled from "styled-components";
import { PropTypes } from "prop-types";
import { up } from "styled-breakpoints";
import ButtonIcon from "./ButtonIcon";
import { trackEventSegment } from "../utils/segmentUtils";

const ButtonWrap = styled.a`
  border-radius: 12px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.16);
  width: ${(props) => (props.small ? "auto" : "100%")};
  display: flex;
  padding: ${(props) => (props.small ? "0" : "1.2em")};
  font-family: Gordita;
  font-weight: 500;
  box-sizing: border-box;
  margin-bottom: ${(props) => (props.small ? "0" : "3em")};
  text-decoration: none;
  color: black;
  height: ${(props) => (props.small ? "max-content" : "auto")};

  ${up("lg")} {
    padding: ${(props) => (props.small ? "0.5em 0.75em" : "1.2em")};
  }

  ${up("xxl")} {
    padding: ${(props) => (props.small ? "0.5em 1.2em" : "1.2em")};
  }
`;

const ButtonContent = styled.div`
  margin: auto 0;
  margin-left: ${(props) => (props.small ? "0" : "1.5em")};
  display: flex;
  flex-direction: column;

  ${up("lg")} {
    margin-left: ${(props) => (props.small ? "0.75em" : "1.5em")};
  }
`;

const ButtonTitle = styled.span`
  margin-bottom: 0.2em;
  font-size: 1.1em;
  display: ${(props) => (props.small ? "none" : "block")};

  ${up("lg")} {
    display: block;
  }

  ${up("xxl")} {
    font-size: 1.2em;
  }
`;

const ButtonSubtitle = styled.span`
  font-size: 0.9em;
`;

const PreviewButton = (props) => {
  const handleClick = () => {
    trackEventSegment("Clicked preview button", {
      href: props.href,
      title: props.title,
    });
  };

  return (
    <ButtonWrap onClick={() => handleClick()} href={props.href} small={props.small} target="_blank">
      <ButtonIcon icon={props.icon} small={props.small} />
      <ButtonContent small={props.small}>
        <ButtonTitle small={props.small}>{props.title}</ButtonTitle>
        {props.subtitle && <ButtonSubtitle>{props.subtitle}</ButtonSubtitle>}
      </ButtonContent>
    </ButtonWrap>
  );
};

PreviewButton.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired,
};

export default PreviewButton;
