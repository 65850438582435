import styled from "styled-components";
import { up } from "styled-breakpoints";

const InputWrap = styled.div`
  font-family: Gordita;
  width: 100%;
  font-size: 0.85em;
  display: flex;

  input {
    width: 100%;
    font-weight: 400;
    padding: 0.75em 1em;
    border-radius: ${(props) => (props.isRounded ? "8px" : "0px")};
    border: ${(props) => (props.hasBorder ? "1px lightgrey solid" : "0")};
    background: ${(props) => (props.hasBackground ? "#F4F4F4" : "transparent")};
    color: black;
    box-sizing: border-box;
    line-height: 1.4;
    font-family: Gordita;
  }
  input::placeholder {
    opacity: 0.7;
  }
  textarea {
    width: 100%;
    border-radius: ${(props) => (props.isRounded ? "8px" : "0px")};
    border: ${(props) => (props.hasBorder ? "1px lightgrey solid" : "0")};
    background: ${(props) => (props.hasBackground ? "#F4F4F4" : "transparent")};
    padding: 1em;
    box-sizing: border-box;
    line-height: 1.4;
    font-family: Gordita;
  }

  ${up("md")} {
    font-size: 0.9em;
  }

  ${up("lg")} {
    font-size: 0.95em;
  }

  ${up("xxl")} {
    font-size: 1em;
  }
`;

export default InputWrap;
