import React from "react";
import { Card, Text, CardHeader, CardFooter, Paragraph } from "grommet";
import { faPhone, faEnvelope, faClock } from "pro-regular-svg-icons";
import dayjs from "dayjs";
import CardTrait from "./CardTrait";

const FeedbackItem = (props) => {
  const { feedbackItem } = props;

  const dayTimeScanned = dayjs(feedbackItem.created).format("MM/DD/YYYY HH:MM A");

  return (
    <Card background="white" pad={{ vertical: "medium", horizontal: "medium" }} flex>
      <CardHeader direction="column" gap="small" margin={{ bottom: "small" }}>
        <Text alignSelf="start" weight="bold">
          {feedbackItem.full_name}
        </Text>
        <Paragraph alignSelf="start" fill>
          {feedbackItem.feedback}
        </Paragraph>
      </CardHeader>
      <CardFooter justify="start">
        <CardTrait content={feedbackItem.phone} icon={faPhone} />
        {feedbackItem.email && <CardTrait content={feedbackItem.email} icon={faEnvelope} />}
        <CardTrait content={dayTimeScanned} icon={faClock} />
      </CardFooter>
    </Card>
  );
};

export default FeedbackItem;
